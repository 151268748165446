import { DETERMINAR_PAGINA_ATUAL_PEDIDO } from '../../actions/types'

const initialState = undefined

export default function (state = 1, action = initialState) {
  switch (action.type) {
    case DETERMINAR_PAGINA_ATUAL_PEDIDO: {
      return action.payload
    }

    default:
      return state
  }
}
