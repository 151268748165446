import moment from 'moment';
import React, { Component } from 'react';
import '../../estilo/css/PainelItinerarios.css';

export class DetalhesItinerario extends Component {

    exibirTrecho() {
        const trechos = this.props.itinerario.trechos;
        return <table className='table-trechos'>
            <thead style={{ backgroundColor: '#ffffff66' }}>
                <tr>
                    <th colSpan={4} className='text-center'><strong></strong></th>
                    <th colSpan={4} className='text-center'><strong>Trecho</strong></th>
                    <th colSpan={4} className='text-center'><strong>Horário</strong></th>
                    <th colSpan={4} className='text-center'><strong>quilometragem</strong></th>
                    <th colSpan={4} className='text-center'><strong>Data/Hora Partida</strong></th>
                </tr>
            </thead>
            <tbody>
                {trechos && trechos.length && trechos.sort((a , b) => a.ordem - b.ordem).map((trecho, indice) =>
                    <tr key={indice}>
                        <td colSpan={4} className='text-center'>{trecho.ordem}</td>
                        <td colSpan={4} className='text-center'>{trecho.nome} - {trecho.uf}</td>
                        <td colSpan={4} className='text-center'>{trecho.duracao}</td>
                        <td colSpan={4} className='text-center'>{trecho.quilometragem}</td>
                        <td colSpan={4} className='text-center'>{trecho.dataHoraPartida && moment(trecho.dataHoraPartida).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>)}
            </tbody>
        </table>
    }

    render() {
        if (!this.props.itinerario.exibirDetalhe) {
            return ''
        }

        return (
            <div>
                <div className="card-body cinza-zebrado table-responsive-sm">
                    <table className="table table-sm table-bordered">
                        {this.exibirTrecho()}
                    </table>
                </div>
            </div>
        )
    }
}