import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import adicionarFiltroPedido from '../../actions/pedido/adicionarFiltroPedido'
import removerFiltroPedido from '../../actions/pedido/removerFiltroPedido'
import { formaPagamento } from '../../util/constantsUtil'
import {  
  FILTRO_NUMERO_PEDIDO,
  FILTRO_DATA_VENDA,
  FILTRO_EMPRESA,
  FILTRO_FORMA_PAGAMENTO  
} from '../../util/filtros'
import FiltroChaveValor from '../comum/FiltroChaveValor';
import FiltroBuscaPedido from './FiltroBuscaPedido';

export class FiltroPedidos extends Component {

  adicionarFiltro (filtro) {
    this.props.adicionarFiltroPedido(filtro)
  }

  removerFiltro (filtro) {
    this.props.removerFiltroPedido(filtro)
  }

  render () {
    return (
      <React.Fragment>
        <div className="conteudo">
          <FiltroBuscaPedido
            filtro={FILTRO_NUMERO_PEDIDO}
            isNumero={true}
          />
          <FiltroBuscaPedido
            filtro={FILTRO_DATA_VENDA}
            opcoes={[new Date().getFullYear()]}
            isFiltroData={true}
          />
          <FiltroBuscaPedido
            filtro={FILTRO_EMPRESA}
            opcoes={[]}
            isNome={true}
          />
          <FiltroChaveValor
            filtro={FILTRO_FORMA_PAGAMENTO}
            opcoes={formaPagamento}
            filtrosAdicionados={this.props.filtrosAdicionados}
            adicionarFiltro={this.adicionarFiltro.bind(this)}
            removerFiltro={this.removerFiltro.bind(this)}
          />
        </div>

      </React.Fragment>
    )
  }
}

export const mapStateToProps = (state) => ({    
  filtrosAdicionados: state.filtrosAdicionadosPedido,
  
})

export const mapDispatchToProps = dispatch => (
  bindActionCreators({
    adicionarFiltroPedido,
    removerFiltroPedido,
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(FiltroPedidos)
