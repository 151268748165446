import React, { Component } from 'react'
import BarraNavegacaoLogin from './BarraNavegacaoLogin'

export class PoliticaPrivacidade extends Component {
    render() {
        return (
            <div className='conteudo'>
                <BarraNavegacaoLogin />
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                                <h1 id='secao-politica-privacidade'>Política de privacidade</h1>
                                <div className="divider" />
                            </div>
                            <div className='card'>
                                <div className='card-body informacoes'>
                                    <strong><h1>POLÍTICA DE PRIVACIDADE </h1></strong><br />
                                    <p>Última atualização: JULHO DE 2023 </p>
                                    <p>A VEXADO PROMOTORA DE VENDAS E INTERMEDIAÇÕES DE NEGÓCIOS EIRELI (“VEXADO”), com sede na Q SCS Quadra 01,
                                        Bloco M, número 30, Sala 508, Asa Sul/DF, CEP: 70.305-900, valoriza seus usuários e está comprometida com
                                        a proteção de seus dados pessoais e com a sua privacidade. Para o cumprimento desse compromisso, a VEXADO
                                        desenvolveu esta Política de Privacidade (&quot;Política de Privacidade&quot; ou &quot;Política&quot;) que
                                        descreve as práticas da Bilhete na Mão no que se refere ao tratamento dos dados pessoais, de acordo com os
                                        parâmetros estabelecidos pela Lei Geral de Proteção de Dados (Lei nº 13.709/2018).</p>
                                    <p>Podemos alterar a Política de Privacidade a qualquer tempo, nos termos do artigo 8º, Parágrafo 6º da Lei
                                        13.709/2018, mas você será avisado quando fizermos qualquer mudança substancial, de forma específica
                                        naquilo a qual foi alterado. Inclusive precisamos que você concorde com essas alterações para continuar
                                        tendo acesso ao site e aplicativo Bilhete na Mão. Você pode notar que sempre informamos, no início
                                        desta Política, a data da sua última atualização.</p>
                                    <p>Quando você utiliza os serviços da Bilhete na Mão, seja através de nosso website, aplicativo ou centro
                                        de atendimento, alguns dados pessoais são coletados e, posteriormente, tratados. Essa Política de
                                        Privacidade destina-se a ajudá-lo a entender de que forma tratamos os dados pessoais.</p>
                                    <p><strong>Nosso Compromisso com a sua Privacidade</strong></p>
                                    <p>A Bilhete na Mão está empenhada em proteger a sua privacidade, garantindo que seus dados pessoais serão
                                        tratados de acordo com a Lei nº. 13.709/2018 e com as melhores práticas de mercado. Somos transparentes
                                        em relação às nossas práticas e estamos à disposição para conversar a respeito sempre que você quiser e
                                        julgar necessário.</p>
                                    <p>Por isso, veja abaixo alguns dos princípios que orientam a forma pela qual a Bilhete na Mão trata seus
                                        dados pessoais em conformidade com a Lei nº. 13.709/2018:</p>
                                    <p>Toda a coleta e tratamento de dados pessoais será realizada de acordo com essa Política de Privacidade e
                                        para cumprir as finalidades aqui especificadas. Seus dados não serão transferidos, vendidos, alugados ou
                                        divulgados de forma diferente do que indicamos nesta Política de Privacidade. Quando algum dado pessoal
                                        deixar de ser necessário e não tivermos a obrigação legal de armazená-lo, nossa intenção é excluí-lo,
                                        destruí-lo ou anonimizá-lo. Nossa intenção é coletar a menor quantidade possível de dados pessoais –
                                        realmente só pretendemos coletar os dados que forem necessários para atender às finalidades aqui
                                        descritas.</p>
                                    <p><strong>Definições importantes</strong></p>
                                    <p>Veja abaixo algumas definições importantes para entender melhor a proteção que aplicamos aos seus dados
                                        pessoais, os limites do nosso uso e seus direitos. A Lei nº 13.709/2018, nos termos do seu artigo 5º,
                                        considera:</p>
                                    <p><strong>Titular</strong> – Você, pessoa física, é o titular dos seus dados pessoais;</p>
                                    <p><strong>Dados pessoais</strong> – Informações que identifiquem ou levem à identificação de um titular.
                                        São exemplos de dados pessoais seu nome completo, telefone, CPF, RG e endereço;</p>
                                    <p><strong>Controlador</strong> – Pessoa responsável por tomar decisões relacionadas ao tratamento dos dados
                                        pessoais dos titulares. Nesse caso, é a Bilhete na Mão;</p>
                                    <p><strong>Tratamento</strong> – Qualquer operação que seja realizada com dados pessoais, o que pode incluir
                                        obtenção, acesso, análise, transferência, armazenamento, anonimização, exclusão etc;</p>
                                    <p><strong>Anonimização</strong> – Processo de descaracterização dos dados pessoais por meio da remoção de
                                        qualquer associação destas informações aos titulares a quem elas se referem;</p>
                                    <p><strong>Dado pessoal sensível</strong> – dado pessoal sobre origem racial ou étnica, convicção religiosa,
                                        opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político,
                                        dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa
                                        natural;</p>
                                    <p><strong>Banco de dados</strong> – conjunto estruturado de dados pessoais, estabelecido em um ou em vários
                                        locais, em suporte eletrônico ou físico;</p>
                                    <p><strong>Operador</strong> – pessoa natural ou jurídica, de direito público ou privado, que realiza o
                                        tratamento de dados pessoais em nome do controlador;</p>
                                    <p><strong>Agentes de tratamento</strong> – o controlador e o operador;</p>
                                    <p><strong>Consentimento</strong> – manifestação livre, informada e inequívoca pela qual o titular concorda
                                        com o tratamento de seus dados pessoais para uma finalidade determinada;</p>
                                    <p><strong>Bloqueio</strong> – suspensão temporária de qualquer operação de tratamento, mediante guarda do
                                        dado pessoal ou do banco de dados;</p>
                                    <p><strong>Eliminação</strong> – exclusão de dado ou de conjunto de dados armazenados em banco de dados,
                                        independentemente do procedimento empregado;</p>
                                    <p><strong>Transferência internacional de dados</strong> – transferência de dados pessoais para país
                                        estrangeiro ou organismo internacional do qual o país seja membro;</p>
                                    <p><strong>Uso compartilhado de dados</strong> – comunicação, difusão, transferência internacional,
                                        interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e
                                        entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados,
                                        reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por
                                        esses entes públicos, ou entre entes privados;</p>
                                    <p><strong>Relatório de impacto à proteção de dados pessoais</strong> – documentação do controlador que
                                        contém a descrição dos processos de tratamento de dados pessoais que podem gerar riscos às liberdades
                                        civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de risco;
                                    </p>
                                    <p><strong>Órgão de pesquisa</strong> – órgão ou entidade da administração pública direta ou indireta ou
                                        pessoa jurídica de direito privado sem fins lucrativos legalmente constituída sob as leis brasileiras,
                                        com sede e foro no País, que inclua em sua missão institucional ou em seu objetivo social ou estatutário
                                        a pesquisa básica ou aplicada de caráter histórico, científico, tecnológico ou estatístico</p>
                                    <p><strong>Autoridade nacional</strong> – órgão da administração pública responsável por zelar, implementar
                                        e fiscalizar o cumprimento desta Lei em todo o território nacional.</p>
                                    <p><strong>Seus Direitos</strong></p>
                                    <p>A legislação brasileira lhe concede uma série de direitos em relação aos seus dados pessoais e você
                                        poderá exercê-los conforme descrito nesta Política.</p>
                                    <p>Veja abaixo seus principais direitos relacionados aos seus dados pessoais:</p>
                                    <p>Confirmar se seus dados pessoais são objeto de tratamento pela Bilhete na Mão. Obter informações acerca
                                        da origem, finalidade e prazo de tratamento dos seus dados pessoais. Correção de dados incompletos,
                                        inexatos ou desatualizados. Anonimização, bloqueio ou eliminação dos dados pessoais que forem
                                        desnecessários, excessivos ou que estejam sendo tratados em desconformidade com a Lei nº. 13.709/2018.
                                        Pedir a eliminação de seus dados pessoais, que tenham sido tratados a partir de consentimento dado por
                                        você anteriormente. Saber quais entidades públicas e privadas têm acesso aos dados por decisão da Bilhete na Mão. Não fornecer consentimento para o tratamento dos seus dados e ser informado das consequências
                                        dessa recusa. Revogar o consentimento, a qualquer tempo, para coleta de novos dados pessoais. Solicitar
                                        a revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que, por
                                        exemplo, determinem seu perfil pessoal, profissional, de consumo, ou de crédito.</p>
                                    <p><strong>Encarregado de Proteção de Dados</strong></p>
                                    <p>Caso você tenha dúvidas sobre as informações disponibilizadas aqui ou em nossa Política de Privacidade,
                                        você pode entrar em contato com o nosso encarregado: Encarregado de Dados Oficial, através dos
                                        e-mails
                                        <a href="contato@vexado.com.br" target="_blank" rel="noreferrer"></a>
                                        e
                                        <a href="suporte@vexado.com.br" target="_blank" rel="noreferrer"></a>.</p>
                                    <p><strong>1. Quais dados pessoais coletamos?</strong></p>
                                    <p>A Bilhete na Mão coleta e armazena a) dados pessoais enviados por você b) dados pessoais enviados por
                                        terceiros, inclusive dados públicos, e c) dados pessoais coletados por nossos sistemas, conforme
                                        descrito a seguir:</p>
                                    <p>Dados pessoais enviados por você:</p>
                                    <p>Para poder fazer reservas de passagens de ônibus rodoviários ou outras modalidades disponíveis na Bilhete na Mão, você deve enviar determinados dados pessoais, que incluem, mas não se limitam a nome e
                                        sobrenome, número de documento ou identificação válida, informações de identificação tributária e
                                        informações físicas de contato (como número de telefone ou endereço de e-mail). Para pagamento do
                                        serviço, o usuário precisa muitas vezes passar informações de cartão de crédito ou débito, todas essas
                                        informações são enviadas diretamente aos parceiros de pagamento, a Bilhete na Mão somente armazena
                                        informação de cartões de forma incompleta, as quais não são possíveis identificar (ex:
                                        “511111******1111”).</p>
                                    <p>A Bilhete na Mão poderá confirmar a veracidade dos dados pessoais enviados, a critério exclusivo,
                                        através de entidades públicas, empresas especializadas ou centrais de risco. As informações obtidas pela&nbsp;
                                        Bilhete na Mão junto a estas entidades serão tratadas de modo confidencial, respeitando a Lei nº.
                                        13.709/2018 no que diz respeito à proteção de dados.</p>
                                    <p>Você sempre poderá decidir não nos enviar dados pessoais, mas, em geral, certos tipos de dados pessoais
                                        são necessários para comprar ou realizar transações na Bilhete na Mão.</p>
                                    <p>Caso decida não nos fornecer determinados dados pessoais, é possível que você não possa contratar nossos
                                        serviços, tendo em vista ser necessário a informação para efetivar a contratação do serviço.</p>
                                    <p>Caso forneça os dados de um terceiro, entenderemos que você possui o consentimento deste terceiro, bem
                                        como esteja ciente das reponsabilidades criminais, pelo uso indevido e/ou incorreto, passando a ser
                                        também responsável pelo tratamento dos referidos dados. Caso tal terceiro seja menor de idade, de acordo
                                        com a legislação aplicável, você declara possuir o consentimento específico dado por pelo menos um dos
                                        pais ou pelo responsável legal do menor.</p>
                                    <p><strong>Dados pessoais coletados por nossos sistemas:</strong></p>
                                    <p>Por conta de uma obrigação legal, somos obrigados a guardar, por seis meses, os dados de navegação no
                                        nosso site e, eventualmente, autoridades judiciais podem solicitar tais informações, contudo, mediante
                                        decisão fundamentada da finalidade.</p>
                                    <p>Os dados de navegação incluem: endereços de IP, Identificadores Uniformes de Recursos (URIs), horários de
                                        solicitações, métodos usados para enviar solicitações ao servidor, tamanho dos arquivos recebidos como
                                        resposta, códigos numéricos indicando a situação da resposta do servidor, e outros parâmetros
                                        relacionados ao sistema operacional do usuário e ao ambiente computacional.</p>
                                    <p>Os sistemas de computadores e softwares projetados para navegar neste site coletam, durante seu uso
                                        normal, determinados dados pessoais, cuja transmissão é implícita ao uso de protocolos de comunicação da
                                        Internet. Em regra, essas informações não são coletadas para identificar usuários, mas, por conta da sua
                                        natureza, podem permitir essa identificação por meio de associações a outros dados.</p>
                                    <p><strong>2. Qual é a nossa política de cookies e web beacons?</strong></p>
                                    <p>A Bilhete na Mão informa que o site e/ou o aplicativo utilizam &quot;cookies&quot;, relacionados a
                                        determinadas características ou funções. Os cookies são tipos específicos de informações que um site
                                        transmite ao disco rígido do computador do usuário, com o objetivo de manter os registros. Os cookies
                                        podem servir para facilitar o uso de um site, ao guardar senhas e preferências enquanto o usuário navega
                                        na internet.</p>
                                    <p>Por meio dos cookies, não podemos acessar outras informações armazenadas no seu dispositivo, mesmo se
                                        esses cookies tiverem sido baixados aqui. Os cookies não podem fazer o upload de códigos de qualquer
                                        tipo ou carregar vírus ou malware, e não prejudicam o terminal do usuário.</p>
                                    <p>As &quot;web beacons&quot; são imagens inseridas em um site da internet ou e-mail, que podem ser
                                        utilizadas para monitorar o comportamento de um visitante, assim como armazenar informações sobre o
                                        endereço IP do usuário, a duração do tempo de interação em tal site e o tipo de navegador utilizado,
                                        entre outros. A Bilhete na Mão recolhe e armazena automaticamente determinadas informações sobre a
                                        atividade dos seus usuários. Tais informações podem incluir a URL da qual provêm (estejam ou não no site
                                        ou aplicativo da Bilhete na Mão), a URL que acessam frequentemente, o navegador que utilizam e seus
                                        endereços IP, bem como os sites visitados e as buscas realizadas.</p>
                                    <p><strong>3. Para que utilizamos os dados pessoais que coletamos?</strong></p>
                                    <p>A Bilhete na Mão utiliza os dados pessoais coletados para os seguintes fins:</p>
                                    <p>Oferecer-lhe produtos e serviços, através da própria Bilhete na Mão ou de parceiros terceiros, na
                                        medida em que fomos autorizados a realizar esse tipo de comunicação;</p>
                                    <p>Medir satisfação do cliente para com nossos produtos, serviços e atendimento;</p>
                                    <p>Cumprimento de obrigações legais, cooperação com órgãos públicos ou para o exercício de algum direito
                                        previsto por lei;</p>
                                    <p>Enviar-lhe confirmações e atualizações sobre sua viagem, bem como quaisquer outras informações
                                        relevantes. Estas informações poderão ser enviadas via e-mail, SMS ou WhatsApp, ou outra forma de
                                        contato que você tiver informado. Estas mensagens poderão conter (sem limitar-se a) informações sobre os
                                        serviços da Bilhete na Mão, bem como dados de contato de prestadores de serviços que poderiam auxiliar
                                        na sua experiência de viagem.</p>
                                    <p>Com o propósito de oferecer melhores serviços ou proporcionar aos usuários informações relativas às suas
                                        preferências, a Bilhete na Mão também poderá desenvolver estudos internos sobre os interesses,
                                        comportamentos e demografia dos usuários, para compreender melhor suas necessidades e interesses,
                                        melhorar nossas iniciativas comerciais e promocionais, personalizar seus conteúdos, sua apresentação e
                                        serviços, exibir publicidade ou promoções e banners que sejam interessantes para os nossos usuários,
                                        para tanto, a Bilhete na Mão só fará uso de dados anonimizados.</p>
                                    <p><strong>4. Com quem compartilhamos os dados pessoais coletados?</strong></p>
                                    <p>A Bilhete na Mão pode compartilhar os dados pessoais relevantes com prestadores de serviço e empresas
                                        parceiras para execução da venda de passagens ou outras solicitações de compra, tais como com empresas
                                        de transporte rodoviário, aéreo, ferroviário ou aquático que forem contratadas, nos termos do artigo 9º,
                                        inciso V da Lei nº 13.709/2018.</p>
                                    <p>Do mesmo modo, a Bilhete na Mão também pode compartilhar os dados pessoais com prestadores de serviço
                                        nas áreas de cartões de crédito, atendimento ao cliente, prevenção de fraudes e marketing.</p>
                                    <p>A Bilhete na Mão poderá compartilhar seus dados pessoais e transacionais, incluindo seus dados
                                        cadastrais e os dados de suas reservas e/ou solicitações de compra, com instituições financeiras e
                                        adquirentes para atribuir maior segurança, prevenir fraudes, realizar análises de risco de crédito e
                                        aperfeiçoar o atendimento e os produtos e serviços prestados, sempre com a estrita observância dos
                                        princípios e finalidades legais.</p>
                                    <p>Finalmente, a Bilhete na Mão poderá igualmente compartilhar seus dados pessoais com as empresas
                                        pertencentes ao mesmo grupo econômico ou comercial. Caso houver transferência de dados pessoais,
                                        informamos que o receptor de tais dados pessoais assumirá as mesmas obrigações correspondentes ao
                                        responsável que transfere esses dados pessoais.</p>
                                    <p>Esteja ciente de que, caso você proporcionar, de maneira livre e voluntária e por iniciativa própria,
                                        dados pessoais diretamente aos prestadores de serviços (sejam ou não prestadores de serviços para a&nbsp;
                                        Bilhete na Mão), tais prestadores recolherão, tratarão e conservarão estas informações de acordo com
                                        suas próprias Políticas de Privacidade. A Bilhete na Mão não é responsável pelo eventual uso indevido
                                        das Informações Pessoais de parte de empresas externas fora do site ou do aplicativo.</p>
                                    <p><strong>5. Onde armazenamos e como protegemos os dados pessoais coletados?</strong></p>
                                    <p>Todos os dados pessoais são recolhidos e armazenados em servidores localizados fisicamente no Brasil. A&nbsp;
                                        Bilhete na Mão poderá futuramente realocar tais servidores em qualquer outro país, e poderá armazenar
                                        os dados pessoais nos Estados Unidos ou em outros países, para fins de respaldo ou back-up.</p>
                                    <p>Em qualquer caso, a Bilhete na Mão compromete-se a garantir que se cumpram as normas legalmente
                                        exigíveis para a proteção e resguardo de seus dados pessoais, mediante a assinatura de acordos ou
                                        termos, cujo objeto seja a proteção dos seus dados pessoais.</p>
                                    <p>A Bilhete na Mão possui ferramentas para proteger seus dados pessoais de terceiros mal-intencionados ou
                                        sem autorização, tendo em vista sua armazenagem ser criptografada de ponta a ponta. Adotamos medidas de
                                        segurança adequadas para nos proteger contra acesso, alteração, divulgação ou destruição não-autorizada
                                        dos dados. Essas medidas incluem análises internas de nossas práticas de coleta, armazenamento e
                                        processamento de dados.</p>
                                    <p>Com os cuidados que tomamos, podemos garantir que evitamos ao máximo possíveis falhas de segurança que
                                        afetem seus dados pessoais. Entretanto, reconhecendo que existem riscos naturais inerentes ao uso da
                                        Internet, entendemos que não podemos anular esse risco por completo. De todo modo, garantimos que a
                                        proteção de seus dados pessoais é uma das nossas prioridades e que, caso algum incidente aconteça,
                                        adotaremos todas as medidas possíveis para minimizar eventuais danos.</p>
                                    <p>A Bilhete na Mão resguarda seus dados pessoais de acordo com os padrões e procedimentos de segurança e
                                        confidencialidade vigentes no Brasil. A Bilhete na Mão não transmite, divulga ou compartilha os dados
                                        pessoais coletados a terceiros, exceto aqueles descritos na presente Política e que são necessários para
                                        as finalidades pelas quais você se cadastrou em nosso site: pesquisar e adquirir passagens das empresas
                                        de transporte que possuem contrato conosco. Nesse sentido, este site e o aplicativo tomam as medidas
                                        necessárias para proteger os dados pessoais dos usuários.</p>
                                    <p><strong>6. Por quanto tempo a Bilhete na Mão trata meus dados pessoais?</strong></p>
                                    <p>Os dados pessoais do cliente serão tratados pelo tempo necessário para a execução dos serviços, e, após,
                                        para o cumprimento de obrigação legal ou regulatória, para o exercício regular de direito ou para o
                                        legítimo interesse da Bilhete na Mão. Ademais, nos termos do artigo 40 da Lei nº. 13.709/2018, a
                                        autoridade nacional poderá dispor sobre padrões de interoperabilidade para fins de portabilidade, livre
                                        acesso aos dados e segurança, assim como sobre o tempo de guarda dos registros, tendo em vista
                                        especialmente a necessidade e a transparência.</p>
                                    <p><strong>7. Como pode ser feito o acesso, eliminação e/ou atualização dos dados pessoais
                                        coletados?</strong></p>
                                    <p><strong>7.1 Opt-out</strong></p>
                                    <p>Quando você realizar transações ou registrar-se como usuário no site da Bilhete na Mão, lhe daremos a
                                        opção de receber boletins promocionais, mensagens ou alertas de e-mail sobre ofertas. Em cada mensagem
                                        de e-mail enviada, também lhe daremos a oportunidade de cancelar sua inscrição.</p>
                                    <p>Ademais, o cancelamento será feito nos termos da Lei nº. 13.709/2018, artigo 18 inciso IV.</p>
                                    <p>Esteja ciente de que o exercício do direito de opt-out refere-se aos boletins promocionais, mensagens ou
                                        alertas de e-mail sobre ofertas. Nesse sentido, você continuará recebendo comunicações referentes ao
                                        estado das suas reservas ativas. Em determinados casos, inclusive, a Bilhete na Mão deverá manter nos
                                        seus arquivos determinados dados pessoais, com a finalidade de resolver disputas ou reclamações,
                                        detectar problemas ou incidentes e solucioná-los, e cumprir as disposições dos Termos e Condições Gerais
                                        por um período de tempo determinado pela legislação. Em qualquer caso, os dados pessoais de um usuário
                                        não serão retirados imediatamente dos nossos arquivos por questões legais e de segurança.</p>
                                    <p>Para fazer qualquer modificação nos dados pessoais oportunamente fornecidos, você pode entrar na
                                        seção &quot;Minha conta&quot; &gt; &quot;Meu Perfil&quot;.</p>
                                    <p><strong>7.2 Como entrar em contato para solicitações relacionadas a dados pessoais?</strong></p>
                                    <p>Você pode exercê-los através de qualquer das opções a seguir:</p>
                                    <p>Envie sua solicitação aos emails
                                        <a href="contato@vexado.com.br" target="_blank" rel="noreferrer"></a>
                                        ou
                                        <a href="suporte@vexado.com.br" target="_blank" rel="noreferrer"></a>
                                    contendo seu nome completo, RG e CPF.
                                    Conforme o caso, pode ser necessário envio de documentos que comprovem ser o titular dos dados ou a
                                    representação legal do titular. Faça uma descrição de forma detalhada e clara do ocorrido para que
                                    possamos lhe orientar da melhor forma, dentro de um prazo de no máximo 05 (cinco) dias úteis.</p>
                                <p><strong>8. Como a Bilhete na Mão relaciona-se com as suas redes sociais?</strong></p>
                                <p>Ao registrar-se na Bilhete na Mão, é necessária a escolha de uma identificação de usuário e uma senha
                                    (ou seja, acessar uma conta pessoal no site). Do mesmo modo, a Bilhete na Mão permite que você acesse
                                    a seção &quot;Minha Conta&quot; através da sua conta de Facebook, Google ou outras redes sociais
                                    (doravante, as &quot;Redes Sociais&quot;, ou &quot;Rede Social&quot;) que futuramente tenham
                                    compatibilidade com o acesso à &quot;Minha Conta&quot;.</p>
                                <p>Como parte da funcionalidade do site, e para possibilitar uma melhor experiência personalizada, você pode
                                    vincular a sua conta às Redes Sociais, proporcionando as informações de login da sua conta na Rede
                                    Social através do site e/ou o aplicativo ou permitindo que a Bilhete na Mão acesse a sua conta nas
                                    Redes Sociais, de acordo com o permitido nos termos e condições aplicáveis que regem o uso que você faz
                                    de cada Rede Social.</p>
                                <p>Você declara que tem direito de revelar as informações de login da sua Rede Social à Bilhete na Mão
                                    e/ou conceder à Bilhete na Mão o acesso à sua conta, sem que isso suponha um descumprimento da sua
                                    parte de nenhum dos termos e condições que regem o uso que você faz da correspondente Rede Social, e sem
                                    que a Bilhete na Mão esteja obrigada a pagar nenhuma taxa ou a respeitar nenhuma limitação de uso
                                    imposta pelos prestadores de serviços externos da Rede Social.</p>
                                <p>Em função das redes sociais que você escolher, e sujeitas às configurações de privacidade que houver
                                    estabelecido em tais contas, as informações de identificação pessoal que publicar em suas redes sociais
                                    estarão disponíveis na sua conta da Bilhete na Mão e através dela no site e/ou no aplicativo. Você
                                    deve estar ciente de que, se uma rede social deixar de estar disponível, ou se o prestador de serviços
                                    externo bloquear o acesso da Bilhete na Mão a esta rede social, os conteúdos das redes sociais
                                    deixarão de estar disponíveis no site e/ou o aplicativo ou através deles.</p>
                                <p>Se você se registra no site da Bilhete na Mão através da sua conta pessoal de uma rede social
                                    (a &quot;Conta Pessoal&quot;), concorda que a Bilhete na Mão poderá acessar informações contidas na
                                    sua Conta Pessoal, incluindo seus Dados Pessoais.</p>
                                <p><strong>9. Informações gerais.</strong></p>
                                <p>ESTEJA CIENTE DE QUE SUA RELAÇÃO COM OS PRESTADORES DE SERVIÇOS EXTERNOS REGE-SE EXCLUSIVAMENTE PELOS
                                    ACORDOS QUE TENHA FEITO COM TAIS PRESTADORES DE SERVIÇOS EXTERNOS.</p>
                                <p>Este site pode conter vínculos (links) a outros sites que têm suas próprias políticas de privacidade e
                                    confidencialidade. Por esse motivo, recomendamos que, se você acessar estes outros sites, revise
                                    cuidadosamente as respectivas práticas e políticas de confidencialidade, sempre que esta Política de
                                    Privacidade não englobar práticas ou políticas de terceiros, incluindo os que possam revelar e/ou
                                    compartilhar informações com a Bilhete na Mão.</p>
                                <p>Se você perder o acesso à sua conta ou senha de acesso às redes sociais, poderá perder o controle sobre
                                    os seus dados pessoais e estará sujeito a transações legalmente válidas realizadas em seu nome.
                                    Portanto, se por qualquer razão sua senha estiver comprometida, você deverá imediatamente: (i) trocá-la,
                                    modificando suas informações de registro proporcionadas a este site, e (ii) entrar em contato conosco
                                    através de qualquer canal oferecido pela Bilhete na Mão.</p>
                                <p>As informações e ou casos ausentes na presente Política de Privacidade serão analisados pontualmente.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div >
        )
    }
}

export default PoliticaPrivacidade
