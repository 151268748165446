import React from 'react'
import FiltrosAdicionadosItinerarios from './FiltrosAdicionadosItinerario'
import Filtro from '../comum/Filtro'
import PainelFiltragem from '../comum/PainelFiltragem'
import FiltroItinerarios from './FiltroItinerario';

export const PainelFiltrosItinerario = () => (
  <Filtro PainelFiltro={FiltroItinerarios}/>
);

const PainelFiltrosItinerarios = () => (
  <PainelFiltragem    
    Filtro={PainelFiltrosItinerario}
    FiltrosAdicionados={FiltrosAdicionadosItinerarios}
    />
)

export default PainelFiltrosItinerarios
