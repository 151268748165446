import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import loading from './loadingReducer'
import usuario from './usuariosReducer'
import messages from './messagesReducer';
import acervoDivulgacao from './acervo/reducerBuscarAcervoDivulgacao';
import pedidos from './pedido/reducer-buscar-pedidos';
import { filtrosAdicionadosPedido } from './pedido/reducer-filtro-pedido'
import { filtrosAdicionadosItinerario, filtrosEmpresa } from './itinerario/reducer-filtro-itinerario'
import registrosPorPaginaPedido from './pedido/reducer-registros-por-paginal-pedido'
import registrosPorPaginaItinerario from './itinerario/reducer-registros-por-pagina-itinerario'
import paginaAtualPedido from './pedido/reducer-pagina-atual-pedido'
import paginaAtualItinerario from './itinerario/reducer-pagina-atual-itinerario'
import datasRejeitadas from './reducer-filtros-data-rejeitadas'
import dashboardReservas from './reserva/reducer-dashboard-reservas'
import itinerario from './itinerario/reducer-buscar-itinerario'
import openGraph from './openGraphReducer'

const rootReducer = combineReducers({
    form: formReducer,

    loading,
    usuario,
    messages,
    acervoDivulgacao,
    pedidos,
    filtrosAdicionadosPedido,
    filtrosAdicionadosItinerario,
    filtrosEmpresa,
    registrosPorPaginaPedido,
    registrosPorPaginaItinerario,
    paginaAtualPedido,
    paginaAtualItinerario,
    dashboardReservas,
    itinerario,
    datasRejeitadas,
    openGraph
});

export default rootReducer
