import React, {Component} from 'react'
import Select from 'react-select'
import Alert from 'react-s-alert'
import {connect} from 'react-redux';
import moment from 'moment'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion'
import {DateTimePicker} from 'react-widgets';
import {filtroExiste, configuracaoMensagemAlerta} from '../../util/filtros'
import {ICONE_SUCESSO} from '../../util/icone'
import adicionarFiltroDatasRejeitadas from '../../actions/adicionarFiltroDatasRejeiradas';
import limparFiltroDatasRejeitadas from '../../actions/limparFiltroDatasRejeitadas';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import {apenasNumero, email} from '../../util/inputs';

export class FiltroBusca extends Component {
    constructor(props) {
        super(props);
        this.state = {filtroNumero: '', filtroCpf: '', filtroNome: '', filtroEmail: '', filtroPorcentagem: ''}
    }

    opcoesSelecionadas(filtro) {
        return this.props.filtrosAdicionados
            .filter(f => f.chave === filtro.chave)
            .map(f => ({label: f.valor, value: f.valor}))
    }

    isOpcaoSelecionada(elemento) {
        return this.props.filtrosAdicionados.find(filtro => filtro.valor === elemento) !== undefined
    }

    adicionarFiltro(filtro) {
        const mensagem = `Filtro "${filtro.label} = ${filtro.valor}"`;
        this.props.adicionarFiltro(filtro);
        // language=HTML
        Alert.success(
            `<div class='col-md-2 icone-feedback posicao-camada'>${ICONE_SUCESSO}</div>
       <div class='col-md-10 mensagem-feedback posicao-camada'>${mensagem} adicionado.</div>`,
            configuracaoMensagemAlerta
        )
    }

    adicionarFiltroData(filtro) {
        this.props.adicionarFiltro(filtro);
        if (filtro.valor.inicio && filtro.valor.fim) {
            const mensagem = `Filtro "${filtro.label} = ${filtro.valor.inicio} - ${filtro.valor.fim}"`;
            // language=HTML
            Alert.success(
                `<div class='col-md-2 icone-feedback posicao-camada'>${ICONE_SUCESSO}</div>
         <div class='col-md-10 mensagem-feedback posicao-camada'>${mensagem} adicionado.</div>`,
                configuracaoMensagemAlerta
            )
        }
    }


    handleFiltroAdicionadoClick(filtro, valor) {
        const filtroAdicionar = {label: filtro.label, chave: filtro.chave, valor};
        if (!filtroExiste(this.props.filtrosAdicionados, filtroAdicionar)) {
            this.adicionarFiltro(filtroAdicionar)
        } else {
            this.props.removerFiltro(filtroAdicionar);
            if (this.props.isNumero) {
                this.setState({filtroNumero: ''})
            }
            if (this.props.isNome) {
                this.setState({filtroNome: ''})
            }
            if (this.props.isEmail) {
                this.setState({filtroEmail: ''})
            }
            if (this.props.isCpf) {
                this.setState({filtroCpf: ''})
            }
            if (this.props.isPorcentagem) {
                this.setState({filtroPorcentagem: ''})
            }
        }
    }

    handleFiltroAdicionadoRadioClick(filtro, valor) {
        const filtroAdicionar = {label: filtro.label, chave: filtro.chave, valor};
        const filtoAtual = this.props.filtrosAdicionados.filter(f => f.chave === filtro.chave);
        if (filtoAtual.length > 0) {
            if (filtroAdicionar.valor === filtoAtual[0].valor) {
                this.props.removerFiltro(filtoAtual[0])
            } else {
                this.props.removerFiltro(filtoAtual[0]);
                this.adicionarFiltro(filtroAdicionar)
            }
        } else {
            this.adicionarFiltro(filtroAdicionar)
        }
    }

    handleFiltroAdicionadoDataClick(filtro, data, tipo) {
        const datas = this.props.filtrosAdicionados.filter(f => f.chave === filtro.chave)[0];

        const valor = {inicio: '', fim: ''};

        if (datas) {
            valor.inicio = datas.valor.inicio;
            valor.fim = datas.valor.fim;
            this.props.removerFiltro(datas)
        }

        if (tipo === 'start') {
            valor.inicio = data
        }
        if (tipo === 'end') {
            valor.fim = data
        }

        if (this.props.datasRejeitadas.length > 0) {
            const filtroData = this.props.datasRejeitadas.filter(f => f.chave === filtro.chave);
            if (filtroData[0] !== undefined) {
                if (tipo === 'start') {
                    valor.fim = filtroData[0].valor.fim
                }
                if (tipo === 'end') {
                    valor.inicio = filtroData[0].valor.inicio
                }
                this.props.limparFiltroDatasRejeitadas(filtroData[0]);
            }
        }


        const filtroAdicionar = {
            label: filtro.label, chave: filtro.chave, valor, isData: true
        };

        if (valor.inicio.length > 0 && valor.fim.length > 0 && moment(valor.inicio, 'DD/MM/YYYY').isAfter(moment(valor.fim, 'DD/MM/YYYY'))) {
            this.props.adicionarFiltroDatasRejeitadas(filtroAdicionar)
        } else {
            this.adicionarFiltroData(filtroAdicionar)
        }
    }


    handleFiltroAdicionadoChange(filtro, event) {
        const filtrosAdicionadosDoTipo = this.props.filtrosAdicionados.filter(f => f.chave === filtro.chave);
        const filtroFoiAdicionado = event.length > filtrosAdicionadosDoTipo.length;

        if (filtroFoiAdicionado) {
            event.forEach((element) => {
                const filtroAdicionar = {label: filtro.label, chave: filtro.chave, valor: element.value};
                if (!filtroExiste(this.props.filtrosAdicionados, filtroAdicionar)) {
                    this.adicionarFiltro(filtroAdicionar)
                }
            })
        } else {
            filtrosAdicionadosDoTipo.forEach((filtroAdicionado) => {
                if (event.filter(e => e.value === filtroAdicionado.valor).length === 0) {
                    this.props.removerFiltro(filtroAdicionado)
                }
            })
        }
    }

    caixaSelecao() {
        if (this.props.isFiltroData && this.props.opcoes.length > 0) {
            const dataSelecionada = this.props.filtrosAdicionados.filter(f => f.chave === this.props.filtro.chave)[0];
            if (dataSelecionada && dataSelecionada.valor.inicio !== '' && dataSelecionada.valor.fim !== '') {
                return (
                    <div>
            <span className="badge" style={{backgroundColor: '#E6E6E6'}}>
                  {`${dataSelecionada.valor.inicio} - ${dataSelecionada.valor.fim}`}
                <span className="badge badge-light ml-2 clicavel"
                      onClick={() => this.props.removerFiltro(dataSelecionada)}>&times;
              </span>
            </span>
                    </div>
                )
            }
            return (
                <div>
                    <div className="linha">
                        <span className="label-data">De</span>
                        <DateTimePicker
                            time={false}
                            format="DD/MM/YYYY"
                            onChange={(value) => {
                                if (value) {
                                    this.handleFiltroAdicionadoDataClick(this.props.filtro, value.toLocaleDateString('pt-BR'), 'start')
                                }
                            }}/>
                    </div>
                    <div className="linha">
                        <span className="label-data">Até</span>
                        <DateTimePicker
                            time={false}
                            format="DD/MM/YYYY"
                            onChange={value => this.handleFiltroAdicionadoDataClick(this.props.filtro, value.toLocaleDateString('pt-BR'), 'end')}/>
                    </div>
                </div>

            )
        }

        if (this.props.isRadio) {
            return (
                this.props.opcoes.map(element => (
                    <label className="checkbox d-block" key={element}>
                        <input className="form-check-input" key={element}
                               type="checkbox" name={element} id={element}
                               onChange={() => this.handleFiltroAdicionadoRadioClick(this.props.filtro, element)}
                               checked={this.isOpcaoSelecionada(element)}/>
                        <span>{element}</span>
                    </label>
                ))
            )
        }

        if (this.props.isNumero) {
            const filtroAdicionado = this.props.filtrosAdicionados.filter(f => f.chave === this.props.filtro.chave).length;
            return <div className="row">
                <div className='col-10'>
                    <NumberFormat className='form-control'
                                  readOnly={filtroAdicionado}
                                  name={this.props.filtro.chave}
                                  value={this.state.filtroNumero}
                                  onChange={e => this.setState({filtroNumero: e.target.value})}/>
                </div>
                <div className='col-2'>
                    {filtroAdicionado ?
                        <i style={{cursor: 'pointer'}}
                           className="material-icons"
                           onClick={() => this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroNumero)}>&#xe872;</i>
                        :
                        <i style={{cursor: 'pointer'}}
                           className="material-icons"
                           onClick={() => this.state.filtroNumero ? this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroNumero) : null}>&#xe8b6;</i>
                    }</div>
            </div>
        }

        if (this.props.isNome) {
            const filtroAdicionado = this.props.filtrosAdicionados.filter(f => f.chave === this.props.filtro.chave).length;
            return <div className="row">
                <div className='col-10'>
                    <input className='form-control'
                           readOnly={filtroAdicionado}
                           name={this.props.filtro.chave}
                           value={this.state.filtroNome}
                           onChange={e => this.setState({filtroNome: e.target.value})}/>
                </div>
                <div className='col-2'>
                    {filtroAdicionado ?
                        <i className="material-icons"
                           style={{
                               cursor: 'pointer'
                           }}
                           onClick={() => this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroNome)}>&#xe872;</i>
                        : _.size(this.state.filtroNome) > 3 ?
                            <i style={{
                                cursor: 'pointer'
                            }}
                               className="material-icons"
                               onClick={() => this.state.filtroNome ? this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroNome) : null}>&#xe8b6;</i>
                            : ''
                    }</div>
            </div>
        }

        if (this.props.isEmail) {
            const filtroAdicionado = this.props.filtrosAdicionados.filter(f => f.chave === this.props.filtro.chave).length;
            const validacaoEmail = email(this.state.filtroEmail);
            return <div className="row">
                <div className='col-10'>
                    <input className='form-control'
                           type='email'
                           readOnly={filtroAdicionado}
                           name={this.props.filtro.chave}
                           value={this.state.filtroEmail}
                           onChange={e => this.setState({filtroEmail: e.target.value})}/>
                    {validacaoEmail ? <span style={{ color: 'red' }}>{email(this.state.filtroEmail)}</span> : null}
                </div>
                <div className='col-2'>
                    {filtroAdicionado ?
                        <i className="material-icons"
                           style={{
                               cursor: 'pointer'
                           }}
                           onClick={() => this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroEmail)}>&#xe872;</i>
                        : this.state.filtroEmail && validacaoEmail !=='E-mail inválido' ?
                            <i style={{
                                cursor: 'pointer'
                            }}
                               className="material-icons"
                               onClick={() => this.state.filtroEmail ? this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroEmail) : null}>&#xe8b6;</i>
                            : ''
                    }</div>
            </div>
        }

        if (this.props.isCpf) {
            const filtroAdicionado = this.props.filtrosAdicionados.filter(f => f.chave === this.props.filtro.chave).length;
            return <React.Fragment>
                <div className="row">
                    <div className='col-10'>
                        <NumberFormat className='form-control'
                                      format={apenasNumero(this.state.filtroCpf).length > 11 ? '##.###.###/####-##' : '###.###.###-###'}
                                      readOnly={filtroAdicionado}
                                      name={this.props.filtro.chave}
                                      value={this.state.filtroCpf}
                                      onChange={e => this.setState({filtroCpf: e.target.value.trim()})}/>
                    </div>
                    <div className='col-2 pull-left'>
                        {filtroAdicionado ?
                            <i className="material-icons" style={{
                                cursor: 'pointer'
                            }}
                               onClick={() => this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroCpf)}>&#xe872;</i>
                            :
                            <i className="material-icons" style={{
                                cursor: 'pointer'
                            }}
                               onClick={() => this.state.filtroCpf ? this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroCpf) : null}>&#xe8b6;</i>
                        }
                    </div>
                </div>

            </React.Fragment>
        }

        if (this.props.isPorcentagem) {
            const filtroAdicionado = this.props.filtrosAdicionados.filter(f => f.chave === this.props.filtro.chave).length;
            return <React.Fragment>
                <div className="row">
                    <div className='col-10'>
                        <NumberFormat className='form-control'
                                      format={apenasNumero(this.state.filtroPorcentagem).length > 4 ? '##.##' : '##.##'}
                                      readOnly={filtroAdicionado}
                                      name={this.props.filtro.chave}
                                      value={this.state.filtroPorcentagem}
                                      onChange={e => this.setState({filtroPorcentagem: e.target.value.trim()})}/>
                    </div>
                    <div className='col-2 pull-left'>
                        {filtroAdicionado ?
                            <i className="material-icons" style={{
                                cursor: 'pointer'
                            }}
                               onClick={() => this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroPorcentagem)}>&#xe872;</i>
                            :
                            <i className="material-icons" style={{
                                cursor: 'pointer'
                            }}
                               onClick={() => this.state.filtroPorcentagem ? this.handleFiltroAdicionadoClick(this.props.filtro, this.state.filtroPorcentagem) : null}>&#xe8b6;</i>
                        }
                    </div>
                </div>

            </React.Fragment>
        }

        if (this.props.opcoes && this.props.opcoes.length <= 5) {
            return (
                this.props.opcoes.map(element => (
                    <label className="checkbox d-block" key={element}>
                        <input className="form-check-input" type="checkbox" key={element}
                               onChange={() => this.handleFiltroAdicionadoClick(this.props.filtro, element)}
                               checked={this.isOpcaoSelecionada(element)}/>
                        <span>{element}</span>
                    </label>
                ))
            )
        }
        return (
            <Select
                noOptionsMessage={() => ('Nenhuma opção encontrada')}
                value={this.opcoesSelecionadas(this.props.filtro)}
                isMulti
                placeholder=''
                options={this.props.opcoes.map(opcao => ({label: opcao, value: opcao}))}
                onChange={event => this.handleFiltroAdicionadoChange(this.props.filtro, event)}
            />
        )
    }

    render() {
        if (!this.props.filtrosAdicionados) {
            return <div>Carregando...</div>
        }
        return (
            <React.Fragment>
                <Accordion className=''>
                    <AccordionItem expanded={true}>
                        <AccordionItemTitle>
                            <h6>{this.props.filtro.label}</h6>
                            <div className="accordion__arrow" role="presentation"/>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            {this.caixaSelecao()}
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
            </React.Fragment>
        )
    }
}

export function mapStateToProps(state) {
    return {
        datasRejeitadas: state.datasRejeitadas
    }
}

export default connect(mapStateToProps, {adicionarFiltroDatasRejeitadas, limparFiltroDatasRejeitadas})(FiltroBusca)
