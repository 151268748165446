import axios from 'axios'
import { URL } from '../../util/ambienteActions';
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil'

const baixarArquivoAcervoDivulgacao = (chave, nomeArquivo, pasta, subPasta) => axios({
    headers: SLOW_REQUEST_CONFIG.headers,
    url:`${URL}/arquivo/id/${chave}/nome/${nomeArquivo}/sub/${subPasta}/baixar?pasta=${pasta}`,
    method: 'GET',
    responseType: 'blob'
    })

export default baixarArquivoAcervoDivulgacao