import { ALTERAR_DETALHAMENTO_PEDIDO } from '../types'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil'
import { URL } from '../../util/ambienteActions';

export default function alterarExibicaoDetalhamentoPedido (pedido) {
  const url = `${URL}/reserva/pedido/${pedido.id}`
  return dispatch => {
    if(!pedido.reservaFoiPesquisado) {
      axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => {
          return dispatch({
            type: ALTERAR_DETALHAMENTO_PEDIDO,
            payload: {
              reservas: response.data,
              pedido: pedido
            }
          })
        }
      )
    } else {
      return dispatch({
        type: ALTERAR_DETALHAMENTO_PEDIDO,
        payload: {
          reservas: null,
          pedido: pedido
        }
      })
    }
  }
}