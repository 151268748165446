import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import momentLocalizer from 'react-widgets-moment'
import adicionarFiltroPedido from '../../actions/pedido/adicionarFiltroPedido'
import removerFiltroPedido from '../../actions/pedido/removerFiltroPedido'
import FiltroBusca from '../comum/FiltroBusca'
import 'react-widgets/dist/css/react-widgets.css';

momentLocalizer();

export class FiltroBuscaPedido extends Component {
  render () {
    if (!this.props.filtrosAdicionados) {
      return <div>Carregando...</div>
    }
    return (
      <FiltroBusca
        filtrosAdicionados={this.props.filtrosAdicionados}
        filtro={this.props.filtro}
        opcoes={this.props.opcoes}
        isCpf={this.props.isCpf}
        isNome={this.props.isNome}
        isRadio={this.props.isRadio}
        isFiltroData={this.props.isFiltroData}
        isNumero={this.props.isNumero}
        adicionarFiltro={this.props.adicionarFiltroPedido}
        removerFiltro={this.props.removerFiltroPedido}
      />
    )
  }
}

export const mapStateToProps = state => ({
  filtrosAdicionados: state.filtrosAdicionadosPedido
})

export const mapDispatchToProps = dispatch => (
  bindActionCreators({
    adicionarFiltroPedido,
    removerFiltroPedido,
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(FiltroBuscaPedido)

