import React from 'react';
import {withRouter} from 'react-router-dom';
import {
    acervoDivulgacao, acervoDivulgacaoGaleria, acervoDivulgacaoSubPasta, pedidoMinhasVendas,
    raiz
} from '../../routes/rotas'
import BreadCrumbLink from './BreadCrumbLink';
import Titulo from './Titulo';

const linkInicio = (last, action) => <BreadCrumbLink action={action} last={last} path={raiz.path}
                                                     desc={raiz.desc}/>;

const linkAcervoDivulgacao = (last, action) => <BreadCrumbLink action={action} last={last} path={acervoDivulgacao.path}
                                                               desc={acervoDivulgacao.desc}/>;

const linkAcervoDivulgacaoGaleria = (last, action) => <BreadCrumbLink action={action} last={last}
                                                                      path={acervoDivulgacaoGaleria.path}
                                                                      desc={acervoDivulgacaoGaleria.desc}/>;

const linkAcervoDivulgacaoSubPasta = (last, action) => <BreadCrumbLink action={action} last={last}
                                                                       path={acervoDivulgacaoSubPasta.path}
                                                                       desc={acervoDivulgacaoSubPasta.desc}/>;

const linkpedidoMinhasVendas = (last, action) => <BreadCrumbLink action={action} last={last}
                                                                       path={pedidoMinhasVendas.path}
                                                                       desc={pedidoMinhasVendas.desc}/>;


class BreadCrumb extends React.Component {

    acervoDivulgacao() {
        if (this.props.history.location.pathname === acervoDivulgacao.path) {
            return <React.Fragment>
                <Titulo texto={acervoDivulgacao.desc}/>
                {linkInicio()}
                {linkAcervoDivulgacao(true)}
            </React.Fragment>;
        }
        return '';
    }

    acervoDivulgacaoGaleria() {
        if (this.props.history.location.pathname === acervoDivulgacaoGaleria.path) {
            return <React.Fragment>
                {linkInicio()}
                {linkAcervoDivulgacao()}
                {linkAcervoDivulgacaoSubPasta(true)}
                {linkAcervoDivulgacaoGaleria(true)}
            </React.Fragment>;
        }
        return '';
    }

    acervoDivulgacaoSubPasta() {
        if (this.props.history.location.pathname === acervoDivulgacaoSubPasta.path) {
            return <React.Fragment>
                {linkInicio()}
                {linkAcervoDivulgacaoSubPasta(true)}
            </React.Fragment>;
        }
        return '';
    }

    pedidoMinhasVendas() {
        if (this.props.history.location.pathname === pedidoMinhasVendas.path) {
            return <React.Fragment>
                <Titulo texto={pedidoMinhasVendas.desc}/>
                {linkInicio()}
                {linkpedidoMinhasVendas(true)}
            </React.Fragment>;
        }
        return '';
    }

    render() {
        return (
            <React.Fragment>
                <div style={{display: 'block', 'fontSize': '20px'}}>
                    {this.acervoDivulgacao()}
                    {this.acervoDivulgacaoGaleria()}
                    {this.acervoDivulgacaoSubPasta()}
                    {this.pedidoMinhasVendas()}
                </div>
                <p className="breadCrumb"></p>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const {isUserAuthenticated} = state.usuario.dadosAutenticacao.isAuthenticated;
    return {
        isUserAuthenticated,
    };
}

export default withRouter(BreadCrumb);
