import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {dismissMessage} from '../../actions/messagesActions';
import styles from './MessagesBarStyles';
import {backgroundColorsMessages, closeMsg, labelTypeMessages} from '../../util/messagesUtil';


export class MessageModal extends React.Component {
    render() {
        const {message} = this.props.messages;
        const open = Boolean(message) && message.modal;
        const msgText = open ? message.msgText : null;
        const color = open ? backgroundColorsMessages[message.type] || backgroundColorsMessages.default : backgroundColorsMessages.none;
        const title = open ? labelTypeMessages[message.type] || backgroundColorsMessages.default : backgroundColorsMessages.none;

        return (
            <Dialog
                open={open}
                onClose={() => closeMsg()}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" style={{color: color}}>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {msgText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeMsg()} color="primary" autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const materialUIEnhance = withStyles(styles)(MessageModal);

const mapStateToProps = ({messages}) => ({messages});


export default connect(mapStateToProps, {dismissMessage})(materialUIEnhance);

