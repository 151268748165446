import React, { Component } from 'react'

class Filtro extends Component {
  constructor (props) {
    super(props);
    this.state = { exibirFiltro: false };
    this.alterarExibicaoFiltro = this.alterarExibicaoFiltro.bind(this)
  }

  alterarExibicaoFiltro () {
    this.setState({ exibirFiltro: !this.state.exibirFiltro })
  }

  render () {
    return (
      <React.Fragment>
        <div className="col-10"/>
        <span className="input-group-append">
          <button id="btn-filtro" className="btn custom-botao-filtro botao-abre-filtro" type="button"
                  onClick={this.alterarExibicaoFiltro}>FILTRO
            <i className="material-icons ml-3">&#xe152;</i>
          </button>
        </span>
        <div className={`filtroLateral ${this.state.exibirFiltro ? 'aberto' : 'fechado'}`}>
          <div className="titulo">
            <span>Filtro</span>
            <button type='button' id="btn-fechar-filtro" className='close fechar-filtro pr-2' aria-label='Close'
                    onClick={this.alterarExibicaoFiltro} style={{ fontSize: '1.5rem' }}>
              <i className="material-icons">
                &#xe5cd;
              </i>
            </button>
          </div>
          <this.props.PainelFiltro/>
          <div className='botao-fechar'>
            <button type='button' className='btn btn-outline-dark' onClick={this.alterarExibicaoFiltro}>Fechar</button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default (Filtro)
