import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authenticateUser } from '../../actions/usuarioActions';
import ModalEsqueciMinhaSenha from './ModalEsqueciMinhaSenha';
import ReCaptchaV2 from 'react-google-recaptcha';
import { AUTHENTICATION_FAILED } from '../../reducers/usuariosReducer';
import { renderField, required } from '../../util/inputs';
import $ from 'jquery';
import BarraNavegacaoLogin from '../layout/BarraNavegacaoLogin';
import TextoInicialLogin from '../layout/TextoInicialLogin';
import CarouselBlog from '../layout/CarouselBlog';

class PaginaLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chaveCaptcha: null
    };
    this.recaptchaRef = React.createRef();
  }

  changeRecaptcha(value) {
    if (value) {
      this.setState({ chaveCaptcha: value })
    }
  }

  onSubmit(values) {
    this.props.authenticateUser({
      usuario: values.username.toLowerCase()
        .trim(),
      senha: values.password,
      plataforma: '',
      captchar: this.state.chaveCaptcha,
    }).then((resp) => {
      if (resp.type === AUTHENTICATION_FAILED) {
        this.setState({ chaveCaptcha: null });
        this.recaptchaRef.current.reset();
      }
    })
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (this.props.dadosAutenticacao.isAuthenticated) {
      $('body').removeClass('app-body2')
      $('body').removeClass('barraNav2')
      $('body').removeClass('container-pagInicial')
      $('body').removeClass('App')

      return (<Redirect to={from} />)
    }

    const { handleSubmit } = this.props;

    return (
      <div className="back-login">
        <BarraNavegacaoLogin />
        <div className='container-pagInicial'>
          <div className='container-texto-login'>
            <TextoInicialLogin />
            <form className="container form-login" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              <div className='loginHeader'>
                <h1>Login</h1>
                <div className="divider"></div>
              </div>
              <label htmlFor='username'>Usuário:</label>
              <Field
                name='username'
                type='email'
                id='username'
                placeholder=''
                validate={[required]}
                component={renderField}
              />
              <label htmlFor='password'>Senha:</label>
              <Field
                name='password'
                id='password'
                type='password'
                placeholder=''
                validate={[required]}
                component={renderField}
              />
              <span className='float-right' data-toggle="modal" data-target="#modalEsqueciMinhaSenha" style={{ cursor: 'pointer' }}>
                Esqueci minha senha
              </span>
              <button id='btn-entrar' type='submit' className='btn btn-laranja btn-sistema'
                disabled={!this.state.chaveCaptcha}>ENTRAR
              </button>
              <div className='col-12 text-center'>
                <ReCaptchaV2
                  ref={this.recaptchaRef}
                  style={{ display: 'inline-block' }}
                  size={window.innerWidth > 450 ? 'normal' : 'compact'}
                  sitekey={'6LfNrvkdAAAAAL2wVMaGIuOwJ0STFzIOavTKhNvw'}
                  onChange={(value) => this.changeRecaptcha(value)}
                  onExpired={() => this.setState({ chaveCaptcha: null })}
                  onErrored={() => this.setState({ chaveCaptcha: null })} />
              </div>
            </form>
          </div>

          <div className='container-blogs'>
            <CarouselBlog />
          </div>
        </div>
        <ModalEsqueciMinhaSenha />
      </div>

    )
  }
}

export const mapStateToProps = state => (
  {
    dadosAutenticacao: state.usuario.dadosAutenticacao,
  }
)

const reduxConnectEnhance = connect(mapStateToProps, { authenticateUser })(PaginaLogin);

export default reduxForm({
  form: 'LoginForm',
})(reduxConnectEnhance)
