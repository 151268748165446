import React, { Component } from 'react';
import '../../estilo/css/PainelMinhasVendas.css';
import { connect } from 'react-redux';
import buscarPedidos from '../../actions/pedido/buscarPedidos';
import { Pedido } from './Pedido';
import alterarExibicaoDetalhamentoPedido from '../../actions/pedido/alterarExibicaoDetalhamentoPedido';
import PainelFiltragem from './PainelFiltrosPedidos'
import { alterouFiltro, alterouPagina } from '../../util/filtros';
import determinaPaginaAtual from '../../actions/pedido/determinarPaginaAtuaPedido';
import limparFiltroPedido from '../../actions/pedido/limparFiltroPedido'
import CabecalhoRodapeTabela from '../comum/CabecalhoRodapeTabela';
import PaginacaoPedido from './PaginacaoPedido';
import BotoesPaginacaoPedido from './BotoesPaginacaoPedido';

export class PainelMinhasVendas extends Component {
    constructor(props) {
        super(props)
        this.alterarExibicao = this.alterarExibicao.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.determinaPaginaAtual(1)
        this.props.limparFiltroPedido();
    }

    componentDidMount() {
        this.buscarPedidos();
    }

    componentDidUpdate(prevProps) {
        if (alterouFiltro(this.props, prevProps) || alterouPagina(this.props, prevProps)) {
            this.buscarPedidos();
            if (!alterouPagina(this.props, prevProps)) {
                this.props.determinaPaginaAtual(1)
            }
        }
    }

    buscarPedidos() {
        this.props.buscarPedidos(this.props.filtrosAdicionados, this.props.paginaAtual, this.props.registrosPorPagina, this.props.filtrosAdicionados);
    }

    alterarExibicao(pedido) {
        this.props.alterarExibicaoDetalhamentoPedido(pedido)
    }

    cabecalhoTabela() {
        return (
            <div className="lista-table d-md-block d-lg-block d-xl-block d-none d-sm-none">
                <div className='row' style={{ padding: '0.7rem' }}>
                    <div className="col-sm"></div>
                    <div className="col-sm"> Pedido</div>
                    <div className="col-sm"> Empresa</div>
                    <div className="col-sm"> Cliente</div>
                    <div className="col-sm"> Data da Venda</div>
                    <div className="col-sm"> Situação</div>
                    <div className="col-sm"> Valor</div>
                    <div className="col-sm"> Pagamento</div>
                    <div className="col-sm"> Recebida</div>
                </div>
            </div>
        )
    }

    exibirPedidos() {
        return this.props.pedidos.map((pedido, indice) => <Pedido key={indice}
                                                                    pedido={pedido}
                                                                    indice={indice}
                                                                    alterarExibicao={this.alterarExibicao} />)
    }

    exibirTabelaPedidos() {
        if(this.props.pedidos && this.props.pedidos.length) {
            return (
                <div id='tabela-pedidos'>
                    <div>
                        <CabecalhoRodapeTabela OpcoesPaginacao={PaginacaoPedido}
                                        BotoesPaginacao={BotoesPaginacaoPedido}
                                        pedidos={this.props.pedidos}>
                        {this.cabecalhoTabela()}
                        <div className="lista-table">
                            {this.exibirPedidos()}
                        </div>
                        </CabecalhoRodapeTabela>
                    </div>                        
                </div>
            )
        }

        return <div className="alert alert-warning text-center mt-4" role="alert">Nenhum pedido localizado para os
            parâmetros informados</div>; 
    }

    render() {
        return (
            <> 
                <div className="col-12 filtro-busca painel-fitro-pedido">
                    <PainelFiltragem />
                </div>                
                <div>                 
                    {this.exibirTabelaPedidos()}
                </div>
            </>
        )
    }
}


export const mapStateToProps = state => {
    return {
        pedidos: state.pedidos.pedidos,
        filtrosAdicionados: state.filtrosAdicionadosPedido,
        paginaAtual: state.paginaAtualPedido,
        registrosPorPagina: state.registrosPorPaginaPedido,        
    }
}

export default connect(mapStateToProps, {
    buscarPedidos,
    alterarExibicaoDetalhamentoPedido,
    limparFiltroPedido,
    determinaPaginaAtual
})(PainelMinhasVendas)