import React from 'react'

export const Opcoes = (numeroElementos) => {
  let opcoes = [10, 20, 30, 40, 50]

  if (numeroElementos < 11) {
    opcoes = [10]
  } else if (numeroElementos < 21) {
    opcoes = [10, 20]
  } else if (numeroElementos < 31) {
    opcoes = [10, 20, 30]
  } else if (numeroElementos < 41) {
    opcoes = [10, 20, 30, 40]
  }

  return opcoes.map(opcao => <option key={opcao} value={opcao}>{opcao}</option>)
}

export const OpcoesDoze = (numeroElementos) => {
  let opcoes = [12, 24, 36, 48, 60]

  if (numeroElementos < 13) {
    opcoes = [12]
  } else if (numeroElementos < 25) {
    opcoes = [12, 24]
  } else if (numeroElementos < 37) {
    opcoes = [12, 24, 36]
  } else if (numeroElementos < 49) {
    opcoes = [12, 24, 36, 48]
  }

  return opcoes.map(opcao => <option key={opcao} value={opcao}>{opcao}</option>)
}

export const onRegistrosPorPaginaChange = (determinarPaginaAtual, alterarRegistrosPorPagina, valor) => {
  determinarPaginaAtual(1)
  alterarRegistrosPorPagina(Number(valor))
}

export const inicioPaginaAtual = (numeroElementos, paginaAtual, registrosPorPagina) => {
  if (!numeroElementos) {
    return 0
  }

  return (paginaAtual - 1) * registrosPorPagina + 1
}

const maiorValorPagina = (numeroElementos, inicioPagina, registrosPorPagina) => Math.min(numeroElementos, inicioPagina
  + registrosPorPagina - 1)

const OpcoesPaginacao = ({
  registrosPorPagina,
  numeroElementos,
  paginaAtual,
  determinarPaginaAtual,
  alterarRegistrosPorPagina,
  opcaoDoze
}) => {
  const inicioPagina = inicioPaginaAtual(numeroElementos, paginaAtual, registrosPorPagina)

  return (
    <div id="paginacao-consulta" className='area-numero-resultados col-12 col-sm-6 col-md-6 col-lg-6 text-center text-sm-left text-md-left text-lg-left'>
      <select className='filtroResultadoEstilo' value={registrosPorPagina}
              onChange={e => onRegistrosPorPaginaChange(determinarPaginaAtual, alterarRegistrosPorPagina,
                e.target.value)}>
        {opcaoDoze ? <OpcoesDoze/> : <Opcoes/>}
      </select>
      {inicioPagina}-{maiorValorPagina(numeroElementos, inicioPagina,
        registrosPorPagina)} de {numeroElementos} encontrados
    </div>
  )
}

export default OpcoesPaginacao
