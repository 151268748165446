import React from 'react';
import Select from 'react-select';
import {
  Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle
} from 'react-accessible-accordion';
import { connect } from 'react-redux';
import { filtroChaveValorJaExiste } from '../../util/filtros';

export class FiltroChaveValor extends React.Component {
  opcoesSelecionadas (filtro) {
    if (this.props.filtrosAdicionados) {
      return this.props.filtrosAdicionados
        .filter(f => f.chave === filtro.chave)
        .map(f => ({ label: f.valor.valor, value: f.valor.chave }))
    }
  }

  adicionarNovoFiltro (filtro) {
    this.props.adicionarFiltro(filtro)
  }

  handleFiltroAdicionadoRadioClick (filtro, valor) {
    const filtroAdicionar = { label: filtro.label, chave: filtro.chave, valor }
    const filtoAtual = this.props.filtrosAdicionados.filter(f => f.chave === filtro.chave)
    if (filtoAtual.length > 0) {
      if (filtroAdicionar.valor === filtoAtual[0].valor) {
        this.props.removerFiltro(filtoAtual[0])
      } else {
        this.props.removerFiltro(filtoAtual[0])
        this.adicionarNovoFiltro(filtroAdicionar)
      }
    } else {
      this.adicionarNovoFiltro(filtroAdicionar)
    }
  }

  handleFiltroAdicionadoChange (filtro, event) {
    const filtrosAdicionadosDoTipo = this.props.filtrosAdicionados.filter(f => f.chave === filtro.chave)
    const filtroFoiAdicionado = event.length > filtrosAdicionadosDoTipo.length

    if (filtroFoiAdicionado) {
      event.forEach((element) => {
        const filtroAdicionar = { label: filtro.label, chave: filtro.chave, valor: element }
        if (!filtroChaveValorJaExiste(this.props.filtrosAdicionados, filtroAdicionar)) {
          this.adicionarNovoFiltro(filtroAdicionar)
        }
      })
    } else {
      filtrosAdicionadosDoTipo.forEach((filtroAdicionado) => {
        if (event.filter(e => e.chave === filtroAdicionado.valor.chave).length === 0) {
          this.props.removerFiltro(filtroAdicionado)
        }
      })
    }
  }

  handleFiltroUnicoAdicionadoChange (filtro, event) {
    const filtrosAdicionadosDoTipo = this.props.filtrosAdicionados.filter(f => f.chave === filtro.chave)
    const filtroFoiAdicionado = event.length > filtrosAdicionadosDoTipo.length

    if (filtroFoiAdicionado) {
      const filtroAdicionar = { label: filtro.label, chave: filtro.chave, valor: event }
      if (!filtroChaveValorJaExiste(this.props.filtrosAdicionados, filtroAdicionar)) {
        this.adicionarNovoFiltro(filtroAdicionar)
      }
    } else {
      filtrosAdicionadosDoTipo.forEach((filtroAdicionado) => {
        if (event.chave === filtroAdicionado.valor.chave) {
          this.props.removerFiltro(filtroAdicionado)
        }
      })
    }
  }

  isOpcaoSelecionada (elemento) {
    return this.props.filtrosAdicionados.find(filtro => filtro.valor === elemento) !== undefined
  }


  exibirFiltro () {
    if (this.props.opcoes && this.props.opcoes.length > 0) {
      if (this.props.isRadio) {
        return (
          this.props.opcoes.map(element => (
                    <label className="checkbox d-block" key={this.props.name + element.valor}>
                        <input className="form-check-input"
                               type="checkbox" name={this.props.name + element.valor}
                               id={this.props.name + element.valor}
                               onChange={() => this.handleFiltroAdicionadoRadioClick(this.props.filtro, element)}
                               checked={this.isOpcaoSelecionada(element)}/>
                        <span>{element.valor}</span>
                    </label>
          ))
        )
      }
      if (this.props.unico) {
        return (
        <div>
          <div className="linha" style={{ marginTop: 10 }}>
            <Select
              noOptionsMessage={() => ('Nenhuma opção encontrada')}
              value={this.opcoesSelecionadas(this.props.filtro)}
              placeholder=''
              options={this.props.opcoes.map(opcao => ({ label: opcao.valor, value: opcao.chave }))}
              onChange={event => this.handleFiltroAdicionadoRadioClick(this.props.filtro, {
                chave: event.value,
                valor: event.label
              })}
            />
          </div>
        </div>)
      }


      return (
              <div>
                  <div className="linha" style={{ marginTop: 10 }}>
                      <Select
                          noOptionsMessage={() => ('Nenhuma opção encontrada')}
                          value={this.opcoesSelecionadas(this.props.filtro)}
                          isMulti
                          placeholder=''
                          options={this.props.opcoes.map(opcao => ({ label: opcao.valor, value: opcao.chave }))}
                          onChange={event => this.handleFiltroAdicionadoChange(this.props.filtro, event.map(ev => ({
                            chave: ev.value,
                            valor: ev.label
                          })))}
                      />
                  </div>
              </div>


      )
    }
  }

  render () {
    if (!this.props.filtrosAdicionados) {
      return <div>Carregando...</div>
    }
    return (
            <Accordion className=''>
                <AccordionItem expanded={true}>
                    <AccordionItemTitle>
                        <h6>{this.props.filtro.label}</h6>
                        <div className="accordion__arrow" role="presentation"/>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        {this.exibirFiltro()}
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
    )
  }
}
export default connect()(FiltroChaveValor)
