import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { setBrowserHistory } from '../util/applicationContext';
import AuthenticatedRoute from './AuthenticatedRoute';
import Login from '../componentes/usuario/PaginaLogin';
import Logout from '../componentes/usuario/Logout';
import PaginaTrocaSenha from '../componentes/usuario/PaginaTrocaSenha';
import Dashboard from '../componentes/inicio/Dashboard';
import PainelAcervoDivulgacao from '../componentes/acervo/PainelAcervoDivulgacao';
import GaleriaAcervoDivulgacao from '../componentes/acervo/GaleriaAcervoDivulgacao';
import PainelAcervoDivulgacaoSubPasta from '../componentes/acervo/PainelAcervoDivulgacaoSubPasta';
import PainelMinhasVendas from '../componentes/pedido/PainelMinhasVendas';
import PainelItinerarios from '../componentes/itinerarios/PainelItinerarios';
import Atendimento from '../componentes/layout/Atendimento';
import PoliticaCookies from '../componentes/layout/PoliticaCookies';
import PoliticaPrivacidade from '../componentes/layout/PoliticaPrivacidade';
import QuemSomos from '../componentes/layout/QuemSomos';
import TermosUso from '../componentes/layout/TermosUso';

import {
    acervoDivulgacao,
    acervoDivulgacaoGaleria,
    acervoDivulgacaoSubPasta,
    login,
    logout,
    pedidoMinhasVendas,
    painelItinerarios,
    raiz,
    trocaSenha,
    atendimento,
    politicaCookies,
    politicaPrivacidade,
    termosUso,
    quemSomos
} from './rotas';

export class AppRoutes extends React.Component {
    constructor(props) {
        super(props);
        const { history } = this.props;
        setBrowserHistory(history)
    }

    render() {
        return (
            <Switch>
                <Route path={login.path} component={Login} />
                <Route path={logout.path} component={Logout} />
                <Route path={atendimento.path} component={Atendimento} />
                <Route path={politicaCookies.path} component={PoliticaCookies} />
                <Route path={politicaPrivacidade.path} component={PoliticaPrivacidade} />
                <Route path={termosUso.path} component={TermosUso} />
                <Route path={quemSomos.path} component={QuemSomos} />
                <Route path={trocaSenha.path} component={PaginaTrocaSenha} />
                <AuthenticatedRoute path={acervoDivulgacaoSubPasta.path} component={PainelAcervoDivulgacaoSubPasta} />
                <AuthenticatedRoute path={acervoDivulgacao.path} component={PainelAcervoDivulgacao} />
                <AuthenticatedRoute path={acervoDivulgacaoGaleria.path} component={GaleriaAcervoDivulgacao} />
                <AuthenticatedRoute path={pedidoMinhasVendas.path} component={PainelMinhasVendas} />
                <AuthenticatedRoute path={painelItinerarios.path} component={PainelItinerarios} />
                <AuthenticatedRoute path={raiz.path} component={Dashboard} />
            </Switch>
        )
    }
}

export default withRouter(AppRoutes)
