import React from 'react';
import WhatsApp from '@material-ui/icons/WhatsApp';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import DirectionsBusOutlinedIcon from '@material-ui/icons/DirectionsBusOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import BarraNavegacaoLogin from './BarraNavegacaoLogin';
import ('../../util/pg-contato.min.js')

const url = window.location.protocol + '//' + window.location.host;

export class Atendimento extends React.Component {
    render() {
        return (
            <div className='conteudo'>
                <BarraNavegacaoLogin />
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                                <h1 id='secao-atendimento' className='titulo-pg-atendimento'>Central de Atendimento</h1>
                                <h3 className='subtitulo-pg-atendimento'>Não passe perrengue! Tire suas dúvidas ou entre em contato com <span className='nome-emp-atendimento'></span>.</h3>
                                <div className="divider" />
                            </div>
                            <div className="page-contato">
                                <section className="gv-section">
                                    <div className="section-content">
                                        <h2 className="gv-title">Dúvidas mais procuradas</h2>
                                        <section className="gv-cards">
                                            <div className="gv-cards-wrapper">
                                                <div className="gv-card">
                                                    <a href="#" data-detail-trigger="para-compra">
                                                        <span className="gv-card-icon gv-card-icon-compra">
                                                            <i className="material-icons"><ConfirmationNumberOutlinedIcon></ConfirmationNumberOutlinedIcon></i>
                                                        </span>
                                                        <h4 className="gv-card-title">Como compro minha passagem?</h4>
                                                    </a>
                                                    <a href="#" data-detail-trigger="para-compra" className='container-btn-info'>
                                                        <span className='btn-abre-info'><ArrowForwardOutlinedIcon></ArrowForwardOutlinedIcon></span>
                                                    </a>
                                                </div>
                                                <div className="gv-card">
                                                    <a href="#" data-detail-trigger="para-retirar">
                                                        <span className="gv-card-icon gv-card-icon-troca">
                                                            <i className="material-icons"><DateRangeOutlinedIcon></DateRangeOutlinedIcon></i>
                                                        </span>
                                                        <h4 className="gv-card-title">Como retiro minha passagem?</h4>
                                                    </a>
                                                    <a href="#" data-detail-trigger="para-retirar" className='container-btn-info'>
                                                        <span className='btn-abre-info'><ArrowForwardOutlinedIcon></ArrowForwardOutlinedIcon></span>
                                                    </a>
                                                </div>
                                                <div className="gv-card">
                                                    <a href="#=" data-detail-trigger="para-alteracao">
                                                        <span className="gv-card-icon gv-card-icon-passagem">
                                                            <i className="material-icons"><DirectionsBusOutlinedIcon></DirectionsBusOutlinedIcon></i>
                                                        </span>
                                                        <h4 className="gv-card-title">Como altero minha reserva?</h4>
                                                    </a>
                                                    <a href="#" data-detail-trigger="para-alteracao" className='container-btn-info'>
                                                        <span className='btn-abre-info'><ArrowForwardOutlinedIcon></ArrowForwardOutlinedIcon></span>
                                                    </a>
                                                </div>
                                                <div className="gv-card">
                                                    <a href="#" data-detail-trigger="para-cancelamento">
                                                        <span className="gv-card-icon gv-card-icon-cancelamento">
                                                            <i className="material-icons"><CancelOutlinedIcon></CancelOutlinedIcon></i>
                                                        </span>
                                                        <h4 className="gv-card-title">Como cancelo minha compra?</h4>
                                                    </a>
                                                    <a href="#" data-detail-trigger="para-cancelamento" className='container-btn-info'>
                                                        <span className='btn-abre-info'><ArrowForwardOutlinedIcon></ArrowForwardOutlinedIcon></span>
                                                    </a>
                                                </div>
                                                <div className="gv-card-detail" data-detail-target="para-compra">
                                                    <span className="gv-card-detail-back">
                                                        <i className="material-icons">arrow_back</i>
                                                    </span>
                                                    <div className="gv-card-detail-left">
                                                        <div className="gv-card-full">
                                                            <i className="gv-card-icon gv-card-icon-compra"></i>
                                                            <h4>Como compro minha passagem no Site?</h4>
                                                        </div>
                                                    </div>
                                                    <div className="gv-card-detail-right">
                                                        <p>
                                                            Para garantir sua passagem, acesse nosso site, 
                                                            ou baixe nosso aplicativo e informe os dados da sua viagem. Preencha os dados do
                                                            passageiro, do pagamento e pronto!
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="gv-card-detail" data-detail-target="para-retirar">
                                                    <span className="gv-card-detail-back">
                                                        <i className="material-icons">arrow_back</i>
                                                    </span>
                                                    <div className="gv-card-detail-left">
                                                        <div className="gv-card-full">
                                                            <i className="gv-card-icon gv-card-icon-retirar"></i>
                                                            <h4>Como retiro minha passagem?</h4>
                                                        </div>
                                                    </div>
                                                    <div className="gv-card-detail-right">
                                                        <p>
                                                            Acesse o site <a href="https://bilhetenamao.com.br" target="_blank"
                                                                rel="noreferrer"> https://bilhetenamao.com.br </a>, preencha os
                                                            dados solicitados de seu pedido e baixe o bilhete, ou se preferir, chegue com antecedência na
                                                            rodoviária e apresente o documento original com foto utilizado na compra da passagem no balcão de atendimento da empresa de ônibus.
                                                        </p>
                                                        <p>Se o Embarque Fácil estiver disponível para a sua viagem, basta apresentar o bilhete
                                                            digital com QR Code junto com um documento com foto direto ao motorista na
                                                            plataforma de embarque.</p>
                                                    </div>
                                                </div>
                                                <div className="gv-card-detail" data-detail-target="para-alteracao">
                                                    <span className="gv-card-detail-back">
                                                        <i className="material-icons">arrow_back</i>
                                                    </span>
                                                    <div className="gv-card-detail-left">
                                                        <div className="gv-card-full">
                                                            <i className="gv-card-icon gv-card-icon-alteracao"></i>
                                                            <h4>Como altero minha reserva?</h4>
                                                        </div>
                                                    </div>
                                                    <div className="gv-card-detail-right">
                                                        <p>
                                                            A alteração pode ser realizada com até 3 horas antes do
                                                            horário de embarque e a passagem não pode ter sido retirada na rodoviária.
                                                        </p>
                                                        <p>Para alterar, entre em contato conosco em nosso horário de atendimento.</p>
                                                    </div>
                                                </div>
                                                <div className="gv-card-detail" data-detail-target="para-cancelamento">
                                                    <span className="gv-card-detail-back">
                                                        <i className="material-icons">arrow_back</i>
                                                    </span>
                                                    <div className="gv-card-detail-left">
                                                        <div className="gv-card-full">
                                                            <i className="gv-card-icon gv-card-icon-cancelamento" />
                                                            <h4>Como cancelar minha compra?</h4>
                                                        </div>
                                                    </div>
                                                    <div className="gv-card-detail-right">
                                                        <p>O cancelamento total da compra por ser realizado pelo site ou aplicativo.
                                                        </p>
                                                        <p>Se precisar realizar o cancelamento de uma ou mais passagens, entre em contato conosco
                                                            em nosso horário de atendimento.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <h2 className="gv-title">
                                            Tem outra dúvida? Selecione uma categoria que vamos te ajudar:
                                        </h2>
                                        <div className="page-contato-block-accordion">
                                            <div className="menu-accordion" id="accordion-compra">
                                                <div className="menu-accordion-group">
                                                    <div className="menu-accordion-heading">
                                                        <a className="menu-accordion-toggle" href="#item-compra-0">
                                                            Processo de compra
                                                            <i className="fa fa-caret-down p-float-right"></i>
                                                        </a>
                                                    </div>
                                                    <div id="item-compra-0" className="menu-accordion-body"
                                                        style={{ overFlow: 'hidden', display: 'none' }}>
                                                        <div className="menu-accordion-inner">
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar uma passagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div
                                                                className="menu-accordion-inner-collapse menu-accordion-inner-collapse-active">
                                                                <p>Quer viajar de ônibus? Passe aqui.</p><br />
                                                                <p>Pelo celular, baixe nosso aplicativo ou por um
                                                                    computador acesse <a title="https://vexado.com.br"></a> </p> <br />
                                                                <ul>
                                                                    <li>
                                                                        1. Coloque o nome da cidade de saída, a cidade
                                                                        de
                                                                        destino e então a data da
                                                                        viagem;
                                                                    </li>
                                                                    <li>
                                                                        2. Clique em Buscar;
                                                                    </li>
                                                                    <li>
                                                                        3. Pronto! Você verá todos os horários, valores
                                                                        e
                                                                        empresas que fazem a viagem.
                                                                    </li>
                                                                </ul>
                                                                <br />
                                                                <p>Agora é só escolher o melhor horário que te atende e
                                                                    depois a poltrona da sua
                                                                    viagem.</p><br />
                                                                <p>Após colocar seu e-mail e ir para a página de
                                                                    pagamento, preencha os dados do
                                                                    passageiro (CPF, nome completo, telefone e número do documento).</p><br />
                                                                <p>Em seguida, preencha os dados do comprador (responsável pelo
                                                                    pagamento).</p><br />
                                                                <p>Quando tudo estiver preenchido, é só finalizar o
                                                                    pedido.</p><br />
                                                                <p>A confirmação da compra com os dados da viagem e do
                                                                    passageiro serão enviados ao seu
                                                                    e-mail.</p><br />
                                                                <p>Viajar de ônibus pode ser melhor do que você
                                                                    imagina!</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                É seguro comprar no site?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Garanta sua passagem sem perrengue algum, o site é
                                                                    100% seguro!</p><br />
                                                                <p>Temos um selo de segurança chamado Comodo, que
                                                                    garante a
                                                                    segurança das suas compras.
                                                                    Além disso, ao acessar o site pelo navegador,
                                                                    naquele
                                                                    espaço onde você escreve o
                                                                    endereço, possui uma indicação de que o
                                                                    nosso
                                                                    site é seguro, com um
                                                                    desenho de um cadeado.</p><br />
                                                                <p>Também não temos acesso aos dados do seu cartão, a
                                                                    compra
                                                                    só é autorizada pelo seu
                                                                    banco.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar passagens para estrangeiros?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Ainda não conseguimos efetuar venda de passagens
                                                                    para
                                                                    estrangeiros que não tenham
                                                                    CPF. Entre em contato com os nossos atendentes para
                                                                    juntos encontrarmos uma solução.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar usando um CNPJ?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Pelo site ou pelo aplicativo, vendemos passagens
                                                                    apenas
                                                                    para pessoas físicas, pois
                                                                    durante a compra é necessário informar um CPF.</p>
                                                                <br />
                                                                <p>Se precisar emitir a nota fiscal com outro número de
                                                                    documento ou com CNPJ, entre em
                                                                    contato conosco em nosso horário de atendimento <b>antes da
                                                                        viagem</b> e informe os dados para
                                                                    alteração.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar passagem para outra pessoa?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Nos dados do passageiro basta preencher com nome completo e número de documento da 
                                                                    pessoa que irá viajar. Já nos dados do pagamento, preencha com as informações de 
                                                                    quem está comprando a passagem.</p>
                                                                <br />
                                                                <p>Após a compra finalizada, é só encaminhar o voucher
                                                                    da
                                                                    viagem, que é enviado por
                                                                    e-mail, para o passageiro realizar o embarque.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como escolher uma poltrona?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>No site e no aplicativo, as poltronas disponíveis
                                                                    ficam
                                                                    marcadas com o número
                                                                    correspondente a elas. Para escolher a sua, é só
                                                                    selecionar a que mais te agrada e a
                                                                    poltrona ficará na cor amarela. Depois de selecionada(s)
                                                                    a(s)
                                                                    poltrona(s), basta continuar
                                                                    o processo de compra normalmente.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="menu-accordion-heading">
                                                        <a className="menu-accordion-toggle" href="#item-compra-1">
                                                            Retirada de passagem e embarque
                                                            <i className="fa fa-caret-down p-float-right" />
                                                        </a>
                                                    </div>
                                                    <div id="item-compra-1" className="menu-accordion-body"
                                                        style={{ overFlow: 'hidden', display: 'none' }}>
                                                        <div className="menu-accordion-inner">
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como retiro minha passagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Para retirar a passagem o passageiro deve chegar com
                                                                    antecedência na rodoviária e
                                                                    apresentar um documento original com foto junto com
                                                                    o
                                                                    voucher da compra para o
                                                                    bilheteiro, no balcão de atendimento da empresa de
                                                                    ônibus.</p><br />
                                                                <p>Também é possível retirar a passagem nos totens de
                                                                    autoatendimento.</p><br />
                                                                <p>Se o Embarque Fácil estiver disponível para a sua
                                                                    viagem,
                                                                    basta apresentar seu
                                                                    bilhete digital com QR Code junto com um documento
                                                                    com
                                                                    foto direto ao motorista na
                                                                    plataforma de embarque.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Preciso imprimir o voucher para retirar minha passagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Em alguns casos é preciso apresentar o voucher
                                                                    impresso
                                                                    para retirar a passagem. Mas
                                                                    não se preocupe! Quando houver necessidade, essa
                                                                    informação estará no voucher
                                                                    enviado para seu e-mail.</p><br />
                                                                <p>Caso não tenha nenhuma orientação para impressão, é
                                                                    só
                                                                    apresentar seu documento de
                                                                    identidade com foto no balcão de atendimento da
                                                                    empresa
                                                                    de ônibus ou trem turístico
                                                                    que realizará sua viagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Outra pessoa pode retirar a passagem em meu nome?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Não, apenas o passageiro pode retirar a passagem,
                                                                    apresentando um documento original
                                                                    com foto.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como encontro o endereço do meu local de embarque?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>O ponto de partida das viagens é sempre a rodoviária
                                                                    da
                                                                    cidade que irá partir. As
                                                                    informações sobre o local de embarque e a cidade de
                                                                    destino estarão na página de
                                                                    finalização de compra.</p><br />
                                                                <p>Algumas viagens podem ter locais específicos para
                                                                    embarque, mas nesses casos a
                                                                    informação estará descrita no voucher da
                                                                    passagem.</p>
                                                                <br />
                                                                <p>Se precisar embarcar em um local diferente, é
                                                                    necessário entrar em contato com a
                                                                    empresa responsável pela sua viagem para verificar a
                                                                    possibilidade e
                                                                    avisar o motorista.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Perdi o horário de embarque. O que fazer?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">

                                                                <p>Só conseguimos trocar ou cancelar a compra
                                                                    até 3
                                                                    horas antes do
                                                                    embarque.</p><br />
                                                                <p>Se passar desse prazo, é preciso falar com a empresa
                                                                    de
                                                                    ônibus responsável pela sua
                                                                    viagem.</p><br />
                                                                <p>Eles podem te informar se ainda é possível trocar
                                                                    ou
                                                                    cancelar a compra, ou se a
                                                                    passagem pode ser remarcada para outro dia.</p>
                                                                <br />
                                                                <p><strong>ATENÇÃO!</strong> A remarcação pode estar disponível ou não,
                                                                    dependendo do órgão regulador da
                                                                    viagem. Se estiver disponível, o prazo é de até 1
                                                                    ano
                                                                    para utilizar o bilhete,
                                                                    podendo ser cobrada uma taxa de 20% sobre o valor da
                                                                    passagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Menores de idade podem viajar sozinhos?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Confira as regras para viagens com crianças e
                                                                    adolescentes:</p><br />
                                                                <p><b>Crianças:</b></p><br />
                                                                <p>- Com menos de 6 anos completos não pagam passagem e
                                                                    viajam no colo do responsável.
                                                                    Nesse caso, não precisa informar nada na hora da
                                                                    compra.
                                                                    No dia da viagem é preciso
                                                                    passar antes no balcão da viação e apresentar um
                                                                    documento de identidade (RG ou
                                                                    Certidão de Nascimento) para emitirem um bilhete
                                                                    para o
                                                                    passageiro não pagante.</p>
                                                                <br />
                                                                <p>- A partir dos 6 anos completos a criança já é
                                                                    obrigada a viajar na poltrona e paga a
                                                                    passagem normalmente.</p><br />
                                                                <p>- Com 12 anos completos a Certidão de Nascimento
                                                                    deixa de ser válida como documento,
                                                                    sendo obrigatório a apresentação do RG.</p><br />
                                                                <p><b>Adolescentes:</b></p><br />
                                                                <p>- A partir dos 16 anos completos já pode viajar
                                                                    sozinho, é só levar o RG original no
                                                                    dia da viagem.</p><br />
                                                                <p>- Com menos de 16 anos pode viajar acompanhado de um
                                                                    parente direto (pais, avós ou
                                                                    tios) que levem também um documento para comprovar o
                                                                    parentesco.</p><br />
                                                                <p>- Se for desacompanhado, precisa apresentar uma
                                                                    Autorização Judicial feita pelos
                                                                    responsáveis para fazer a viagem. Essa autorização
                                                                    pode ser retirada no Juizado de
                                                                    menores da sua cidade.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual o limite de bagagens que posso levar na minha
                                                                viagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Cada passageiro pode levar até 30kg de malas no
                                                                    bagageiro
                                                                    externo e 5kg de bagagens
                                                                    de mão, conforme dimensões especificadas pela ANTT e
                                                                    também nos 
                                                                    <a href={`${url}/termos`} target="_blank" rel="noreferrer"> termos e
                                                                        condições de uso</a>.</p> <br/>
                                                                <p>Se precisar levar mais que isso, você pode, mas
                                                                    precisa
                                                                    entrar em contato com a
                                                                    empresa responsável pela viagem para saber sobre o
                                                                    valor
                                                                    que será pago.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Posso levar animais na viagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Antes de tudo, certifique-se que a empresa
                                                                    responsável
                                                                    pela sua viagem permite o
                                                                    transporte de animais.</p><br />
                                                                <p>Se você já tem autorização da empresa, fique atento a
                                                                    essas regras:</p><br />
                                                                <p>- Apenas cães e gatos de pequeno e médio porte, de
                                                                    até 10
                                                                    quilos, têm autorização
                                                                    para o embarque;</p>
                                                                <p>- O passageiro deve apresentar carteira de vacinação
                                                                    atualizada, na qual conste a
                                                                    aplicação das vacinas antirrábica e polivalente;</p>
                                                                <p>- O transporte de animais deve ser feito em caixa
                                                                    transportadora, garantindo as
                                                                    condições de higiene, segurança e conforto do
                                                                    animal. </p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual é o trajeto da minha viagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Não temos acesso ao itinerário da viagem. Para
                                                                    conseguir
                                                                    essa informação, entre em
                                                                    contato direto com a empresa responsável por sua
                                                                    viagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual a diferença dos tipos de ônibus?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Os principais tipos de ônibus são o convencional,
                                                                    executivo, semi-leito e leito-cama.
                                                                    Abaixo estão as principais características de cada
                                                                    um:</p><br />
                                                                <p><b>Ônibus convencional:</b> normalmente possui cerca
                                                                    de
                                                                    42 poltronas convencionais
                                                                    acolchoadas, mas pouco reclináveis. Alguns modelos
                                                                    contam com ar-condicionado
                                                                    e banheiro e é mais indicado para viagens de curta
                                                                    duração, pois não oferecem
                                                                    serviços extras.</p><br />
                                                                <p><b>Ônibus executivo:</b> é mais confortável por ter um espaço mais amplos para as 
                                                                    poltronas e apoio para pernas. Além do ar-condicionado, algumas viações costumam contar 
                                                                    com serviços extras como água a bordo.</p><br />
                                                                <p><b>Ônibus semi leito:</b> as poltronas se reclinam mais e por isso são mais confortáveis. 
                                                                    Além disso, alguns possuem TV, geladeira, apoio para as pernas e serviço de bordo. Por ser 
                                                                    mais confortável é mais indicado para viagens longas.
                                                                </p><br />
                                                                <p><b>Ônibus leito-cama:</b> é a melhor opção para quem procura máximo conforto, tendo poltronas 
                                                                    duplas e anatômicas que viram uma verdadeira cama. Também podem oferecer extras que fazem toda 
                                                                    a diferença durante uma viagem longa: descanso para pernas, mantas, travesseiro e geladeira.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Quais os documentos necessários para retirar minha
                                                                passagem
                                                                e realizar a viagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>O passageiro precisa apresentar um documento original
                                                                    que
                                                                    tenha foto, como RG, CNH ou
                                                                    até a Carteira de Trabalho. Cópias não são
                                                                    aceitas.</p>
                                                                <br />
                                                                <p>O número do documento utilizado na compra, nos dados
                                                                    do passageiro, deve ser o mesmo
                                                                    documento que será apresentado na viagem.</p><br />
                                                                <p>Para crianças, serve tanto o RG como a Certidão de
                                                                    Nascimento. A partir de 12 anos
                                                                    completos é obrigatória a apresentação do RG para o
                                                                    embarque.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="menu-accordion-heading">
                                                        <a className="menu-accordion-toggle" href="#item-compra-2">
                                                            Pagamento
                                                            <i className="fa fa-caret-down p-float-right"></i>
                                                        </a>
                                                    </div>
                                                    <div id="item-compra-2" className="menu-accordion-body"
                                                        style={{ overFlow: 'hidden', display: 'none' }}>
                                                        <div className="menu-accordion-inner">
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Quais são as formas de pagamento?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>No site você consegue comprar com cartão de crédito,
                                                                    débito, PIX ou transferência
                                                                    bancária.</p><br />
                                                                <p>Para compra com <b>cartão de débito</b> você tem 2
                                                                    opções:</p><br />
                                                                <p>1. Acessar o site através de um computador que tenha
                                                                    o
                                                                    dispositivo de segurança do
                                                                    seu banco instalado nele</p>
                                                                <p>2. Se estiver comprando pelo celular, o número do seu
                                                                    aparelho celular precisa ser
                                                                    registrado no seu banco para transações de compras
                                                                    no
                                                                    débito.</p><br />
                                                                <p>Lembrando que para débito ainda não temos opção para
                                                                    bandeira ELO e cartões da Caixa
                                                                    Econômica. Se seu cartão for um desses, você pode
                                                                    optar
                                                                    por fazer transferência
                                                                    bancária ou PIX.</p><br />
                                                                <p>A compra por <b>cartão de crédito</b> pode exigir a
                                                                    autenticação de pagamento. Essa
                                                                    autenticação é um procedimento de segurança do
                                                                    cartão e
                                                                    pode ser solicitado
                                                                    informações pessoais (token ou senha do cartão).</p>
                                                                <br />
                                                                <p>Por <b>Pix</b>, ao finalizar o pedido, irá aparecer
                                                                    um QR
                                                                    Code na tela, é só abrir o
                                                                    aplicativo do seu banco pelo celular e escanear o QR
                                                                    Code para fazer o pagamento ou
                                                                    utilizar a opção PIX Copia e Cola.</p>
                                                                <p>Após confirmado, você recebe as informações da viagem
                                                                    por
                                                                    e-mail dentro de 30
                                                                    minutos.</p><br />
                                                                <p>Para <b>Transferência Bancária</b>, ao finalizar a
                                                                    compra
                                                                    irá abrir uma aba com os
                                                                    dados bancários da PayMee Brasil Serviços de
                                                                    Pagamentos
                                                                    SA.</p><br />
                                                                <p>Então você pode acessar seu banco (direto na agência
                                                                    bancária, pelo site ou
                                                                    aplicativo de celular) e realizar a transferência no
                                                                    prazo de até 2 horas.</p>
                                                                <p>Assim que a transferência é realizada, o pedido se
                                                                    confirma dentro de 40 minutos e o
                                                                    voucher é enviado ao seu e-mail.</p><br />
                                                                <p><b>ATENÇÃO!</b> Não é possível efetuar o pagamento em
                                                                    lotéricas ou correspondentes
                                                                    bancários, pois essas instituições realizam
                                                                    depósitos,
                                                                    ao invés de transferência, e
                                                                    o valor só é compensado após 24 horas, ok?</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como reservar uma viagem para pagar na rodoviária?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Por aqui não reservamos passagens, fazemos a venda.
                                                                    Por
                                                                    isso, não é possível deixar
                                                                    uma passagem reservada pelo site para depois
                                                                    finalizar
                                                                    o pagamento na
                                                                    rodoviária.</p><br />
                                                                <p>Para ter sua passagem garantida, a melhor opção é
                                                                    finalizar sua compra no site ou
                                                                    aplicativo.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                O preço da passagem é o mesmo da rodoviária?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Sim, os preços disponíveis nos sites são os mesmos da
                                                                    rodoviária, exceto quando há
                                                                    promoção disponível para o trecho no site. Neste
                                                                    caso, o
                                                                    valor com desconto é apenas
                                                                    para compra pelo site ou app.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                O que são as taxas cobradas em minha compra?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Compras feitas pelo site é cobrado a taxa de
                                                                    conveniência
                                                                    referentes aos serviços que
                                                                    prestamos para as empresas de ônibus. A taxa é de
                                                                    7%
                                                                    sobre o valor da passagem.</p>
                                                                <br />
                                                                <p>Além disso, você tem acesso à tecnologia de ponta
                                                                    para garantir suas passagens de
                                                                    ônibus online e com segurança e a possibilidade de
                                                                    fazer trocas e cancelamentos
                                                                    gratuitamente.</p><br />
                                                                <p>Já pelo aplicativo, você garante vantagens
                                                                    exclusivas como isenção da taxa
                                                                    de conveniência para compras feitas dentro de 30
                                                                    dias com seu login.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Tive uma cobrança em meu cartão, mas minha compra não
                                                                foi
                                                                concluída. O que fazer?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Quando a compra não é concluída e a reserva da
                                                                    poltrona
                                                                    não é confirmada, o valor é
                                                                    reembolsado automaticamente pelo site.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como usar um cupom de desconto?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Os cupons de descontos podem ser usados em compras feitas no site.
                                                                    Na página de pagamento, logo abaixo das informações do passageiro, 
                                                                    há um campo para adicionar o cupom. Coloque o código do cupom e clique em Aplicar.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como emitir a nota fiscal da minha compra?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>A nota fiscal da compra feita no site é referente à
                                                                    nossa taxa de conveniência. Essa
                                                                    nota é emitida e enviada no e-mail cadastrado na
                                                                    compra
                                                                    após 48 horas da viagem
                                                                    realizada.</p><br />
                                                                <p>Caso precise da nota fiscal da passagem, você deve
                                                                    solicitar diretamente à empresa de
                                                                    ônibus responsável pela viagem. Essa nota costuma
                                                                    ser o
                                                                    próprio bilhete fiscal
                                                                    (passagem).</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="menu-accordion-heading">
                                                        <a className="menu-accordion-toggle" href="#item-compra-3">
                                                            Troca e remarcação
                                                            <i className="fa fa-caret-down p-float-right"></i>
                                                        </a>
                                                    </div>
                                                    <div id="item-compra-3" className="menu-accordion-body"
                                                        style={{ overFlow: 'hidden', display: 'none' }}>
                                                        <div className="menu-accordion-inner">
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual a diferença de trocar e remarcar uma viagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>A troca da passagem é feita antes da viagem pela empresa e
                                                                    é gratuita. Para isso, basta
                                                                    entrar em contato conosco em nosso horário de atendimento.</p>
                                                                <br />
                                                                <p>O prazo para alteração é de até 3 horas antes do
                                                                    embarque, porém pode variar conforme
                                                                    a empresa de ônibus e o trajeto que ela opera.
                                                                    Consulte
                                                                    sempre no voucher da compra
                                                                    o prazo de alterações e cancelamento da sua
                                                                    viagem!</p>
                                                                <br />
                                                                <p>Já a remarcação acontece após a viagem, caso o
                                                                    passageiro não embarque, e deve ser
                                                                    feita diretamente com a empresa de ônibus, pagando
                                                                    multa de 20% do valor da
                                                                    passagem.</p><br />
                                                                <p><b>ATENÇÃO!</b> A remarcação pode estar disponível ou não,
                                                                    dependendo do órgão regulador da
                                                                    viagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Posso fazer alterações nos dados da minha passagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Sim, é possível alterar as passagens que foram
                                                                    adquiridas
                                                                    pelo site ou aplicativo da empresa, e para isso é preciso entrar em contato com
                                                                    nosso time dentro do horário de
                                                                    atendimento.</p><br />
                                                                <p>As alterações devem ser solicitadas até 3 horas antes
                                                                    do
                                                                    embarque e a passagem não
                                                                    pode ter sido emitida na rodoviária.</p><br />
                                                                <p>Caso precise alterar a data ou o horário da viagem, a
                                                                    nova passagem tem que ser de
                                                                    valor igual ou menor à que você comprou.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Posso transferir minha passagem para outra pessoa?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Sim, é possível transferir a passagem para outra
                                                                    pessoa.
                                                                    Para isso, atente-se ao
                                                                    prazo para fazer alterações na passagem.</p><br />
                                                                <p>Para essa alteração serão necessários o nome completo
                                                                    do
                                                                    novo passageiro e o número
                                                                    do documento de identidade.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual o prazo para fazer a troca da minha passagem com a empresa?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>As trocas podem ser feitas até 3 horas antes do
                                                                    horário
                                                                    marcado para a viagem, mas
                                                                    quanto antes, melhor.</p><br />
                                                                <p>É importante ficar atento ao nosso horário de
                                                                    atendimento. Caso não consiga contato,
                                                                    você deve entrar em contato com a empresa
                                                                    responsável
                                                                    pela sua viagem, seja por
                                                                    telefone ou direto na rodoviária, no balcão de
                                                                    atendimento.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                É cobrado algum valor para fazer a troca ou remarcação
                                                                da
                                                                minha viagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>As trocas são feitas sem nenhum custo
                                                                    adicional. Para isso, basta entrar
                                                                    em contato com nosso time de atendimento até 3
                                                                    horas antes do horário de
                                                                    embarque.</p><br />
                                                                <p>Já a remarcação da passagem é um procedimento feito
                                                                    diretamente com a empresa de
                                                                    ônibus, no balcão de atendimento da rodoviária.</p>
                                                                <br />
                                                                <p>Se perder a viagem por algum motivo, é possível
                                                                    remarcar
                                                                    pagando uma taxa de até 20%
                                                                    do valor da passagem, a critério da empresa de
                                                                    ônibus
                                                                    conforme regulamentação, e a
                                                                    passagem vale por até 1 ano para ser utilizada.</p>
                                                                <br />
                                                                <p><b>ATENÇÃO!</b> A remarcação pode estar disponível ou não,
                                                                    dependendo do órgão regulador da
                                                                    viagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como consigo remarcar uma passagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Você deve ir à rodoviária com o documento de
                                                                    identidade,
                                                                    a passagem em mãos (caso
                                                                    tenha retirado) e solicitar a remarcação no balcão
                                                                    de
                                                                    atendimento da empresa
                                                                    responsável pela sua viagem.</p><br />
                                                                <p>A passagem vale por 1 ano a partir da data marcada
                                                                    para
                                                                    a viagem. Normalmente, as
                                                                    viações cobram uma taxa de 20% do valor da passagem
                                                                    por
                                                                    esse serviço.</p><br />
                                                                <p><b>ATENÇÃO!</b> A remarcação pode estar disponível ou não,
                                                                    dependendo do órgão regulador da
                                                                    viagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Já estou com meu bilhete de embarque. Posso fazer
                                                                alterações?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Se já retirou o bilhete na rodoviária, tanto para
                                                                    troca
                                                                    ou cancelamento, é preciso
                                                                    devolver o bilhete para a empresa de ônibus
                                                                    responsável
                                                                    pela viagem. </p><br />
                                                                <p>Para cancelamento, o prazo é de até 3 horas antes da
                                                                    viagem para cancelar a
                                                                    compra.</p>
                                                                <p>Para troca, o prazo é o mesmo. Você devolve a
                                                                    passagem e
                                                                    eles emitem outra com a
                                                                    alteração feita.</p><br />
                                                                <p><b>ATENÇÃO!</b> Não esqueça de verificar o horário de
                                                                    atendimento
                                                                    da viação na
                                                                    rodoviária.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="menu-accordion-heading">
                                                        <a className="menu-accordion-toggle" href="#item-compra-4">
                                                            Cancelamento e reembolso
                                                            <i className="fa fa-caret-down p-float-right"></i>
                                                        </a>
                                                    </div>
                                                    <div id="item-compra-4" className="menu-accordion-body"
                                                        style={{ overFlow: 'hidden', display: 'none' }}>
                                                        <div className="menu-accordion-inner">
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como faço para cancelar minha compra?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Conseguimos cancelar a passagem até 3 horas antes do
                                                                    horário de embarque e o bilhete
                                                                    não pode ter sido retirado na rodoviária.</p><br />
                                                                <p>Você pode cancelar todo o pedido direto em nosso site
                                                                    ou
                                                                    pelo nosso app, mas caso
                                                                    precise cancelar apenas um ou todos os bilhetes, será
                                                                    preciso
                                                                    entrar em contato com nosso
                                                                    time de atendimento.</p><br />
                                                                <p>Para cancelar toda a compra, acesse
                                                                    {url}</p><br />
                                                                <p>O número do pedido fica no voucher da viagem, que foi
                                                                    enviado no seu e-mail, e é
                                                                    composto apenas por números.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual o prazo para cancelar minha compra no site?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>O cancelamento deve ser solicitado até 3 horas antes
                                                                    do
                                                                    horário de embarque da
                                                                    passagem.</p><br />
                                                                <p>Em alguns casos o prazo para cancelamento pode
                                                                    variar. Em
                                                                    todas as compras, os prazos
                                                                    específicos para cancelamento de cada viação ficam
                                                                    descritos no voucher da
                                                                    viagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Ao cancelar, terei meu dinheiro de volta?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Sim, após o cancelamento, iniciamos o processo de
                                                                    reembolso conforme a forma de
                                                                    pagamento utilizada na compra.</p><br />
                                                                <p>Quando a passagem é cancelada, é encaminhado via
                                                                    e-mail a
                                                                    confirmação do cancelamento
                                                                    com as informações sobre a devolução do valor
                                                                    pago.</p>
                                                                <br />
                                                                <p>Se ainda tiver dúvidas ou precisar de um comprovante,
                                                                    basta chamar nosso time de
                                                                    atendimento.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual é o prazo para receber meu dinheiro de volta?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Após o cancelamento, o reembolso pode ocorrer de 2
                                                                    formas:</p><br />
                                                                <p>- Para compras com cartão de Crédito ou Débito, o
                                                                    prazo
                                                                    de devolução é de até 5 dias
                                                                    úteis;</p>
                                                                <p>- Para compras por Pix ou Transferência Bancária, o
                                                                    prazo
                                                                    de devolução é de até 1 dia
                                                                    útil.</p><br />
                                                                <p><b>ATENÇÃO!</b> Se sua compra foi feita com cartão de crédito, o
                                                                    reembolso pode depender da data de
                                                                    fechamento da sua fatura.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="menu-accordion-heading">
                                                        <a className="menu-accordion-toggle" href="#item-compra-5">
                                                            Benefícios e gratuidades
                                                            <i className="fa fa-caret-down p-float-right"></i>
                                                        </a>
                                                    </div>
                                                    <div id="item-compra-5" className="menu-accordion-body"
                                                        style={{ overFlow: 'hidden', display: 'none' }}>
                                                        <div className="menu-accordion-inner">
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar passagens com desconto?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>As viações só liberam informações e vendas de
                                                                    passagens
                                                                    com benefício
                                                                    presencialmente, na rodoviária. É preciso apresentar
                                                                    os
                                                                    documentos de identidade e
                                                                    comprovação do desconto ou gratuidade.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como reservar minha passagem com gratuidade?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>No site só vendemos passagens com valor integral,
                                                                    não é
                                                                    possível reservar
                                                                    passagens.</p><br />
                                                                <p>Para garantir passagens com gratuidade ou desconto, é
                                                                    necessário ir ao balcão de
                                                                    atendimento da empresa responsável pela viagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar passagens online com desconto de estudantes
                                                                ou
                                                                ID Jovem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>O benefício para <b>estudante</b> é válido para
                                                                    deslocamento entre a escola e
                                                                    residência, nos dias letivos.</p><br />
                                                                <p>Para conseguir o benefício é preciso do comprovante
                                                                    de
                                                                    residência e do atestado de
                                                                    matrícula informando o curso frequentado, dias
                                                                    letivos,
                                                                    horários de aula e duração
                                                                    do curso e então é só solicitar direto no balcão da
                                                                    empresa que for utilizar.</p>
                                                                <br />
                                                                <p>Para o <b>ID Jovem</b>, o passageiro deve ter entre
                                                                    15 a 29 anos, renda de até dois
                                                                    salários-mínimos e ser inscrito no CadÚnico
                                                                    (Cadastro Único para Programas Sociais
                                                                    do Governo Federal).</p><br />
                                                                <p>É só se cadastrar no site ou aplicativo para
                                                                    smartphones do ID Jovem.</p><br />
                                                                <p>São 2 vagas gratuitas, mas depois de esgotadas também
                                                                    são reservadas mais 2 vagas com
                                                                    50% de desconto no valor das passagens, as passagens
                                                                    são apenas para ônibus
                                                                    convencionais e em viagens entre estados
                                                                    diferentes.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar passagens com desconto para idosos?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Para conseguir o benefício para idoso é preciso
                                                                    apresentar direto no balcão da viação
                                                                    que irá viajar o documento de identidade oficial e o
                                                                    comprovante de renda,
                                                                    preferencialmente a carteira do idoso, fornecido
                                                                    pelas
                                                                    Prefeituras Municipais.</p>
                                                                <br />
                                                                <p>Na falta da Carteira de Idoso, se faz necessário o
                                                                    documento de identidade e
                                                                    comprovante de renda inferior a 2
                                                                    salários-mínimos.</p><br />
                                                                <p>O quanto antes conseguir reservar melhor. As viações
                                                                    reservam 2 poltronas para viajar
                                                                    com gratuidade e mais 2 poltronas com 50% do
                                                                    valor.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como comprar passagens com desconto para pessoas com
                                                                deficiência?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Para esse benefício, o passageiro deve ser inscrito
                                                                    no
                                                                    Programa Passe Livre e ir ao
                                                                    balcão de atendimento da empresa de ônibus na
                                                                    rodoviária
                                                                    para solicitar o
                                                                    benefício.</p><br />
                                                                <p>Tem direito ao passe livre pessoas com deficiência
                                                                    seja
                                                                    física, mental, auditiva,
                                                                    visual ou múltipla, que apresentem carência e
                                                                    comprovem
                                                                    a baixa renda.</p><br />
                                                                <p>Os documentos exigidos são a credencial do Passe
                                                                    Livre e
                                                                    um documento de identidade
                                                                    com foto. Basta ir direto ao balcão de atendimento
                                                                    da
                                                                    empresa de ônibus que irá
                                                                    viajar e solicitar a passagem.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como conseguir um cupom de desconto?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Para garantir cupons e saber dos nossos descontos
                                                                    exclusivos, é só se cadastrar em
                                                                    nosso site <a href="https://vexado.com.br/" rel="noreferrer"></a> e ficar atento aos e-mails que enviamos,
                                                                    além de nos seguir nas redes sociais.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="menu-accordion-heading">
                                                        <a className="menu-accordion-toggle" href="#item-compra-6">
                                                            Outros
                                                            <i className="fa fa-caret-down p-float-right"></i>
                                                        </a>
                                                    </div>
                                                    <div id="item-compra-6" className="menu-accordion-body"
                                                        style={{ overFlow: 'hidden', display: 'none' }}>
                                                        <div className="menu-accordion-inner">
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                O que é o Vexado?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Somos uma plataforma de venda online de passagens
                                                                    rodoviárias que você encontra
                                                                    facilmente sempre que precisar. Comprar com a gente
                                                                    é
                                                                    buscar, pagar e viajar.
                                                                    Simples assim!</p><br />
                                                                <p>Sua passagem chega em seu celular para você embarcar
                                                                    de
                                                                    um jeito que faz muito mais
                                                                    sentido nos tempos atuais: sem papéis perdidos no
                                                                    fundo
                                                                    da bolsa, sem demora em
                                                                    filas da rodoviária e sem dúvidas se a viagem vai
                                                                    dar
                                                                    certo ou não.</p><br />
                                                                <p>Trabalhamos apenas com viações cadastradas e
                                                                    legalizadas
                                                                    e temos muitas outras
                                                                    parcerias a caminho para entregar novas rotas para
                                                                    você.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Qual o horário de atendimento?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Temos vários canais de atendimento para te ajudar
                                                                    sempre
                                                                    que precisar, porém cada um
                                                                    deles funciona em seus respectivos horários:</p>
                                                                <br />
                                                                <p><strong>Telefone:</strong><br />Segunda a sexta, exceto feriados, das 09:00 às 18:00.
                                                                </p><br />
                                                                <p>Nosso time vai te ajudar pelo telefone (11)
                                                                    2626-9632. Caso já tenha feito a sua
                                                                    compra, vale ter em mãos o seu pedido e o documento
                                                                    para agilizar o atendimento.</p>
                                                                <br />
                                                                <p><strong>WhatsApp</strong><br />Segunda à sexta das 09:00 às 18:00.
                                                                    Sábado das 09:00 às 12:00, exceto feriados.
                                                                </p><br />
                                                                <p>Atenção! Em períodos de alta demanda podem
                                                                    ocorrer filas de atendimento, conforme
                                                                    disponibilidade de atendentes.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                O que é uma conexão?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Se você procurar por uma rota que não tem ônibus
                                                                    direto,
                                                                    nosso site ou aplicativo
                                                                    encontra uma forma de você chegar no seu destino,
                                                                    sugerindo uma conexão.</p><br />
                                                                <p>Em uma viagem com conexão, você vai parar em uma
                                                                    outra
                                                                    cidade no caminho, trocar de
                                                                    ônibus e depois continuar a viagem para chegar no
                                                                    seu
                                                                    destino.
                                                                </p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                O que é uma escala?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>A escala é um pouco diferente de conexão. Também tem
                                                                    um
                                                                    tempo de espera, mas o
                                                                    passageiro não precisa desembarcar porque não tem
                                                                    troca
                                                                    de ônibus.</p><br />
                                                                <p>A escala é uma parada um pouco mais longa, feita para
                                                                    desembarque e embarque de novos
                                                                    passageiros. Se for uma viagem muito extensa, pode
                                                                    ser
                                                                    que mude o motorista, para a
                                                                    segurança dos passageiros.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Tive problemas em minha viagem. O que fazer?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Aqui no site fazemos apenas a venda das passagens de
                                                                    ônibus pela internet para
                                                                    empresas de viagem. Por isso, não temos acesso aos
                                                                    ônibus e suas condições.</p><br />
                                                                <p>Nossa sugestão é que converse com a empresa que
                                                                    viajou.
                                                                    Seu feedback é muito
                                                                    importante para melhorar o serviço prestado por
                                                                    eles!</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como eu encontro um objeto perdido na viagem?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Não temos acesso ao setor de “Achados e
                                                                    Perdidos” das rodoviárias e
                                                                    empresas de ônibus.</p><br />
                                                                <p>Por isso, nossa sugestão é que entre em contato com a
                                                                    empresa responsável por sua
                                                                    viagem para conferir se seu objeto foi
                                                                    encontrado.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Não encontrei meu destino. E agora?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>É possível que o trecho que você pesquisou não tenha
                                                                    passagens disponíveis para venda
                                                                    na internet ou que a empresa de ônibus responsável
                                                                    pela
                                                                    viagem ainda não seja nossa
                                                                    parceira de vendas.</p><br />
                                                                <p>Nesse caso, a nossa sugestão é procurar em cidades
                                                                    maiores perto da sua região ou ir
                                                                    direto na rodoviária da cidade da cidade de
                                                                    saída.</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Como faço para alterar meus dados cadastrais?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Para alterar os seus dados cadastrais, basta fazer
                                                                    login
                                                                    no site ou no aplicativo e
                                                                    ir na aba “Minha conta” e então clicar em “Meu
                                                                    perfil”,
                                                                    simples assim!</p>
                                                            </div>
                                                            <h3 className="menu-accordion-inner-trigger js-accordion-inner-trigger">
                                                                Por que a rodoviária tem horários que não tem para
                                                                compra
                                                                online?
                                                                <i className="fa fa-caret-down p-float-right"></i>
                                                            </h3>
                                                            <div className="menu-accordion-inner-collapse">
                                                                <p>Pelo site fazemos a venda das passagens das empresas
                                                                    que
                                                                    são nossas parceiras.
                                                                    Apesar disso, nem sempre todos os horários ficam
                                                                    disponíveis para comprar
                                                                    online.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-contato-block-phone">
                                            <div className="main-container">
                                                <div className="pcb-phone-container">
                                                    <p>Ainda não encontrou a resposta que precisava? Entre em contato
                                                        com nosso atendimento:</p>
                                                </div>
                                                <div className="pcb-phone-contacts">
                                                    <div className="pcbp-content">
                                                        <span className="pcbp-name">
                                                            <i className="material-icons">phone</i>
                                                            <p>Telefone</p>
                                                        </span>
                                                        <div className="pcbp-text">
                                                            <p className="pcbpt-info">Segunda a sexta, exceto feriados,
                                                                das <strong>09:00 às 18:00</strong>.
                                                            </p>
                                                        </div>
                                                        <a className="bs-btn bs-btn-text-only js-amplitude-open-contacts"
                                                            href="tel:556141015235"
                                                            id="btn-open-phone">
                                                                61 - 4101-5235
                                                        </a>
                                                    </div>
                                                    <div className="pcbp-divider" />
                                                    <div className="pcbp-content">
                                                        <span className="pcbp-name">
                                                            <WhatsApp
                                                                className='mr-2' />
                                                            <p>Whatsapp</p>
                                                        </span>
                                                        <div className="pcbp-text">
                                                            <p className="pcbpt-info">
                                                                Segunda à sexta das <strong>09:00 às 18:00</strong>.
                                                                Sábado das <strong>09:00 às 12:00</strong>, exceto
                                                                feriados.
                                                            </p>
                                                        </div>
                                                        <a className="bs-btn bs-btn-text-only js-amplitude-open-contacts" target='_blank' rel="noreferrer"
                                                            href="https://api.whatsapp.com/send?phone=556141015235&text=Olá. Preciso de atendimento." id="btn-open-phone">
                                                            61 - 4101-5235
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Atendimento;