import {BUSCAR_DADOS_DASHBOARD, LIMPAR_DADOS_DASHBOARD } from '../../actions/types'

const intialState = {
    totalReservasDia: 0,
    totalReservasMes: 0,
    totalDisponivel: 0,
    totalAEmbarcadar: 0,
    totalComissoesAhFaturar: 0,
    totalCanceladasMes: 0,
    totalComissoesPerdidasMes: 0,
    totalComissoesRecebidasMes: 0,
    totalReservasPorAnoAgrupdasPorMes: {
        APROVADO: [],
        CANCELADO: [],
        EMBARCADA: [],
        FATURADA: []
    }
};

export default function reducerDashboardReservas(state = intialState, action) {
    switch (action.type) {
        case BUSCAR_DADOS_DASHBOARD: {            
            return action.payload;
        }
        case LIMPAR_DADOS_DASHBOARD: {
            return intialState;
        }
        default: {
            return state
        }
    }
}
