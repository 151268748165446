export const raiz = { path: '/', desc: 'Início' };
export const login = { path: '/login', desc: '' };
export const logout = { path: '/logout', desc: '' };
export const trocaSenha = { path: '/troca-senha', desc: 'Troca de senha' };
export const acervoDivulgacao = { path: '/painel-acervo-divulgacao', desc: 'Acervo de divulgação' };
export const acervoDivulgacaoSubPasta = { path: '/painel-acervo-divulgacao-pastas', desc: 'Sub-pasta acervo de divulgação / ' };
export const acervoDivulgacaoGaleria = { path: '/painel-galeria-acervo-divulgacao', desc: 'Galeria' };
export const pedidoMinhasVendas = { path: '/painel-minhas-vendas', desc: 'Minhas vendas' };
export const painelItinerarios = { path: '/painel-itinerarios', desc: 'Itinerarios' };
export const httpError = { path: '/httperror', desc: '' };
export const forbidden = { path: '/httperror', desc: '' };
export const atendimento = { path: '/atendimento', desc: 'Atendimento' };
export const politicaCookies = { path: '/politica-de-cookies', desc: 'Politica de Cookies' };
export const politicaPrivacidade = { path: '/politica-de-privacidade', desc: 'Politica de Privacidade' };
export const quemSomos = { path: '/quem-somos', desc: 'Quem somos' };
export const termosUso = { path: '/termos-de-uso', desc: 'Termos de Uso' };