import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { isAmbienteDev } from '../../util/applicationContext';

export class MenuLateral extends Component {

  componentDidMount() {
    $('.sidebar.top-55').on('mouseover', function () {
      $(this).closest('body').removeClass('sidebar-minimized');
    });
    $('.sidebar.top-55').on('mouseout', function () {
      $(this).closest('body').addClass('sidebar-minimized');
    });

    $('li').on('click', function () {
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
    })
  }


  exibirIconesMenu() {
    return (<React.Fragment>
      <div className="sidebar" id="sidebar">

        <li className="nav-item" >
          <Link className="nav-link" to="/">
            <i className='bx bx-home'></i>
            <span className="custom-font-aside">Início</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/painel-minhas-vendas">
            <i className='bx bx-line-chart' ></i>
            <span className="custom-font-aside">Minhas vendas</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/painel-itinerarios">
            <i className='bx bx-map-alt'></i>
            <span className="custom-font-aside">Itinerários</span>
          </Link>
        </li>

        {isAmbienteDev() ? <li className="nav-item">
          <Link className="nav-link" to="/painel-acervo-divulgacao">
            <i className='bx bxs-videos' ></i>
            <span className="custom-font-aside">Aprenda agora</span>
          </Link>
        </li> : '' }

        {isAmbienteDev() ? <li className="nav-item">
          <Link className="nav-link" to="/painel-acervo-divulgacao">
            <i className='bx bx-pie-chart-alt-2' ></i>
            <span className="custom-font-aside">Estratégias e Metas<br /> de ataque</span>
          </Link>
        </li> : '' }

        <li className="nav-item">
          <Link className="nav-link" to="/painel-acervo-divulgacao">
            <i className='bx bx-images' ></i>
            <span className="custom-font-aside">Galeria de divulgação</span>
          </Link>
        </li>

        {isAmbienteDev() ? <li className="nav-item">
          <Link className="nav-link" to="/painel-acervo-divulgacao">
            <i className='bx bx-money' ></i>
            <span className="custom-font-aside">Extrato</span>
          </Link>
        </li> : '' }
        
        <li className="slide"></li>

      </div >
    </React.Fragment >)

  }

  render() {
    return (
      <div id="menu-lateral" className="menu-lateral">
        <nav className="sidebar top-55" style={{}}>
          <ul className="nav">
            {this.exibirIconesMenu()}
          </ul>
        </nav>
      </div>)
  }
}

export function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps)(MenuLateral)
