import React from 'react';

const PaginacaoGaleria = ({paginas, paginaAtual, setPaginaAtual}) => {
    return (
        <div className='pagination'>
                    {Array.from(Array(paginas), (item, index) => {
                        return <span key={'btn-' + index} className={'page-item ' + (index === paginaAtual ? 'active' : '')}><button
                            className={'page-link'}
                            value={index}
                            onClick={(e) => setPaginaAtual(Number(e.target.value))}>
                            {index + 1}
                        </button></span>
                    })}
                </div>
    )
}

export default PaginacaoGaleria;