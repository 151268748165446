import React from 'react'
import FiltrosAdicionados from './FiltrosAdicionados'

const PainelFiltragem = (props) => (
  <React.Fragment>
    <div className="justify-content-md-center">
      <div className="mb-4">
        <div className="input-group">
          <props.Filtro/>
        </div>
      </div>
    </div>
    <div className="row">
    </div>
    <div className="row justify-content-md-center">
      <FiltrosAdicionados Filtro={props.FiltrosAdicionados}/>
    </div>
  </React.Fragment>
)

export default PainelFiltragem
