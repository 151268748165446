import axios from 'axios'
import { isUserAuthenticated, logoutUser, validarToken } from '../actions/usuarioActions'
import {getAuthentication} from '../seguranca/securityContext'
import { URL } from '../util/ambienteActions';
import {AUTHENTICATION_FAILED} from '../reducers/usuariosReducer';

export default function jwtInterceptor (store) {
  if (!isUserAuthenticated(getAuthentication())) {
    logoutUser();
    store.dispatch({
      type: AUTHENTICATION_FAILED,
      payload: { erro: 'Sessão Expirou' }
    })
  }
  const temToken = () => getAuthentication() && getAuthentication().tokenJwt;

  const ehAuth = config => config.url && config.url === `${URL}/auth/cliente/login`;

  const ehPublico = config => config.url && config.url.indexOf(`${URL}/rest/publico`) !== -1;

  const onFulfilled = (config) => {
    const configuration = config;
    if (temToken() && validarToken(getAuthentication().tokenJwt) && !ehAuth(configuration) && !ehPublico(configuration)) {
      configuration.headers.Authorization = `Bearer ${getAuthentication().tokenJwt}`
    }
    if (!isUserAuthenticated(getAuthentication())) {
      store.dispatch({
        type: AUTHENTICATION_FAILED,
        payload: { erro: 'Sessão Expirou' }
      })
    }
    return configuration
  };

  const onRejected = err => Promise.reject(err);

  axios.interceptors.request.use(onFulfilled, onRejected)
}
