import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil'
import { URL } from '../../util/ambienteActions';
import { BUSCAR_ARQUIVOS_PASTA_ACERVOS } from '../types';

const buscarArquivosPastasAcervoDivulgacao = (pasta) => {
    let url = `${URL}/arquivo/listar?pasta=${pasta}`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: BUSCAR_ARQUIVOS_PASTA_ACERVOS,
            payload: response
        })
    );
}


export default buscarArquivosPastasAcervoDivulgacao