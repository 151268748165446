import {FECHAR_LOADING, MOSTRAR_LOADING} from '../reducers/loadingReducer';


export function mostrarLaoding() {
    return {
        type: MOSTRAR_LOADING,
        payload: null,
    }
}


export function fecharLoading() {
    return {
        type: FECHAR_LOADING,
        payload: null,
    }
}
