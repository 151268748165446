import React, { Component } from 'react';
import moment from 'moment'

export class DetalhesPedido extends Component {

    exibirReservas() {
        const reservas = this.props.pedido.reservas;        
        return reservas && reservas.length && reservas.map( (reserva, indice) => <tbody key={indice}>
            <tr style={{ backgroundColor: '#ffffff66' }}>
                <td colSpan={4} className='text-center'><strong>Número do bilhete:</strong> {reserva.id}</td>
            </tr>
            <tr>
                <td colSpan={2}><strong>Rota:</strong> {reserva.descricaoRota}</td>
                <td colSpan={1}><strong>Trecho:</strong> {`${reserva.descricaoCidadeOrigem} à ${reserva.descricaoCidadeDestino}`}</td>
            </tr>
            <tr>                
                <td><strong>Situação:</strong> {reserva.situacao}</td>
                <td><strong>Partida:</strong> {reserva.dataPartida && moment(reserva.dataPartida).format('DD/MM/YYYY HH:mm')}</td> 
                <td colSpan={1}><strong>Data de embarque:</strong> {reserva.dataEmbarque ? moment(reserva.dataEmbarque).format('DD/MM/YYYY HH:mm') : 'não embarcado'}</td>
            </tr>
            <tr>
                <td colSpan={1}><strong>Valor:</strong> {reserva.valor && `${Number(this.props.pedido.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}</td>
                <td><strong>Recebida:</strong> -</td>
                <td><strong>Data de recebimento:</strong> -</td>
            </tr>
        </tbody>)
    }

    render() {
        if (!this.props.pedido.exibirDetalhe) {
            return ''
        }

        return (
            <div>
                <div className="card-body cinza-zebrado table-responsive-sm">
                    <table className="table table-sm table-bordered">
                        {this.exibirReservas()}
                    </table>
                </div>
            </div>
        )
    }
}