import React from 'react'
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import axios from 'axios';
import {renderFieldEmail, required} from '../../util/inputs'
import {SLOW_REQUEST_CONFIG} from '../../util/loadingUtil';
import {URL} from '../../util/ambienteActions';
import ReCaptchaV2 from 'react-google-recaptcha';

export class ModalEsqueciMinhaSenha extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.limparFormulario = this.limparFormulario.bind(this);
        this.state = {
            mensagem: {tipo: '', texto: ''},
            chaveCaptcha: null
        };
        this.recaptchaRef = React.createRef();
    }
    
    changeRecaptcha(value) {
        if(value) {
            this.setState({ chaveCaptcha: value })
        }
    }

    submit(values) {
        const trocaSenha = {
            email: values.email,
            codigo: values.codigo,
            senha: values.senha,
            chaveCaptcha: this.state.chaveCaptcha
        };

        const url = `${URL}/usuarios/public/gerar-troca-senha`;
        axios.post(url, trocaSenha, SLOW_REQUEST_CONFIG).then((response) => {
            if (response != null) {
                const mensagem = {
                    tipo: 'sucesso',
                    texto: 'Solicitação enviada, verifique as instruções no seu e-mail.',
                };
                this.setState({mensagem});
            }

            this.setState({ chaveCaptcha: null })
            this.recaptchaRef.current.reset();

        }).catch((error) => {
            const mensagem = {
                tipo: 'erro',
                texto: error.response.data.message ? error.response.data.message : error.response.statusText,
            };
            this.setState({mensagem});
        })
    }

    limparMensagem() {
        this.setState({mensagem: undefined})
    }

    recuperaMensagem() {
        if (this.state.mensagem !== null && this.state.mensagem !== undefined) {
            if (this.state.mensagem.tipo === 'sucesso') {
                return (
                    <div className="alert alert-success col-md-12">
                        {this.state.mensagem.texto}
                        <button type="button" className="close" aria-label="Close"
                                onClick={() => {
                                    this.limparMensagem();
                                    this.setState({usuario: null})
                                }
                                }>
                            <i className="material-icons">
                                &#xe5cd;
                            </i>
                        </button>
                    </div>
                )
            }
            if (this.state.mensagem.tipo === 'erro') {
                return (
                    <div className="alert alert-danger col-md-12">
                        {this.state.mensagem.texto}
                        <button type="button" className="close" aria-label="Close"
                                onClick={() => {
                                    this.limparMensagem();
                                }
                                }>
                            <i className="material-icons">
                                &#xe5cd;
                            </i>
                        </button>
                    </div>
                )
            }
        }
        return ''
    }

    limparFormulario() {
        this.props.reset('formularioEsqueciMinhaSenha');
        this.limparMensagem();
    }


    render() {
        const {
            handleSubmit, submitting, invalid, pristine,
        } = this.props;
        return (
            <div className="modal fade show" id='modalEsqueciMinhaSenha' role='dialog'
                 aria-labelledby='modalEsqueciMinhaSenha' aria-hidden='true' data-backdrop='static'>
                <div className={'modal-dialog modal-dialog-centered modal-md'}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>Recuperar minha senha</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={this.limparFormulario}>
                                <i className="material-icons">
                                    &#xe5cd;
                                </i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="container form-login" onSubmit={handleSubmit(this.submit)}>
                                {this.recuperaMensagem()}
                                <div className="form-group">
                                    <p>Enviaremos um e-mail com instruções para recuperar sua senha</p>
                                </div>
                                <div className="form-group">
                                    <Field name="email" type="email" component={renderFieldEmail} validate={[required]}
                                           label="E-mail"
                                           css="form-control col-md-12"/>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" disabled={invalid || submitting || pristine || !this.state.chaveCaptcha}
                                            className="btn btn-laranja">Enviar
                                    </button>
                                </div>
                                <div className='col-12 text-center'>
                                    <ReCaptchaV2
                                        ref={this.recaptchaRef}
                                        style={{display: 'inline-block'}}
                                        size={window.innerWidth > 450 ? 'normal' : 'compact'}
                                        sitekey={'6LfNrvkdAAAAAL2wVMaGIuOwJ0STFzIOavTKhNvw'}
                                        onChange={(value) => this.changeRecaptcha(value)}
                                        onExpired={() => this.setState({ chaveCaptcha: null })}
                                        onErrored={() => this.setState({ chaveCaptcha: null })} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(null, {})(reduxForm({
    form: 'formularioEsqueciMinhaSenha',
})(ModalEsqueciMinhaSenha));
