import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import removerFiltroPedido from '../../actions/pedido/removerFiltroPedido'

export class FiltrosAdicionadosPedidos extends Component {
  exibirFiltro (filtro) {
    if (filtro.isData) {
      if (filtro.valor.inicio && filtro.valor.fim) {
        return (
          <div className="col-xl-auto justify-content-md-center"
               key={`${filtro.chave}${filtro.valor.inicio}${filtro.valor.fim}`}>
              <span className="badge badge-secondary badge-filtro">
                {filtro.label} = {`${filtro.valor.inicio} - ${filtro.valor.fim}`}
                <span className="badge badge-light ml-2 clicavel"
                      onClick={() => this.props.removerFiltroPedido(filtro)}>&times;
                  </span>
               </span>
          </div>
        )
      }
    } else {      
      if (filtro.valor.valor) {        
        return (<div key={`${filtro.chave}${filtro.valor.valor}`} className="col-xl-auto justify-content-md-center">
        <span id="badge-filtro" className="badge badge-secondary badge-filtro">
              {filtro.label} = {filtro.valor.valor}
          <span id={'btn-remover-filtro'} className="badge badge-light ml-2 clicavel"
                onClick={() => this.props.removerFiltroPedido(filtro)}>&times;
          </span>
        </span>
        </div>)
      }
      return (
        <div key={`${filtro.chave}${filtro.valor}`} className="col-xl-auto justify-content-md-center">
        <span id="badge-filtro" className="badge badge-secondary badge-filtro">
              {filtro.label} = {filtro.valor}
          <span className="badge badge-light ml-2 clicavel"
                onClick={() => this.props.removerFiltroPedido(filtro)}>&times;
          </span>
        </span>
        </div>
      )
    }
  }

  render () {
    return this.props.filtrosAdicionadosPedido.map(filtro => this.exibirFiltro(filtro))
  }
}

const mapStateToProps = ({ filtrosAdicionadosPedido }) => ({ filtrosAdicionadosPedido });

const mapDispatchToProps = dispatch => bindActionCreators({
  removerFiltroPedido
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FiltrosAdicionadosPedidos)
