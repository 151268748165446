import { ALTERAR_DETALHAMENTO_ITINERARIO } from '../types'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil'
import { URL } from '../../util/ambienteActions';

export default function alterarExibicaoDetalhamentoItinerario (itinerario) {
  const url = `${URL}/trecho/empresa/${itinerario.empresaId}/rota/${itinerario.rotaId}/trechos`
  return dispatch => {
    if(!itinerario.trechoFoiPesquisado) {
      axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => {
          return dispatch({
            type: ALTERAR_DETALHAMENTO_ITINERARIO,
            payload: {
              trechos: response.data,
              itinerario: itinerario
            }
          })
        }
      )
    } else {
      return dispatch({
        type: ALTERAR_DETALHAMENTO_ITINERARIO,
        payload: {
          itinerario: itinerario,
          trechos: null
        }
      })
    }
  }
}