import axios from 'axios';
import { URL } from '../util/ambienteActions';
import { BUSCAR_OPEN_GRAPH } from './types';

export default function buscarOpenGraphSites() {
  const url = `${URL}/open-graph/public/getOpenGraphData`;
  return dispatch => axios.get(url).then(
    response => dispatch({
      type: BUSCAR_OPEN_GRAPH,
      payload: response
    })
  );
}