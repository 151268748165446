import React from 'react'
const Titulo =  ({
  texto, cssText
}) => (
    <div className="titulo-principal">
        <h1 id="titulo-page" className={cssText}>
          {texto}
        </h1>
    </div>
)

export default Titulo
