import React from 'react'

export const ConteudoPaginacao = ({ OpcoesPaginacao, BotoesPaginacao }) => (
  <React.Fragment>
    <OpcoesPaginacao/>
    <BotoesPaginacao/>
  </React.Fragment>
);

const Paginacao = props => (
  <React.Fragment>
    <ConteudoPaginacao {... props} />
  </React.Fragment>
);

export default Paginacao
