import React, { Component } from 'react';

export class TextoInicialLogin extends Component {

    render() {
        return (
            <div className='textoInicial'>
                <div>
                    <span className='textInicio'>
                        O ACESSO AO SITE GESTÃO DE AGÊNCIA É A CHAVE PARA UMA PLATAFORMA DIGITAL ALTAMENTE ORGANIZADA E EFICIENTE. ATRAVÉS DESSE ACESSO, VOCÊ TERÁ FERRAMENTAS PODEROSAS, COMO ANÁLISES EM TEMPO REAL, ACOMPANHAMENTO DE ROTAS, RELATÓRIOS PERSONALIZADOS E ESTRATÉGIAS DE VENDAS EFICIENTES.
                    </span>
                </div>
                <div>
                    <span className='textInicio'>
                        ISSO PERMITE QUE EQUIPES DE VENDAS OTIMIZEM OPERAÇÕES, IMPULSIONEM O CRESCIMENTO E OFEREÇAM SERVIÇO EXCEPCIONAL. DESBLOQUEIE O POTENCIAL MÁXIMO COM NOSSA PLATAFORMA LÍDER NO SETOR E ABRAÇE O FUTURO DA GESTÃO DE AGÊNCIAS.
                    </span>
                </div>
            </div>
        )
    }
}

export default TextoInicialLogin;