import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const config = {
    type: 'line'
}

export const options = {
    layout: true,
    responsive: true,
    radius: 3,
    hoverRadius: 15,
    labels: {
        fontStyle: 'bold',
        fontSize: 20,
    },

    plugins: {
        title: {
            display: true,
            fontSize: 50,
            text: 'BUS EXPRESSO',
            padding: {
                top: 20,
                bottom: 20,
            },
            legend: {
                position: 'top',
            },
        },
    },
};


export class GraficoReservas extends Component {
    constructor(props) {
        super(props);
        this.state = {                        
            labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],            
        }
    }

    preencherValoresGrafico() {
        const labelsAprovadas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.props.totalReservasPorAnoAgrupdasPorMes.APROVADO.map((reserva) => {
            labelsAprovadas[reserva.ano - 1] = reserva.quantidade
        });

        const labelsCanceladas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.props.totalReservasPorAnoAgrupdasPorMes.CANCELADO.map((reserva) => {
            labelsCanceladas[reserva.ano - 1] = reserva.quantidade
        });
        
        const labelsEmbarcadas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.props.totalReservasPorAnoAgrupdasPorMes.EMBARCADA.map((reserva) => {
            labelsEmbarcadas[reserva.ano - 1] = reserva.quantidade
        });
        
        const labelsFaturadas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.props.totalReservasPorAnoAgrupdasPorMes.FATURADA.map((reserva) => {
            labelsFaturadas[reserva.ano - 1] = reserva.quantidade
        });

        const { labels } = this.state

        return {
            labels,
            datasets: [
                {
                    label: 'Aprovadas',
                    data: labels.map((l, i) =>
                        labelsAprovadas[i]),
                    borderWidth: 4,
                    borderColor: 'rgba(255,215,0)',
                    backgroundColor: 'transparent',
                },
                {
                    label: 'Canceladas',
                    data: labels.map((l, i) => labelsCanceladas[i]),
                    borderWidth: 4,
                    borderColor: 'rgba(017,126,253,0.85)',
                    backgroundColor: 'transparent'
                },
                {
                    label: 'Embarcadas',
                    data: labels.map((l, i) => labelsEmbarcadas[i]),
                    borderWidth: 4,
                    borderColor: 'rgba(77,166,23,0.85)',
                    backgroundColor: 'transparent'
                },
                {
                    label: 'Faturadas',
                    data: labels.map((l, i) => labelsFaturadas[i]),
                    borderWidth: 4,
                    borderColor: 'rgba(255,127,80)',
                    backgroundColor: 'transparent'
                },
            ],
        }

    }

    render() {
        const dados = this.preencherValoresGrafico();
        return (
            <div style={{}}>
                { this.props.totalReservasPorAnoAgrupdasPorMes ?
                    <Line config={config} options={options} data={dados} />
                    : ''}
            </div>
        )
    }
}

export const mapStateToProps = state => (
    {
        dadosAutenticacao: state.usuario.dadosAutenticacao,
        totalReservasPorAnoAgrupdasPorMes: state.dashboardReservas.totalReservasPorAnoAgrupdasPorMes
    }
)

export default connect(mapStateToProps,{})(GraficoReservas)
