import React, { Component } from 'react'
import moment from 'moment'
import { DetalhesItinerario } from './DetalhesItinerario'
// import { capitalizeFirstLetter } from '../../util/stringUtil'

export class Itinerario extends Component {

    exibirIcone() {
        if (this.props.itinerario.exibirDetalhe) {
            return <i className="material-icons" style={{ cursor: 'pointer' }}>
                &#xE5C7;</i>
        }
        return <i className="material-icons" style={{ cursor: 'pointer' }}>&#xE5C5;</i>
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body" style={{padding:'0.7rem'}}>
                        <div className='row'>
                            <div className="col" onClick={() => this.props.alterarExibicao(this.props.itinerario)} style={{ cursor: 'pointer' }}>
                                {this.exibirIcone()}
                            </div>
                            <div className="col">
                                <span className="titulo"><strong>Nº:</strong>&nbsp;</span>{this.props.itinerario.id}
                            </div>
                            <div className="col">
                                <span className="titulo"><strong>Empresa:</strong>&nbsp;</span>{this.props.itinerario.nomeFantasia}
                            </div>
                            <div className="col">
                                <span className="titulo"><strong>Data/Hora saída:</strong>&nbsp;</span>{moment(this.props.itinerario.dataPartida).format('DD/MM/YYYY')} {this.props.itinerario.horaPartida}
                            </div>
                            <div className="col">
                                <span className="titulo"><strong>Rota:</strong>Rota:&nbsp;</span>{this.props.itinerario.rotaDescricao}
                            </div>
                            <div className="col">
                                <span className="titulo"><strong>Delimitador:</strong>&nbsp;</span>{this.props.itinerario.rotaDelimitacao}
                            </div>
                            <div className="col">
                                <span className="titulo"><strong>Veiculo:</strong>&nbsp;</span>{this.props.itinerario.veiculoDescricao}
                            </div>
                            <div className="col">
                                <span className="titulo"><strong>Assentos vendidos:</strong>&nbsp;</span>{this.props.itinerario.assentosVendidos}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DetalhesItinerario itinerario={this.props.itinerario} />
                </div>
            </div>
        )
    }
}