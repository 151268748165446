import React from 'react'
import { connect } from 'react-redux'
import loadingImage from '../../estilo/img/loading.gif'

export class Loading extends React.Component {
  render () {
    if (this.props.loading) {
      return (
        <div className="loading-content text-center">
          <img alt="Loading..." className="loading-gif" src={loadingImage}/>
        </div>
      )
    }
    return null
  }
}

function mapStateToProps ({ loading }) {
  return loading
}

export default connect(mapStateToProps)(Loading)
