import {
  ADICIONAR_FILTRO_PEDIDO,
  LIMPAR_FILTRO_PEDIDO,
  REMOVER_FILTRO_PEDIDO
} from '../../actions/types'
import { filtroExiste } from '../../util/filtros'

const initialState = undefined

export const filtrosAdicionadosPedido = (state = [], action = initialState) => {
  switch (action.type) {
    case ADICIONAR_FILTRO_PEDIDO: {      
      if (!filtroExiste(state, action.payload)) {
        return state.concat(action.payload)
      }
      return state
    }

    case REMOVER_FILTRO_PEDIDO: {
      const copia = [...state]
      return copia.filter(filtro => filtro.chave !== action.payload.chave || filtro.valor !== action.payload.valor)
    }

    case LIMPAR_FILTRO_PEDIDO: {
      return []
    }

    default:
      return state
  }
}