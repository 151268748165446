import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import removerFiltroItinerario from '../../actions/itinerario/removerFiltroItinerario'

export class FiltrosAdicionadosItinerario extends Component {
    exibirFiltro (filtro) {
      if (filtro.isData) {
        if (filtro.valor.inicio && filtro.valor.fim) {
          return (
            <div className="col-xl-auto justify-content-md-center"
                 key={`${filtro.chave}${filtro.valor.inicio}${filtro.valor.fim}`}>
                <span className="badge badge-secondary badge-filtro">
                  {filtro.label} = {`${filtro.valor.inicio} - ${filtro.valor.fim}`}
                  <span className="badge badge-light ml-2 clicavel"
                        onClick={() => this.props.removerFiltroItinerario(filtro)}>&times;
                    </span>
                 </span>
            </div>
          )
        }
      } else {      
        if (filtro.valor.valor) {        
          return (<div key={`${filtro.chave}${filtro.valor.valor}`} className="col-xl-auto justify-content-md-center">
          <span id="badge-filtro" className="badge badge-secondary badge-filtro">
                {filtro.label} = {filtro.valor.valor}
            <span id={'btn-remover-filtro'} className="badge badge-light ml-2 clicavel"
                  onClick={() => this.props.removerFiltroItinerario(filtro)}>&times;
            </span>
          </span>
          </div>)
        }
        return (
          <div key={`${filtro.chave}${filtro.valor}`} className="col-xl-auto justify-content-md-center">
          <span id="badge-filtro" className="badge badge-secondary badge-filtro">
                {filtro.label} = {filtro.valor}
            <span className="badge badge-light ml-2 clicavel"
                  onClick={() => this.props.removerFiltroItinerario(filtro)}>&times;
            </span>
          </span>
          </div>
        )
      }
    }
  
    render () {
      return this.props.filtrosAdicionadosItinerario.map(filtro => this.exibirFiltro(filtro))
    }
  }
  
  const mapStateToProps = ({ filtrosAdicionadosItinerario }) => ({ filtrosAdicionadosItinerario });
  
  const mapDispatchToProps = dispatch => bindActionCreators({
    removerFiltroItinerario
  }, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(FiltrosAdicionadosItinerario)
