import React, { Component } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import LogoGestaoAgencia from '../../estilo/img/logo_gestão_agência.png';

export class BarraNavegacaoLogin extends Component {

    render() {
        return (
            <Navbar id='containerLogo' expand="lg" bg="light" variant="light">
                <div className='container'>
                    <Navbar.Brand>
                        <a className="nav-link" href='/' id="logo-topo">
                            <img id='iconBilhete' src={LogoGestaoAgencia} alt="Ícone Bilhete"></img>
                        </a>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-nav" />
                    <Navbar.Collapse id="navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <NavDropdown title="Institucional" id="basic-nav-dropdown-institucional">
                                <NavDropdown.Item href='/quem-somos/#quem-somos'>Quem Somos</NavDropdown.Item>
                                <NavDropdown.Item href='/atendimento/#secao-atendimento'>Atendimento</NavDropdown.Item>
                                <NavDropdown.Item href='/politica-de-cookies/#secao-politica-cookies'>Política de Cookies</NavDropdown.Item>
                                <NavDropdown.Item href='/politica-de-privacidade/#secao-politica-privacidade'>Política de Privacidade</NavDropdown.Item>
                                <NavDropdown.Item href='/termos-de-uso/#secao-termos'>Termos de Uso</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link target="_blank" rel="noreferrer" href="https://queroumaagencia.com.br/">Quero uma Agência</Nav.Link>

                            <Nav.Link target="_blank" rel="noreferrer" href="https://vexado.com.br/sistema-vexado#secao-sistema-vexado">Sistema Vexado</Nav.Link>

                            <NavDropdown title="Blogs" id="basic-nav-dropdown-blogs">
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.vexadoblog.com/">Vexado Blog</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.brasilpassagensblog.com/">Brasil Passagens Blog</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://queroumaagencia.com.br/">Quero uma Agência</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.olhardatecnologia.com/">Olhar da Tecnologia</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.bichosnews.net/">Bichos News</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="Parcerias" id="basic-nav-dropdown-parcerias">
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://vexado.com.br/">Vexado</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://transbrasilpassagens.com/">Transbrasil Passagens</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://brasilpassagens.com.br/">Brasil passagens</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://viacaoamarelinho.com.br/">Amarelinho</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://passagensadamantina.com.br/">Passagens Adamantina</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://bilheterodoviario.com.br/">Bilhete Rodoviário</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://basilioviagens.com.br/">Basílio Passagens</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://expressodiamante.com.br/">Expresso Diamante</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" rel="noreferrer" href="https://queroumaagencia.com.br/">Quero Uma Agencia</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        )
    }
}

export default BarraNavegacaoLogin;
