import _ from 'lodash';

import { showErrorMsg } from './messagesUtil';

export const RESPONSE_ERROR_HANDLER_POSTPONE_HEADER = 'X-Response-Error-Handler-Postpone';

export const RESPONSE_ERROR_HANDLER_POSTPONE_CONFIG = {
  headers: {
    [RESPONSE_ERROR_HANDLER_POSTPONE_HEADER]: true
  }
};

export const isArrayBuffer = value => value && value instanceof ArrayBuffer;

export const arrayBufferToJson = arrayBuffer => JSON.parse(new TextDecoder('utf8').decode(arrayBuffer));

export const extractError = ({ response }) => ({
  response,
  code: response.status,
  json: isArrayBuffer(response.data) ? arrayBufferToJson(response.data) : response.data
});

export const errorHasDomainViolations = error => _.has(error, 'violacoes') && _.isArray(error.violacoes) && !_.isEmpty(error.violacoes);

export const extractDomainViolations = error => (errorHasDomainViolations(error) ? error.violacoes : []);

export const WARNING_DOMAIN_VIOLATION = 'ALERTA';

export const extractWarningDomainViolations = error => _.filter(extractDomainViolations(error), violacao => violacao.tipo === WARNING_DOMAIN_VIOLATION);

export const ERROR_DOMAIN_VIOLATION = 'ERRO';

export const extractErrorDomainViolations = error => _.filter(extractDomainViolations(error), violacao => violacao.tipo === ERROR_DOMAIN_VIOLATION);

export const handleResponseError = (error) => {
  if (errorHasDomainViolations(error)) {
    const msgText = _.join(_.map(error.violacoes, violacao => violacao.mensagem), ', ');
    if (_.size(msgText)) showErrorMsg(msgText);
  }
};
