import React, { Component } from 'react'
import { connect } from 'react-redux'
import BarraNavegacao from './BarraNavegacao'
import MenuLateral from './MenuLateral'
import $ from 'jquery';


export class Template extends Component {

  componentDidMount() {
    if (this.props.dadosAutenticacao.isAuthenticated) {
      $('body').removeClass('app-body2')
    }
  }

  render() {
    return (

      <div style={{ display: this.props.dadosAutenticacao.isAuthenticated ? 'inline' : 'none' }}>
        <BarraNavegacao/>
        <MenuLateral/>
      </div>

    )
  }
}

const mapStateToProps = state => {
  return {
    dadosAutenticacao: state.usuario.dadosAutenticacao,
  }
};


export default connect(mapStateToProps)(Template)
