import {
    ADICIONAR_FILTRO_ITINERARIO,
    LIMPAR_FILTRO_ITINERARIO,
    REMOVER_FILTRO_ITINERARIO,
    LISTAR_FILTROS_ITINERARIO
  } from '../../actions/types'
  import { filtroExiste } from '../../util/filtros'
  
  const initialState = undefined

  const initial = {}
  
  export const filtrosAdicionadosItinerario = (state = [], action = initialState) => {
    switch (action.type) {
      case ADICIONAR_FILTRO_ITINERARIO: {      
        if (!filtroExiste(state, action.payload)) {
          return state.concat(action.payload)
        }
        return state
      }
  
      case REMOVER_FILTRO_ITINERARIO: {
        const copia = [...state]
        return copia.filter(filtro => filtro.chave !== action.payload.chave || filtro.valor !== action.payload.valor)
      }
  
      case LIMPAR_FILTRO_ITINERARIO: {
        return []
      }
  
      default:
        return state
    }
  }

  export const filtrosEmpresa = (state = initial, action) => {
    switch (action.type) {
      case LISTAR_FILTROS_ITINERARIO: {
        return action.payload.data
      }
      default:
        return state
    }
  }