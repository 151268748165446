export const BUSCAR_ACERVOS = 'BUSCAR_ACERVOS';
export const LIMPAR_ACERVOS = 'LIMPAR_ACERVOS';
export const BUSCAR_ARQUIVOS_PASTA_ACERVOS = 'BUSCAR_ARQUIVOS_PASTA_ACERVOS';
export const BUSCAR_PEDIDOS = 'BUSCAR_PEDIDOS';
export const ALTERAR_DETALHAMENTO_PEDIDO = 'ALTERAR_DETALHAMENTO_PEDIDO';

export const ADICIONAR_FILTRO_PEDIDO = 'ADICIONAR_FILTRO_PEDIDO';
export const REMOVER_FILTRO_PEDIDO = 'REMOVER_FILTRO_PEDIDO';
export const LIMPAR_FILTRO_PEDIDO = 'LIMPAR_FILTRO_PEDIDO';
export const LISTAR_FILTROS_PEDIDOS = 'LISTAR_FILTROS_PEDIDOS';

export const ADICIONAR_FILTRO_DATA_REJEITADA = 'ADICIONAR_FILTRO_DATA_REJEITADA';
export const LIMPAR_FILTRO_DATA_REJEITADA = 'LIMPAR_FILTRO_DATA_REJEITADA';

export const ALTERAR_REGISTROS_POR_PAGINA_PEDIDO = 'ALTERAR_REGISTROS_POR_PAGINA_PEDIDO';
export const DETERMINAR_PAGINA_ATUAL_PEDIDO = 'DETERMINAR_PAGINA_ATUAL_PEDIDO';

export const BUSCAR_ITINERARIO = 'BUSCAR_ITINERARIO';
export const BUSCAR_TRECHO = 'BUSCAR_TRECHO';
export const ADICIONAR_FILTRO_ITINERARIO = 'ADICIONAR_FILTRO_ITINERARIO';
export const LISTAR_FILTROS_ITINERARIO = 'LISTAR_FILTROS_ITINERARIO';
export const ALTERAR_DETALHAMENTO_ITINERARIO = 'ALTERAR_DETALHAMENTO_ITINERARIO';
export const ALTERAR_REGISTROS_POR_PAGINA_ITINERARIO = 'ALTERAR_REGISTROS_POR_PAGINA_ITINERARIO';
export const DETERMINAR_PAGINA_ATUAL_ITINERARIO = 'DETERMINAR_PAGINA_ATUAL_ITINERARIO';
export const LIMPAR_FILTRO_ITINERARIO = 'LIMPAR_FILTRO_ITINERARIO';
export const REMOVER_FILTRO_ITINERARIO = 'REMOVER_FILTRO_ITINERARIO';

export const BUSCAR_DADOS_DASHBOARD = 'BUSCAR_DADOS_DASHBOARD';
export const LIMPAR_DADOS_DASHBOARD = 'LIMPAR_DADOS_DASHBOARD';

export const BUSCAR_OPEN_GRAPH = 'BUSCAR_OPEN_GRAPH';