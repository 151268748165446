import React, { Component } from 'react';
import { connect } from 'react-redux';
import buscarArquivosPastasAcervoDivulgacao from '../../actions/acervo/buscarArquivosPastasAcervoDivulgacao';
import '../../estilo/css/GaleriaAcervoDivulgacao.css'
import Titulo from '../comum/Titulo';
import qs from 'qs';
import baixarArquivoAcervoDivulgacao from '../../actions/acervo/baixarArquivoAcervoDivulgacao';
import { Link } from 'react-router-dom';
import Galeria from './Galeria';

export class GaleriaAcervoDivulgacao extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedImg: null,
            parametros: qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true })
        }
    }

    componentDidMount() {
        if (this.state.parametros.pasta) {
            this.props.buscarArquivosPastasAcervoDivulgacao(this.state.parametros.pasta + '/')
        }
    }

    baixarArquivo(chave, nomeArquivo, pasta, subPasta) {
        baixarArquivoAcervoDivulgacao(chave, nomeArquivo, pasta, subPasta)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nomeArquivo);
                document.body.appendChild(link);

                link.click();
            });
    }

    naoEhObjetoVazio = (acervos) => (!(Object.keys(acervos).length === 0))

    setSelectedImg(selectedImg) {
        this.setState({ selectedImg })
    }

    render() {
        return (
            <div className='acervo-container'>
                <div className='titulo-acervo'>
                    <div className="btn-retornar">
                        <Link to={`/painel-acervo-divulgacao-pastas?pasta=${this.state.parametros.pasta}`}>
                            <i id='btn-navegation' className='bx bx-left-arrow-alt'> </i>
                        </Link>
                    </div>

                    <Titulo texto="Galeria de Acervos" urlRetorno={`/painel-acervo-divulgacao-pastas?pasta=${this.state.parametros.pasta}`} />

                </div>

                <div className="galeria-container">
                    <div className='cards-acervo'>
                        <Galeria 
                            acervos={this.props.acervos}
                            naoEhObjetoVazio={this.naoEhObjetoVazio.bind(this)}
                            selectedImg={this.state.selectedImg}
                            setSelectedImg={this.setSelectedImg.bind(this)}
                            baixarArquivo={this.baixarArquivo.bind(this)}
                        />
                    </div>
                </div>

            </div >
        )
    }
}

export const mapStateToProps = state => {
    return {
        acervos: state.acervoDivulgacao
    }
}

export default connect(mapStateToProps, {
    buscarArquivosPastasAcervoDivulgacao
})(GaleriaAcervoDivulgacao)
