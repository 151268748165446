import {
  ADICIONAR_FILTRO_DATA_REJEITADA, LIMPAR_FILTRO_DATA_REJEITADA
} from '../actions/types'

const initialState = undefined

export default function reducerFiltrosDatasRejeitadas (state = [], action = initialState) {
  switch (action.type) {
    case ADICIONAR_FILTRO_DATA_REJEITADA: {
      const datasRejeitadas = Object.assign([], state);
      const novasDatas = [];
      if (datasRejeitadas.length > 0) {
        datasRejeitadas.map(data => novasDatas.push(data));
      }
      novasDatas.push(action.payload);
      return novasDatas
    }

    case LIMPAR_FILTRO_DATA_REJEITADA: {
      const novasDatas = [];
      const datasRejeitadas = Object.assign([], state);
      if (datasRejeitadas.length > 0) {
        datasRejeitadas.forEach((data) => {
          if (data.chave !== action.payload.chave) {
            novasDatas.push(data)
          }
        })
      }
      return novasDatas
    }

    default:
      return state
  }
}
