import {BUSCAR_ACERVOS, BUSCAR_ARQUIVOS_PASTA_ACERVOS, LIMPAR_ACERVOS} from '../../actions/types'

const initialState = undefined;

export default function reducerBuscarAcervoDivulgacao (state = {}, action = initialState) {
  switch (action.type) {
    case BUSCAR_ACERVOS: {
      const acervos = action.payload.data;
      if (acervos) {
        return acervos
      }
      return []
    }
    case BUSCAR_ARQUIVOS_PASTA_ACERVOS: {
      const arquivos = action.payload.data;
      if (arquivos) {
        return arquivos
      }
      return []
    }    
    case LIMPAR_ACERVOS:
      return {};

    default: {
      return state
    }
  }
}
