import React, { Component } from 'react';
import { Carousel, Card } from 'react-bootstrap';
import buscarOpenGraphSites from '../../actions/openGraphActions';
import { connect } from 'react-redux';

const BlogCarousel = ({ blogs, ogDataList }) => {
  return (
    <Carousel interval={null}>
      {blogs.map((blogSet, index) => (
        <Carousel.Item key={index}>
          <div id='container-cards' className="row">
            {Array.isArray(blogSet) ? (
              blogSet.map((card, cardIndex) => {
                const ogData = ogDataList.find(data => data.url === card.link);
                return (
                  <div id='container-card' className="col-md-4" key={cardIndex}>
                    <a href={card.link} target="_blank" rel="noopener noreferrer">
                      <Card>
                        <Card.Body>
                          <Card.Title>{ogData ? ogData.properties['og:title'] : card.title}</Card.Title>
                          <Card.Img alt='imagem noticia blog' variant="top" src={ogData ? ogData.properties['og:image'] : card.image} />
                          <Card.Text>  {ogData && ogData.properties['og:description'] !== '' ? ogData.properties['og:description'] : card.description}</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <span href={card.link} target="_blank" rel="noopener noreferrer">
                            Leia mais
                          </span>
                        </Card.Footer>
                      </Card>
                    </a>
                  </div>
                );
              })
            ) : (
              <p>Nenhum blog encontrado.</p>
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export class CarouselBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ogDataList: [],
      blogs: [
        [
          {
            title: 'Vexado Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.vexadoblog.com/'
          },
          {
            title: 'Brasil Passagens Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.brasilpassagensblog.com/'
          },
          {
            title: 'Bichos News',
            description: 'Breve descrição do Blogs',
            link: 'https://www.bichosnews.net/'
          },
          {
            title: 'Olhar da Tecnologia',
            description: 'Explore o fascinante mundo da tecnologia no blog Olhar da Tecnologia. Fique por dentro das últimas tendências, novidades e análises do universo tech. Uma fonte indispensável para os amantes da inovação e da ciência. Venha conhecer o futuro hoje!',
            link: 'https://www.olhardatecnologia.com/'
          }
        ],
        [
          {
            title: 'Vexado',
            description: 'O seu site para compra de passagens de ônibus. Somos uma solução completa que oferece diversas opções para os passageiros, além de atender às necessidades das empresas com gerenciamento de bilhetagem, emissão de notas fiscais e serviços de encomendas, tudo em um único lugar.',
            link: 'https://vexado.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/vexado.webp'
          },
          {
            title: 'Brasil Passagens Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.brasilpassagensblog.com/'
          },
          {
            title: 'Basílio Passagens',
            description: 'Faça a compra das suas passagens de ônibus com toda comodidade na Basílio e Basílio. Com uma ampla variedade de horários e opções de trajetos para atender às suas preferências, oferecemos sempre os preços mais competitivos do mercado. Dê as boas-vindas à Basílio e Basílio, a sua escolha de excelência para viagens de ônibus!',
            link: 'https://basilioviagens.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/basilioviagens.webp'
          },
          {
            title: 'Bichos News',
            description: 'Breve descrição do Blogs',
            link: 'https://www.bichosnews.net/'
          }
        ],
        [
          {
            title: 'Hot Day News',
            description: 'Descubra um oásis de notícias que vão te cativar, informar e divertir. Com uma abordagem descontraída e conteúdos variados, mergulhe em histórias fascinantes que vão te prender do início ao fim. Prepare-se para se apaixonar pela economia e se encantar pelos bichos. Entre e seja surpreendido!',
            link: 'https://www.hotdaynews.com/'
          },
          {
            title: 'Olhar da Tecnologia',
            description: 'Explore o fascinante mundo da tecnologia no blog Olhar da Tecnologia. Fique por dentro das últimas tendências, novidades e análises do universo tech. Uma fonte indispensável para os amantes da inovação e da ciência. Venha conhecer o futuro hoje!',
            link: 'https://www.olhardatecnologia.com/'
          },
          {
            title: 'Transbrasil Passagens',
            description: 'Facilite a aquisição de suas passagens de ônibus com a Transbasil, tudo isso no conforto do seu lar. Contamos com uma extensa seleção de horários e itinerários para atender a todos os seus requisitos, sempre com preços altamente competitivos. Seja bem-vindo à Transbasil, a escolha líder para suas viagens de ônibus!',
            link: 'https://transbrasilpassagens.com/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/transamericapassagens.webp'
          },
          {
            title: 'Amarelinho',
            description: 'Adquira passagens de ônibus no conforto da sua casa com a Viação Amarelinho. Temos uma ampla variedade de horários e opções de viações para atender às suas necessidades, tudo isso com os preços mais competitivos do mercado. Bem-vindo à sua melhor escolha para viagens de ônibus!',
            link: 'https://viacaoamarelinho.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/amarelinho.webp'
          }
        ],
      ],
      blogs1: [
        [
          {
            title: 'Vexado',
            description: 'O seu site para compra de passagens de ônibus. Somos uma solução completa que oferece diversas opções para os passageiros, além de atender às necessidades das empresas com gerenciamento de bilhetagem, emissão de notas fiscais e serviços de encomendas, tudo em um único lugar.',
            link: 'https://vexado.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/vexado.webp'
          },
          {
            title: 'Brasil Passagens Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.brasilpassagensblog.com/'
          },
          {
            title: 'Quero uma Agência',
            description: 'Já imaginou ter o SEU PRÓPRIO site de vendas de passagens de ônibus? E o SEU PRÓPRIO aplicativo? Agora você pode transformar essa visão em realidade! Bem-vindo ao nosso revolucionário marketplace de vendas de passagens de ônibus, onde você pode vender passagens de todas as empresas cadastradas e ganhar as mesmas comissões que elas praticam.',
            link: 'https://queroumaagencia.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/Quero-uma-Agencia.png'
          },
          {
            title: 'Hot Day News',
            description: 'Descubra um oásis de notícias que vão te cativar, informar e divertir. Com uma abordagem descontraída e conteúdos variados, mergulhe em histórias fascinantes que vão te prender do início ao fim. Prepare-se para se apaixonar pela economia e se encantar pelos bichos. Entre e seja surpreendido!',
            link: 'https://www.hotdaynews.com/'
          }
        ],
        [
          {
            title: 'Vexado Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.vexadoblog.com/'
          },
          {
            title: 'Brasil Passagens Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.brasilpassagensblog.com/'
          },
          {
            title: 'Olhar da Tecnologia',
            description: 'Explore o fascinante mundo da tecnologia no blog Olhar da Tecnologia. Fique por dentro das últimas tendências, novidades e análises do universo tech. Uma fonte indispensável para os amantes da inovação e da ciência. Venha conhecer o futuro hoje!',
            link: 'https://www.olhardatecnologia.com/'
          },
          {
            title: 'Bichos News',
            description: 'Breve descrição do Blogs',
            link: 'https://www.bichosnews.net/'
          }
        ],
        [
          {
            title: 'Hot Day News',
            description: 'Descubra um oásis de notícias que vão te cativar, informar e divertir. Com uma abordagem descontraída e conteúdos variados, mergulhe em histórias fascinantes que vão te prender do início ao fim. Prepare-se para se apaixonar pela economia e se encantar pelos bichos. Entre e seja surpreendido!',
            link: 'https://www.hotdaynews.com/'
          },
          {
            title: 'Brasil Passagens',
            description: 'Compre passagens de ônibus sem sair de casa, aqui no Brasil Passagens. Temos diversas opções de horários e viações para você escolher, sempre com os melhores preços do mercado.',
            link: 'https://brasilpassagens.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/brasilpassagens.webp'
          },
          {
            title: 'Olhar da Tecnologia',
            description: 'Explore o fascinante mundo da tecnologia no blog Olhar da Tecnologia. Fique por dentro das últimas tendências, novidades e análises do universo tech. Uma fonte indispensável para os amantes da inovação e da ciência. Venha conhecer o futuro hoje!',
            link: 'https://www.olhardatecnologia.com/'
          },
          {
            title: 'Transbrasil Passagens',
            description: 'Facilite a aquisição de suas passagens de ônibus com a Transbasil, tudo isso no conforto do seu lar. Contamos com uma extensa seleção de horários e itinerários para atender a todos os seus requisitos, sempre com preços altamente competitivos. Seja bem-vindo à Transbasil, a escolha líder para suas viagens de ônibus!',
            link: 'https://transbrasilpassagens.com/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/transamericapassagens.webp'
          }
        ],
      ],
      blogs2: [
        [
          {
            title: 'Hot Day News',
            description: 'Descubra um oásis de notícias que vão te cativar, informar e divertir. Com uma abordagem descontraída e conteúdos variados, mergulhe em histórias fascinantes que vão te prender do início ao fim. Prepare-se para se apaixonar pela economia e se encantar pelos bichos. Entre e seja surpreendido!',
            link: 'https://www.hotdaynews.com/'
          },
          {
            title: 'Olhar da Tecnologia',
            description: 'Explore o fascinante mundo da tecnologia no blog Olhar da Tecnologia. Fique por dentro das últimas tendências, novidades e análises do universo tech. Uma fonte indispensável para os amantes da inovação e da ciência. Venha conhecer o futuro hoje!',
            link: 'https://www.olhardatecnologia.com/'
          },
          {
            title: 'Transbrasil Passagens',
            description: 'Facilite a aquisição de suas passagens de ônibus com a Transbasil, tudo isso no conforto do seu lar. Contamos com uma extensa seleção de horários e itinerários para atender a todos os seus requisitos, sempre com preços altamente competitivos. Seja bem-vindo à Transbasil, a escolha líder para suas viagens de ônibus!',
            link: 'https://transbrasilpassagens.com/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/transamericapassagens.webp'
          },
          {
            title: 'Amarelinho',
            description: 'Adquira passagens de ônibus no conforto da sua casa com a Viação Amarelinho. Temos uma ampla variedade de horários e opções de viações para atender às suas necessidades, tudo isso com os preços mais competitivos do mercado. Bem-vindo à sua melhor escolha para viagens de ônibus!',
            link: 'https://viacaoamarelinho.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/amarelinho.webp'
          }
        ],
        [
          {
            title: 'Vexado',
            description: 'O seu site para compra de passagens de ônibus. Somos uma solução completa que oferece diversas opções para os passageiros, além de atender às necessidades das empresas com gerenciamento de bilhetagem, emissão de notas fiscais e serviços de encomendas, tudo em um único lugar.',
            link: 'https://vexado.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/vexado.webp'
          },
          {
            title: 'Brasil Passagens Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.brasilpassagensblog.com/'
          },
          {
            title: 'Quero uma Agência',
            description: 'Já imaginou ter o SEU PRÓPRIO site de vendas de passagens de ônibus? E o SEU PRÓPRIO aplicativo? Agora você pode transformar essa visão em realidade! Bem-vindo ao nosso revolucionário marketplace de vendas de passagens de ônibus, onde você pode vender passagens de todas as empresas cadastradas e ganhar as mesmas comissões que elas praticam.',
            link: 'https://queroumaagencia.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/Quero-uma-Agencia.png'
          },
          {
            title: 'Basílio Passagens',
            description: 'Faça a compra das suas passagens de ônibus com toda comodidade na Basílio e Basílio. Com uma ampla variedade de horários e opções de trajetos para atender às suas preferências, oferecemos sempre os preços mais competitivos do mercado. Dê as boas-vindas à Basílio e Basílio, a sua escolha de excelência para viagens de ônibus!',
            link: 'https://basilioviagens.com.br/',
            image: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/basilioviagens.webp'
          }
        ],
        [
          {
            title: 'Vexado Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.vexadoblog.com/'
          },
          {
            title: 'Brasil Passagens Blog',
            description: 'Breve descrição do Blogs',
            link: 'https://www.brasilpassagensblog.com/'
          },
          {
            title: 'Olhar da Tecnologia',
            description: 'Explore o fascinante mundo da tecnologia no blog Olhar da Tecnologia. Fique por dentro das últimas tendências, novidades e análises do universo tech. Uma fonte indispensável para os amantes da inovação e da ciência. Venha conhecer o futuro hoje!',
            link: 'https://www.olhardatecnologia.com/'
          },
          {
            title: 'Bichos News',
            description: 'Breve descrição do Blogs',
            link: 'https://www.bichosnews.net/'
          }
        ]
      ]
    };
  }

  componentDidMount() {
    this.props.buscarOpenGraphSites()
  }

  render() {
    return (
      <div className="App">
        <h1 id='titulo-blogs'>
          <b id='titulo-blogs'> Blogs Parceiros </b>
        </h1>
        <div className='Container-Carousel'>
          <BlogCarousel blogs={this.state.blogs} ogDataList={this.props.openGraph} />
          <BlogCarousel blogs={this.state.blogs1} ogDataList={this.props.openGraph} />
          <BlogCarousel blogs={this.state.blogs2} ogDataList={this.props.openGraph} />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => (
  {
    openGraph: state.openGraph
  });

export default connect(mapStateToProps, { buscarOpenGraphSites })(CarouselBlog)