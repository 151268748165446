import React from 'react'
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import BarraNavegacaoLogin from './BarraNavegacaoLogin';

const QuemSomos = () => (

  <div className='card border-0'>
    <BarraNavegacaoLogin />
    <div className='card-body'>
      <div className='loginHeader'>
        <h1 id='quem-somos'>Quem somos</h1>
        <div className="divider" />
      </div>

      <div className="page-contato" style={{ fontSize: 16, backgroundColor: '#ffffff' }}>
        <section className="gv-section">
          <div className="section-content">
            <h2 className="gv-title"><b>Quem Somos - Gestão Agência</b></h2>

            <p className="txt-item">Seja bem-vindo ao Gestão Agência! Somos uma plataforma inovadora que veio para simplificar a forma como você adquire suas passagens de ônibus.</p>
            <p className="txt-item">Nosso objetivo é proporcionar a você uma experiência de reserva de bilhetes rápida, fácil e sem complicações.</p>

            <h2 className="gv-title" style={{ marginTop: 24 }}><b>Nossa História</b></h2>

            <p className="txt-item">O Gestão Agência surgiu da observação de um problema comum enfrentado por viajantes: a reserva de passagens de ônibus.</p>
            <p className="txt-item">Muitas vezes, as pessoas precisam passar por um processo complicado de busca em diversos sites, comparando itinerários e disponibilidade, antes de finalmente garantir seus bilhetes. Foi então que percebemos a necessidade de simplificar esse processo.</p>
            <br></br>
            <p className="txt-item">Fundado em 2020, o Gestão Agência começou com a missão de tornar a reserva de passagens de ônibus mais conveniente. Trabalhamos diligentemente para criar uma solução que facilitasse a vida dos viajantes, economizando tempo e energia na busca e reserva de bilhetes.</p>
            <br></br>
            <h2 className="gv-title" style={{ marginTop: 24 }}><b>O que Fazemos</b></h2>

            <p className="txt-item">No Gestão Agência, atuamos como um facilitador confiável entre você e as melhores opções de passagens de ônibus disponíveis no mercado. </p>
            <p className="txt-item">Nossa plataforma exclusiva coleta e compila informações sobre passagens que estão disponíveis em diversas fontes respeitáveis. Dessa forma, você pode encontrar as melhores ofertas em um único lugar.</p>

            <h2 className="gv-title" style={{ marginTop: 24 }}><b>Nossa abordagem é simples:</b></h2>

            <p className="txt-item"><b>Pesquisa Eficiente:</b> Utilizamos tecnologia avançada para rastrear passagens de ônibus disponíveis em diferentes empresas e rotas. Isso significa que você não precisa mais passar horas procurando. Basta inserir seus detalhes de viagem, e nós fazemos o trabalho pesado para você.</p>
            <p className="txt-item"><b>Comparação Simples:</b> Apresentamos uma lista organizada de opções de itinerários, com detalhes claros sobre preços, horários e empresas de ônibus. Assim, você pode tomar decisões informadas em questão de minutos.</p>
            <p className="txt-item"><b>Reserva Facilitada:</b> Após selecionar o itinerário que mais lhe convém, você pode fazer a reserva diretamente em nosso site. Facilitamos o processo de reserva, tornando-o rápido e conveniente.</p>
            <p className="txt-item"><b>Gestão Agência:</b> Após a reserva, você receberá uma confirmação eletrônica para imprimir ou salvar em seu dispositivo. É simples assim - você terá seu <b>Gestão Agência</b> e estará pronto para viajar!</p>

            <h2 className="gv-title" style={{ marginTop: 24 }}><b>Nossa Equipe</b></h2>

            <p className="txt-item">Por trás do Gestão Agência, está uma equipe dedicada de profissionais apaixonados por viagens e tecnologia. Nossa equipe é composta por especialistas em pesquisa de mercado e atendimento ao cliente, todos unidos pelo objetivo comum de tornar sua experiência de reserva de passagens de ônibus excepcional.</p>

            <h2 className="gv-title" style={{ marginTop: 24 }}><b>Compromisso com a Qualidade e Transparência</b></h2>

            <p className="txt-item">No Gestão Agência, valorizamos a transparência e a qualidade de serviço. Trabalhamos incansavelmente para garantir que todas as informações em nossa plataforma sejam precisas e atualizadas.</p>
            <p className="txt-item">Além disso, estamos sempre disponíveis para responder a quaisquer dúvidas ou preocupações que você possa ter.</p>

            <h2 className="gv-title" style={{ marginTop: 24 }}><b>O Futuro</b></h2>

            <p className="txt-item">Estamos constantemente inovando e aprimorando nossa plataforma para atender melhor às suas necessidades de viagem. No Gestão Agência, acreditamos que cada viagem deve começar com facilidade e conveniência, e estamos comprometidos em tornar isso uma realidade para todos os viajantes.</p>
            <p className="txt-item">Obrigado por escolher o Gestão Agência como seu parceiro de reserva de passagens de ônibus. Esperamos que você tenha uma experiência excepcional em nossos serviços e estamos ansiosos para ajudá-lo a fazer suas próximas viagens de forma mais simples e eficiente. Seja bem-vindo a uma nova era de reserva de bilhetes de ônibus - com o Gestão Agência, você está no controle.</p>

            <br></br>
            <h2 className="gv-title" style={{ marginTop: 24 }}><b>Nossos Valores</b></h2>

            <p className="txt-item"><b>SOMOS PESSOAS SERVINDO PESSOAS</b></p>
            <p className="txt-item"><b>PENSAMOS E AGIMOS COMO DONOS</b></p>
            <p className="txt-item"><b>BUSCAMOS A EXCELÊNCIA E A SIMPLICIDADE</b></p>
            <p className="txt-item"><b>TEMOS GANA POR PERFORMANCE</b></p>
            <p className="txt-item"><b>CRESCEMOS E APRENDEMOS JUNTOS</b></p>
            <p className="txt-item"><b>INSPIRAMOS, INCENTIVAMOS E CELEBRAMOS A CULTURA DE INOVAÇÃO</b></p>
            <br></br>
            <p className="txt-item">O VEXADO implementa e desenvolve soluções personalizadas, não importa o tamanho,</p>
            <p className="txt-item">o segmento ou o modelo de negócio. Independentemente do que as empresas precisam, nós temos a solução.</p>


            <section className="gv-cards" style={{ marginTop: 50 }}>
              <div className="gv-cards-wrapper">
                <div className="gv-card gv-card-qs">
                  <div className="nome-card">
                    <span className="box-item-card-title">
                      <DevicesOutlinedIcon className="box-item-icon"></DevicesOutlinedIcon>
                      <p>Tecnologia</p>
                      <div className="divider" />
                    </span>
                    <span className="box-item-card-info">
                      <p>Descomplicamos o jeito antigo complicado de comprar uma passagem!</p>
                    </span>
                  </div>
                </div>
                <div className="gv-card gv-card-qs">
                  <div className="nome-card">
                    <span className="box-item-card-title">
                      <EmojiObjectsOutlinedIcon className="box-item-icon"></EmojiObjectsOutlinedIcon>
                      <p>Inovação</p>
                      <div className="divider" />
                    </span>
                    <span className="box-item-card-info">
                      <p>Inovamos em um mercado promissor, tecnologia simples, aplicada e segura para você, sua família e amigos.</p>
                    </span>
                  </div>
                </div>
                <div className="gv-card gv-card-qs">
                  <div className="nome-card">
                    <span className="box-item-card-title">
                      <PeopleAltOutlinedIcon className="box-item-icon"></PeopleAltOutlinedIcon>
                      <p>Relacionamento</p>
                      <div className="divider" />
                    </span>
                    <span className="box-item-card-info">
                      <p>Pensamos em você muito mais que um simples cliente, temos você como parte de nossa empresa, sem você não teríamos motivos de estar aqui!</p>
                    </span>
                  </div>
                </div>
                <div className="gv-card gv-card-qs">
                  <div className="nome-card">
                    <span className="box-item-card-title">
                      <TrendingUpOutlinedIcon className="box-item-icon"></TrendingUpOutlinedIcon>
                      <p>Agilidade</p>
                      <div className="divider" />
                    </span>
                    <span className="box-item-card-info">
                      <p>Rápido e descomplicado, clicou, comprou, viajou.</p>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

    </div>
  </div>

)

export default QuemSomos;

