import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import OpcoesPaginacao from '../comum/OpcoesPaginacao'
import alterarRegistrosPorPagina from '../../actions/pedido/alterarRegistrosPorPaginaPedido'
import determinarPaginaAtual from '../../actions/pedido/determinarPaginaAtuaPedido'

export class PaginacaoPedido extends Component {
  render () {
    return <OpcoesPaginacao
      registrosPorPagina={this.props.registrosPorPagina}
      numeroElementos={this.props.total}
      paginaAtual={this.props.paginaAtual}
      determinarPaginaAtual={this.props.determinarPaginaAtual}
      alterarRegistrosPorPagina={this.props.alterarRegistrosPorPagina}/>
  }
}

export function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    alterarRegistrosPorPagina,
    determinarPaginaAtual
  }, dispatch)
}

export function mapStateToProps (state) {
  return {
    paginaAtual: state.paginaAtualPedido,
    total: state.pedidos.total,
    registrosPorPagina: state.registrosPorPaginaPedido
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginacaoPedido)
