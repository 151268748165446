import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import OpcoesPaginacao from '../comum/OpcoesPaginacao'
import alterarRegistrosPorPagina from '../../actions/itinerario/alterarRegistrosPorPaginaItinerario';
import determinarPaginaAtual from '../../actions/itinerario/determinarPaginaAtualItinerario';

export class PaginacaoItinerario extends Component {
  render () {
    return <OpcoesPaginacao
      registrosPorPagina={this.props.registrosPorPagina}
      numeroElementos={this.props.total}
      paginaAtual={this.props.paginaAtual}
      determinarPaginaAtual={this.props.determinarPaginaAtual}
      alterarRegistrosPorPagina={this.props.alterarRegistrosPorPagina}/>
  }
}

export function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    alterarRegistrosPorPagina,
    determinarPaginaAtual
  }, dispatch)
}

export function mapStateToProps (state) {
  return {
    paginaAtual: state.paginaAtualItinerario,
    total: state.itinerario.total,
    registrosPorPagina: state.registrosPorPaginaItinerario
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginacaoItinerario)