/* eslint-disable */
import React, {Component} from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

export class AuthenticatedRoute extends Component {
    renderComponent(isAuthenticated, Component) {
        return props => {
            if (!isAuthenticated) {
                return (<Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.location},
                    }}
                />)
            }
            return (<Component {...props} />)
        }
    }

    render() {
        const {component: Component, ...rest} = this.props;
        return (
            <Route {...rest} render={this.renderComponent(this.props.dadosAutenticacao && this.props.dadosAutenticacao.isAuthenticated, Component)}/>)
    }
}

export const mapStateToProps = state => {
    return {
        dadosAutenticacao: state.usuario.dadosAutenticacao,
    }
}

export default connect(mapStateToProps, {})(AuthenticatedRoute)
