import React, { Component } from 'react'
import LogoGestaoAgencia from '../../estilo/img/logo_gestão_agência.png';
import { HashLink } from 'react-router-hash-link';

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dadosAutenticacao: '',
            mostrarRodape: false
        };
    }

    alternarVisibilidadeRodape = () => {
        this.setState({
            mostrarRodape: !this.state.mostrarRodape
        });
    };

    arrow() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="arrow" width="12" height="12" fill="currentColor"
                className={`bi bi-caret-up-fill ${this.state.mostrarRodape ? 'rotated' : ''}`} viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
            </svg>
        );
    }

    render() {
        const anoAtual = new Date().getFullYear();
        const { mostrarRodape } = this.state;

        return (
            <footer className='container-footer'>
                <div className="container">
                    <button
                        id="MaisInformacoes"
                        name="Mais Informações"
                        aria-label="Mais Informações"
                        className={`botaoMaisInformacoes ${mostrarRodape ? 'collapsed' : ''}`}
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded={mostrarRodape ? 'true' : 'false'}
                        aria-controls="collapseOne"
                        onClick={this.alternarVisibilidadeRodape}
                    >
                        Mais Informações {this.arrow()}
                    </button>
                    {mostrarRodape && <div className="row">
                        <div className="col-12 col-md sobre">
                            <div className='titulo-footer'>Parcerias</div>
                            <ul className="list-unstyled text-small">
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://vexado.com.br/" target="_blank" rel="noreferrer">
                                        Vexado
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://www.vexadoblog.com/" target="_blank" rel="noreferrer">
                                        Vexado Blog
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://queroumaagencia.com.br/" target="_blank" rel="noreferrer">
                                        Quero uma agencia
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://www.hotdaynews.com/" target="_blank" rel="noreferrer">
                                        Hot day News
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://brasilpassagens.com.br/" target="_blank" rel="noreferrer">
                                        Brasil Passagens
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://www.brasilpassagensblog.com/" target="_blank" rel="noreferrer">
                                        Brasil Passagens Blog
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://www.olhardatecnologia.com/" target="_blank" rel="noreferrer">
                                        Olhar da tecnologia
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://www.bichosnews.net/" target="_blank" rel="noreferrer">
                                        Bichos News
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://diariodotransporte.com.br/" target="_blank" rel="noreferrer">
                                        Diario dos transportes
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://vexado.com.br/" target="_blank" rel="noreferrer">
                                        Vexado
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href="https://bilhetenamao.com.br/" target="_blank" rel="noreferrer">
                                        Bilhete na Mão
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <div className='titulo-footer'>Atendimento</div>
                            <ul className="list-unstyled text-small">
                                <li className='mt-2'>
                                    <div id='footerAtendimento'>
                                        <span id='spanAtendimento'>
                                            Horário de atendimento:
                                            08:00 às 18:00 -
                                            Segunda a Sábado,
                                            horário de Brasília
                                            (Exceto domingo e feriados)
                                        </span>
                                    </div>
                                    <div id='footerAtendimento'>
                                        <span id='spanAtendimento'>
                                            Contate agora nossa central via whatsapp link:<br />
                                        </span>
                                        <a id='spanAtendimento' href="https://wa.me/556141015235" target="_blank" rel="noopener noreferrer">
                                            (61) 4101-5235
                                        </a>
                                    </div>
                                    <div id='footerAtendimento'>
                                        <span id='spanAtendimento'>
                                            Central Atendimento ao Cliente:
                                            (61) 4101-5235
                                        </span>
                                    </div>
                                    <div id='footerAtendimento'>
                                        <span id='spanAtendimento'>
                                            E-mail:
                                            contato@vexado.com.br
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <div className='titulo-footer'>Bilhete na Mão</div>
                            <ul className="list-unstyled text-small">
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/quem-somos/#quem-somos'>
                                        Quem Somos
                                    </HashLink>
                                </li>
                                <li className='mt-2 vexado'>
                                    <a target="_blank" rel="noreferrer" href="https://queroumaagencia.com/">
                                        Quero uma Agência
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a className="mt-2" href='/atendimento/#secao-atendimento'>
                                        Atendimento
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/termos-de-uso/#secao-termos'>
                                        Termos de uso
                                    </HashLink>
                                </li>
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/politica-de-privacidade/#secao-politica-privacidade'>
                                        Política de privacidade
                                    </HashLink>
                                </li>
                                <li className='mt-2'>
                                    <HashLink className="mt-2" to='/politica-de-cookies/#secao-politica-cookies'>
                                        Política de cookies
                                    </HashLink>
                                </li>
                                <li className='mt-2'>
                                    <a target="_blank" rel='noreferrer' href='https://www.facebook.com/vexadovendadepassagens'>
                                        Facebook
                                    </a>
                                </li>
                                <li className='mt-2'>
                                    <a target="_blank" rel='noreferrer' href='https://www.instagram.com/vexadooficial'>
                                        Instagram
                                    </a>
                                </li>
                                <li className='mt-2 blog'>
                                    <a href='https://www.vexadoblog.com/' target="_blank" rel="noreferrer">
                                        Blog
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>}
                    <div className="row linha-Copyright">
                        <div className="col-lg-12 agencia-logo">
                            <a className="nav-link" href='/' id="logo-footer">
                                <img id='iconBilhete' src={LogoGestaoAgencia} alt="Ícone Bilhete"></img>
                            </a>
                            <span>© {anoAtual} Copyright - Todos os direitos reservados. Gestão Agência.</span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default (Footer)