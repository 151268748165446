import {DISMISS_MESSAGE, SHOW_MESSAGE} from '../reducers/messagesReducer';

export const showMessage = message => ({
  type: SHOW_MESSAGE,
  payload: {
    message
  }
});

export const dismissMessage = () => ({
  type: DISMISS_MESSAGE,
  payload: {
    message: null
  }
});
