import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import determinarPaginaAtuaPedido from '../../actions/pedido/determinarPaginaAtuaPedido'
import BotoesPaginacao from '../comum/BotoesPaginacao'

export class BotoesPaginacaoPedido extends Component {
  render () {
    return <BotoesPaginacao
      numeroElementos={this.props.total}
      paginaAtual={this.props.paginaAtual}
      registrosPorPagina={this.props.registrosPorPagina}
      determinarPaginaAtual={this.props.determinarPaginaAtuaPedido}/>
  }
}

export function mapStateToProps (state) {
  return {
    paginaAtual: state.paginaAtualPedido,
    total: state.pedidos.total,
    registrosPorPagina: state.registrosPorPaginaPedido
  }
}

export function mapDispatchToProps (dispatch) {
  return bindActionCreators({ determinarPaginaAtuaPedido }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BotoesPaginacaoPedido)
