import $ from 'jquery';

const abrirModal = (nomeModal) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!$(`#${nomeModal}`)._isShown) {
    $(`#${nomeModal}`)
      .modal('show');
  }
};

export function fecharModal(nomeModal) {
  const modal = $(`#${nomeModal}`);
  modal.modal('hide');
  modal.hide();
  $('.modal-backdrop')
    .hide();
}

export function removerClassModalOpen() {
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
}
export default abrirModal;
