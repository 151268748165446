import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil'
import { URL } from '../../util/ambienteActions';
import { BUSCAR_ACERVOS } from '../types';

const buscarPastasAcervoDivulgacao = (pasta) => {
    let url = '';

    if(pasta && pasta.length > 0){
        url = `${URL}/arquivo/listar-pastas?pasta=${pasta}`;
    } else {
        url = `${URL}/arquivo/listar-pastas`;
    }
   
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: BUSCAR_ACERVOS,
            payload: response
        })
    );
}


export default buscarPastasAcervoDivulgacao