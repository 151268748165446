import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

export class BarraNavegacao extends Component {

    exibirPerfilUsuario() {
        return <ul className="nav navbar-nav ml-auto opcoes-menu menu-desktop float-right">
            <li className="nav-item menu-usuario">
                <a id="icone-circular-sair" className="nav-link" data-toggle="dropdown" role="button"
                    aria-haspopup="true" aria-expanded="false">
                    <i className='bx bx-user' id='iconePerfil' style={{ cursor: 'pointer'}}></i>
                </a>

                <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header text-center">
                        <span>{this.props.dadosAutenticacao.nomeUsuario}</span>
                    </div>
                    <div className="dropdown-content">
                        <a href='#' id="item-sair" className="dropdown-item custom-itens-dd">
                            <i className='bx bx-user'></i>
                            <span>Perfil</span>
                        </a>

                        <a href='#' id="item-sair" className="dropdown-item custom-itens-dd">
                            <i className='bx bx-cog' ></i>
                            <span>Configurações</span>
                        </a>

                        <a href='#' id="item-sair" className="dropdown-item custom-itens-dd">
                            <i className='bx bx-envelope'></i>
                            <span>Mensagens</span>
                        </a>

                        <Link id="item-sair" className="dropdown-item custom-itens-dd" to="/logout">
                            <i className='bx bx-log-out' ></i>
                            <span>Sair</span>
                        </Link>
                    </div>
                </div>


            </li>
        </ul>
    }

    render() {
        return (
            <header className="app-header navbar" style={{ display: 'flex' }}>
                <div className="icon-menu">
                    <button className="navbar-toggler sidebar-minimizer d-md-down-none" type="button"
                        data-toggle="sidebar-lg-show" data-cy="btn-lateral">
                        <i className="material-icons aberto">&#xe5d2;</i>
                        <i className="material-icons fechado">&#xe5cd;</i>
                    </button>

                    <button className="navbar-toggler sidebar-toggler d-lg-none mr-auto" type="button" data-toggle="sidebar-show">
                        <i className="material-icons aberto">&#xe5d2;</i>
                        <i className="material-icons fechado">&#xe5cd;</i>
                    </button>
                </div>

                <div className="" style={{ display: 'flex', justifyContent: 'end' }}>
                    <h1 className="nome-agencia">{this.props.dadosAutenticacao.nomeAgencia}</h1>
                </div>

                <div className='area-btn-filtro-painel'>
                    {this.exibirPerfilUsuario()}
                </div>
            </header>

        )
    }
}

export const mapStateToProps = state => (
    {
        dadosAutenticacao: state.usuario.dadosAutenticacao,

    }
)


export default withRouter(connect(mapStateToProps, {})(BarraNavegacao))
