import axios from 'axios'
import { URL } from '../../util/ambienteActions';
import { BUSCAR_DADOS_DASHBOARD, LIMPAR_DADOS_DASHBOARD } from '../types';
                
export function buscardadosDashboard(ano) {
  const url = `${URL}/dashboard?ano=${ano}`;
  return dispatch => axios.get(url).then(
    response => dispatch({
      type: BUSCAR_DADOS_DASHBOARD,
      payload: response.data
    })
  );
}

export function limparDadosDashboard () {
  return {
    type: LIMPAR_DADOS_DASHBOARD
  }
}