import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import determinarPaginaAtualItinerario from '../../actions/itinerario/determinarPaginaAtualItinerario';
import BotoesPaginacao from '../comum/BotoesPaginacao'

export class BotoesPaginacaoItinerario extends Component {
  render () {
    return <BotoesPaginacao
      numeroElementos={this.props.total}
      paginaAtual={this.props.paginaAtual}
      registrosPorPagina={this.props.registrosPorPagina}
      determinarPaginaAtual={this.props.determinarPaginaAtualItinerario}/>
  }
}

export function mapStateToProps (state) {
  return {
    paginaAtual: state.paginaAtualItinerario,
    total: state.itinerario.total,
    registrosPorPagina: state.registrosPorPaginaItinerario
  }
}

export function mapDispatchToProps (dispatch) {
  return bindActionCreators({ determinarPaginaAtualItinerario }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BotoesPaginacaoItinerario)
