import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Alert from 'react-s-alert'
// CORE UI
import '../node_modules/jquery/dist/jquery'
import '../node_modules/popper.js/dist/umd/popper.min'
import '../node_modules/bootstrap/dist/js/bootstrap.min'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/perfect-scrollbar/dist/perfect-scrollbar'

import '../node_modules/@coreui/coreui/dist/js/coreui.min'
import '../node_modules/@coreui/coreui/dist/css/coreui.min.css'

import './estilo/css/App.css'
import '../node_modules/react-s-alert/dist/s-alert-default.css';
import '../node_modules/react-s-alert/dist/s-alert-css-effects/slide.css';

import Loading from './componentes/inicio/Loading'
import AppRoutes from './routes/AppRoutes';
import Portal from '@material-ui/core/Portal'
import MessagesBar from './componentes/mensagem/MessagesBar';
import Modal from './componentes/mensagem/MessageModal';
// import TagManager from 'react-gtm-module'
import '../node_modules/material-design-icons/iconfont/material-icons.css';
import Template from './componentes/layout/Template'
import BreadCrumb from './componentes/comum/BreadCrumb';
import Footer from './componentes/layout/Footer'

import('./estilo/css/default.css');
import('./estilo/css/template.css');

export const App = () => {
    // TagManager.initialize({
    //     gtmId: ''
    // });
    return <BrowserRouter>
        <React.Fragment>
            <Loading />
            <Template />
            <div className="app-body" style={{ height: '100%' }}>
                <main className="main">
                    <div className="container-fluid container">
                        <div className="animated fadeIn">
                            <BreadCrumb/>
                            <AppRoutes/>
                        </div>
                    </div>
                    <Portal>
                        <MessagesBar />
                        <Modal />
                    </Portal>
                </main>
            </div>
            <Alert stack={{ limit: 2, spacing: 10 }}/>
            <Footer />
        </React.Fragment>
    </BrowserRouter>
};

export default connect()(App)
