import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import qs from 'qs';
import buscarPastasAcervoDivulgacao from '../../actions/acervo/buscarPastasAcervoDivulgacao';
import Titulo from '../comum/Titulo';

export class PainelAcervoDivulgacaoSubPasta extends Component {
    componentDidMount() {
        const parametros = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
        if (parametros.pasta) {
            this.props.buscarPastasAcervoDivulgacao(parametros.pasta + '/');
        }
    }

    retornarNomeDaPastaNivel1(nome) {
        return nome.substring(0, nome.indexOf('/'))
    }

    render() {
        return (
            <div className="galeria-container">
                <div className='titulo-acervo'>

                    <div className="btn-retornar">
                        <Link to='/painel-acervo-divulgacao'>
                            <i id='btn-navegation' className='bx bx-left-arrow-alt'> </i>
                        </Link>
                    </div>

                    <Titulo texto="Sub-pastas" />

                </div>

                <div id='teste' className='cards'>
                    {this.props.acervos && this.props.acervos.length ? this.props.acervos.map((item, indice) =>
                        <div id='sub-pasta-img' className='folder' key={indice}>
                            <div className='name-sub-folder'>
                                <Link className='titulo-sub-folder'
                                    to={`/painel-galeria-acervo-divulgacao?pasta=${this.retornarNomeDaPastaNivel1(item.nome)}`}>
                                    {item.subPasta}
                                </Link>
                            </div>
                        </div>
                    ) : <div>
                        <div className='msg'>
                            Pasta vazia!
                        </div>
                    </div>}
                </div>

            </div>
        )
    }
}

export const mapStateToProps = state => {
    return {
        acervos: state.acervoDivulgacao
    }
}

export default connect(mapStateToProps, {
    buscarPastasAcervoDivulgacao
})(PainelAcervoDivulgacaoSubPasta)
