import React from 'react';
import { motion } from 'framer-motion';

function ImageGrid({ setSelectedImg, doc, indice, baixarArquivo }) {
  return (
    <div>      
        <div key={doc.url}>
          <motion.div
            layout
            whileHover={{ opacity: 1 }}
            className="img-wrap"
            key={indice}
            onClick={() => setSelectedImg(doc)}
          >
            <motion.img
              src={doc.url}
              alt={doc.nome}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            />
          </motion.div>
          <button className='btn btn-success' onClick={() => baixarArquivo(doc.nome, doc.nome, doc.pasta, doc.subPasta)}>download</button>
          <button className='btn btn-secondary' onClick={() => window.open(doc.url, '_blank')}>visualizar</button><br />
        </div>
    </div>
  );
}

export default ImageGrid;
