export const FILTRO_NUMERO_PEDIDO = { chave: 'numeroPedido', label: 'Número do Pedido' };
export const FILTRO_DATA_VENDA = { chave: 'dataVenda', label: 'Data da Venda' };
export const FILTRO_EMPRESA = { chave: 'empresa', label: 'Empresa' };
export const FILTRO_FORMA_PAGAMENTO = { chave: 'formasPagamento', label: 'Forma de Pagamento' };
export const FILTRO_NOME_FANTASIA = { chave: 'empresas', label: 'Nome Fantasia' };
export const FILTRO_DATA_HORA_PARTIDA = { chave: 'dataHoraPartida', label: 'Data/Hora Partida' };
export const FILTRO_PREFIXO = { chave: 'prefixo', label: 'Prefixo' };
export const FILTRO_ROTA = { chave: 'rota', label: 'Rota' };
export const FILTRO_DELIMITACAO = { chave: 'delimitacao', label: 'Delimitação' };
export const FILTRO_VEICULO = { chave: 'veiculo', label: 'Veículo' };
export const FILTRO_ACENTOS_VENDIDOS = { chave: 'acentosVendidos', label: 'Acentos Vendidos' };
export const FILTRO_TRECHO_ORIGEM = { chave: 'trechoOrigem', label: 'Trecho Origem' };
export const FILTRO_TRECHO_DESTINO = { chave: 'trechoDestino', label: 'Trecho Destino' };

export function montarURLFiltro(filtros, pagina, registrosPorPagina) {
  let url = '';
  if (registrosPorPagina && pagina) {
    url = `?size=${registrosPorPagina}&from=${pagina}`;
  }

  if (!filtros) {
    return url
  }

  const concatenarURL = (filtro) => {
    if (filtro.isData) {
      if (filtro.valor.inicio !== '' && filtro.valor.fim !== '') {
        url += `${filtro.chave}Inicio=${filtro.valor.inicio}&`;
        url += `${filtro.chave}Fim=${filtro.valor.fim}&`
      }
    } else {
      url += filtro.valor.valor ? `${filtro.chave}=${filtro.valor.chave}&` : `${filtro.chave}=${filtro.valor}&`
    }
  };

  if (filtros.length > 0) {
    url += '&';
    filtros.forEach(concatenarURL)
  }
  return url
}

export const filtroExiste = (filtros, filtro) => {
  const entradasFiltro = filtros.filter(f => f.chave === filtro.chave && f.valor === filtro.valor);
  return entradasFiltro.length
};

export const filtroChaveValorJaExiste = (filtros, filtro) => {
  const entradasFiltro = filtros.filter(f => f.chave === filtro.chave && f.valor.chave === filtro.valor.chave);
  return entradasFiltro.length
};


export const alterouFiltro = (props, prevProps) => prevProps.filtrosAdicionados !== props.filtrosAdicionados;

export const alterouPagina = (props, prevProps) => prevProps.registrosPorPagina !== props.registrosPorPagina || prevProps.paginaAtual !== props.paginaAtual;


export const configuracaoMensagemAlerta = {
  position: 'top-left',
  effect: 'slide',
  timeout: 5000,
  html: true,
  offset: '80px',
  zIndex: '99999 !important'
};

export const configuracaoMensagemAlertaErro = {
  position: 'top-right',
  effect: 'slide',
  timeout: 15000,
  html: true,
  offset: '280px',
  zIndex: '99999 !important'
};
