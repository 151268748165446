import {BUSCAR_PEDIDOS, ALTERAR_DETALHAMENTO_PEDIDO} from '../../actions/types'

const initial = {
  pedidos: [],
};

export default function reducerBuscarPedido (state = initial, action) {
  switch (action.type) {
    case BUSCAR_PEDIDOS: {
      if (action.payload.pedidos) {
        const pedidos = action.payload.pedidos.pedidos.map(pedido => Object.assign({}, pedido, { exibirDetalhe: false, reservaFoiPesquisado: false }));
        
        return {
          ...state,
          pedidos,
          total: action.payload.pedidos.total
        }
      }
      return state
    }

    case ALTERAR_DETALHAMENTO_PEDIDO: {
      const pedido = action.payload.pedido;
      const reservas = action.payload.reservas || pedido.reservas;
      const indice = state.pedidos.findIndex((p) => p.id === pedido.id)
      const novoPedido = Object.assign({}, pedido, { exibirDetalhe: !pedido.exibirDetalhe, reservaFoiPesquisado:true, reservas: reservas });
      const pedidos = Object.assign([], state.pedidos);
      pedidos[indice] = novoPedido;

      return {
        ...state,
        pedidos
      }
    }

    default: {
      return state
    }
  }
}
