let browserHistory;

export const setBrowserHistory = (history) => {
    browserHistory = history;
};
const ambienteKey = 'ambiente_agenciaweb';

export const getBrowserHistory = () => browserHistory;


export const salvarAmbiente = (ambiente) => {
    localStorage.setItem(ambienteKey , JSON.stringify(ambiente))
};
export const getAmbiente = () => JSON.parse(localStorage.getItem(ambienteKey)) ? JSON.parse(localStorage.getItem(ambienteKey)) : {};


export const isAmbienteProd = () => getAmbiente() === 'live';

export const isAmbienteDev = () => getAmbiente() === 'sandbox';


