import { ALTERAR_REGISTROS_POR_PAGINA_ITINERARIO } from '../../actions/types'

const initialState = 10

export default function (state = initialState, action) {
  switch (action.type) {
    case ALTERAR_REGISTROS_POR_PAGINA_ITINERARIO: {
      return action.payload
    }

    default:
      return state
  }
}
