import React from 'react';
import Link from 'react-router-dom/Link';
import {withRouter} from 'react-router-dom';

class BreadCrumbLink extends React.Component {
    exibirLink(){
        if (this.props.last) {
            return <span className="brdcr"> {this.props.desc}</span>;
        }
        if (this.props.action) {
            return <a href="#" onClick={() => this.props.action()}>
                <span className="brdcr lk-ativo"> {this.props.desc} / </span>
            </a>;
        }
        return (
            <Link to={this.props.path}>
                <span className="brdcr lk-ativo"> {this.props.desc} / </span>
            </Link>
        );
    }
    render() {
        return <React.Fragment>
            {this.exibirLink()}
        </React.Fragment>

    }
}

export default withRouter(BreadCrumbLink);
