import { Component } from 'react'
import { connect } from 'react-redux'

import { logoutUser } from '../../actions/usuarioActions'

export class Logout extends Component {
  UNSAFE_componentWillMount () {
    this.props.logoutUser();
    window.location.href = '/'
  }

  render () {
    return ''
  }
}

export default connect(null, { logoutUser })(Logout)
