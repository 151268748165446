import { LISTAR_FILTROS_ITINERARIO } from '../types'
import axios from 'axios'
import { URL } from '../../util/ambienteActions';

export default function listarFiltrosItinerario () {

  const url = `${URL}/itinerario/filtro`
  
  return dispatch => axios.get(url).then(
    response => dispatch({
      type: LISTAR_FILTROS_ITINERARIO,
      payload: response
    })
  )
}