export const RESPONSE_DOMAIN_VIOLATIONS_SET = '@responseErrorHandlerActions/RESPONSE_DOMAIN_VIOLATIONS_SET';
export const CLEAN_RESPONSE_VIOLATIONS = '@responseErrorHandlerActions/CLEAN_RESPONSE_VIOLATIONS';

export const handleResponseDomainViolations = error => ({
  type: RESPONSE_DOMAIN_VIOLATIONS_SET,
  payload: error
});

export const cleanResponseDomainViolations = () => ({
  type: CLEAN_RESPONSE_VIOLATIONS
});
