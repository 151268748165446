import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { App } from './App'
import store from './store/configureStore'
import './interceptors/requestResponseLoadingInterceptors'
import './interceptors/axiosResquestInterceptor'
import './interceptors/responseErrorInterceptors'
import { unregister } from './registerServiceWorker'

import ErrorBoundary from './componentes/ErrorBoundary'
import jwtInterceptor from './interceptors/authenticationJwtInterceptors';
import {recuperarAmbiente} from './util/ambienteActions';

jwtInterceptor(store);

store.dispatch(recuperarAmbiente());


export default ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App/>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

unregister();
