import React, { Component } from 'react';
import '../../estilo/css/PainelItinerarios.css';
import { connect } from 'react-redux';
import buscarItinerarios from '../../actions/itinerario/buscarItinerarios';
import alterarExibicaoDetalhamentoItinerario from '../../actions/itinerario/alterarExibicaoDetalhamentoItinerario';
import PainelFiltragem from '../itinerarios/PainelFiltrosItinerario'
import { alterouFiltro, alterouPagina } from '../../util/filtros';
import determinaPaginaAtual from '../../actions/itinerario/determinarPaginaAtualItinerario';
import limparFiltroItinerario from '../../actions/itinerario/limparFiltroItinerario';
import CabecalhoRodapeTabela from '../comum/CabecalhoRodapeTabela';
import { Itinerario } from './Itinerario';
import BotoesPaginacaoItinerario from './BotoesPaginacaoItinerario';
import PaginacaoItinerario from './PaginacaoItinerario';

export class painelItinerarios extends Component {
    constructor(props) {
        super(props)
        this.alterarExibicao = this.alterarExibicao.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.determinaPaginaAtual(1)
        this.props.limparFiltroItinerario();
    }

    componentDidMount() {
        this.buscarItinerarios();
    }

    componentDidUpdate(prevProps) {
        if (alterouFiltro(this.props, prevProps) || alterouPagina(this.props, prevProps)) {
            this.buscarItinerarios();
            if (!alterouPagina(this.props, prevProps)) {
                this.props.determinaPaginaAtual(1)
            }
        }
    }

    buscarItinerarios() {
        this.props.buscarItinerarios(this.props.filtrosAdicionados, this.props.paginaAtual, this.props.registrosPorPagina, this.props.filtrosAdicionados);
    }

    alterarExibicao(itinerario) {
        this.props.alterarExibicaoDetalhamentoItinerario(itinerario)
    }

    cabecalhoTabela() {
        return (
            <div className="lista-table d-md-block d-lg-block d-xl-block d-none d-sm-none">
                <div className='row' id='titulo-tabela' style={{ padding: '0.7rem' }}>
                    <div className="col"> </div>
                    <div className="col"> Itinerário </div>
                    <div className="col"> Empresa </div>
                    <div className="col"> Data/Hora saída </div>
                    <div className="col"> Rota </div>
                    <div className="col"> Delimitador </div>
                    <div className="col"> Veículos </div>
                    <div className="col"> Assentos </div>
                </div>
            </div>
        )
    }

    exibirItinerarios() {
        return this.props.itinerario.map((itinerario, indice) => <Itinerario key={indice}
            itinerario={itinerario}
            indice={indice}
            alterarExibicao={this.alterarExibicao} />)
    }

    exibirTabelaItinerarios() {
        if (this.props.itinerario && this.props.itinerario.length) {
            return (
                <div id='tabela-itinerario'>
                    <div>
                        <CabecalhoRodapeTabela OpcoesPaginacao={PaginacaoItinerario}
                            BotoesPaginacao={BotoesPaginacaoItinerario}
                            itinerario={this.props.itinerario}>
                            {this.cabecalhoTabela()}
                            <div className="lista-table">
                                {this.exibirItinerarios()}
                            </div>
                        </CabecalhoRodapeTabela>
                    </div>
                </div>
            )
        }

        return <div className="alert alert-warning text-center mt-4" role="alert">Nenhum itinerário localizado para os
            parâmetros informados</div>;
    }

    render() {
        return (
            <>
                <div className="col-12 filtro-busca painel-fitro-pedido">
                    <PainelFiltragem />
                </div>
                <div>
                    {this.exibirTabelaItinerarios()}
                </div>
            </>
        )
    }
}


export const mapStateToProps = state => {
    return {
        itinerario: state.itinerario.itinerarios,
        filtrosAdicionados: state.filtrosAdicionadosItinerario,
        paginaAtual: state.paginaAtualItinerario,
        registrosPorPagina: state.registrosPorPaginaItinerario,
    }
}

export default connect(mapStateToProps, {
    buscarItinerarios,
    alterarExibicaoDetalhamentoItinerario,
    limparFiltroItinerario,
    determinaPaginaAtual
})(painelItinerarios)