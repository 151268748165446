import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import adicionarFiltroItinerario from '../../actions/itinerario/adicionarFiltroItinerario'
import listarFiltrosItinerario from '../../actions/itinerario/buscarOpcaoFiltroItinerario'
import removerFiltroItinerario from '../../actions/itinerario/removerFiltroItinerario'
import {
  FILTRO_NOME_FANTASIA,
  FILTRO_DATA_HORA_PARTIDA,
  FILTRO_PREFIXO,
  FILTRO_DELIMITACAO,
  FILTRO_VEICULO,
  FILTRO_ACENTOS_VENDIDOS,
  FILTRO_TRECHO_ORIGEM,
  FILTRO_TRECHO_DESTINO
} from '../../util/filtros'
import FiltroBuscaItinerario from './FiltroBuscaItinerario';
import FiltroChaveValor from '../comum/FiltroChaveValor'

export class FiltroItinerario extends Component {

  componentDidMount() {
    this.props.listarFiltrosItinerario()
  }

  adicionarFiltro(filtro) {
    this.props.adicionarFiltroItinerario(filtro)
  }

  removerFiltro(filtro) {
    this.props.removerFiltroItinerario(filtro)
  }

  render() {
    if (!this.props.empresas) {
      return <div>Carregando...</div>
    }

    return (
      <React.Fragment>
        <div className="conteudo">
          <FiltroChaveValor
            filtro={FILTRO_NOME_FANTASIA}
            opcoes={this.props.empresas}
            filtrosAdicionados={this.props.filtrosAdicionados}
            adicionarFiltro={this.adicionarFiltro.bind(this)}
            removerFiltro={this.removerFiltro.bind(this)}
          />
          <FiltroBuscaItinerario
            filtro={FILTRO_DATA_HORA_PARTIDA}
            opcoes={[new Date().getFullYear()]}
            isFiltroData={true}
          />
          <FiltroBuscaItinerario
            filtro={FILTRO_PREFIXO}
            opcoes={[]}
            isNome={true}
          />
          <FiltroBuscaItinerario
            filtro={FILTRO_DELIMITACAO}
            opcoes={[]}
            isNome={true}
          />
          <FiltroBuscaItinerario
            filtro={FILTRO_VEICULO}
            opcoes={[]}
            isNome={true}
          />
          <FiltroBuscaItinerario
            filtro={FILTRO_ACENTOS_VENDIDOS}
            isNumero={true}
          />
          <FiltroBuscaItinerario
            filtro={FILTRO_TRECHO_ORIGEM}
            opcoes={[]}
            isNome={true}
          />
          <FiltroBuscaItinerario
            filtro={FILTRO_TRECHO_DESTINO}
            opcoes={[]}
            isNome={true}
          />
        </div>

      </React.Fragment>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    empresas: state.filtrosEmpresa.empresas,
    filtrosAdicionados: state.filtrosAdicionadosItinerario
  }
}

export const mapDispatchToProps = dispatch => (
  bindActionCreators({
    adicionarFiltroItinerario,
    listarFiltrosItinerario,
    removerFiltroItinerario,
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(FiltroItinerario)
