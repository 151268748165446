/* eslint-disable */

export function realParaNumber (valor) {
  if (valor) {
    return Number(valor.replace('R$', '')
      .replace('.', '')
      .replace(',', '.'));
  }
  return 0;
}

export function realParaNumberExportacao (valor) {
    if (valor) {
        return valor.replace('.', ',');
    }
    return 0;
}

export function arredondamentoPadrao(valor){
    return ((Math.round(valor*100))/100)
}


export function formatarReal (valor) {
  if (valor) {
    return (Number(valor.replace(',', '.'))
      .toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      }))
  }
  return Number(0)
    .toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL'
    })
}
