import { BUSCAR_ITINERARIO, ALTERAR_DETALHAMENTO_ITINERARIO } from '../../actions/types'

const initial = {
  itinerarios: [],
};

export default function reducerBuscarItinerario(state = initial, action) {
  switch (action.type) {
    case BUSCAR_ITINERARIO: {

      if (action.payload.itinerarios) {
        const itinerarios = action.payload.itinerarios.itinerarios.map(itinerario => Object.assign({}, itinerario, { exibirDetalhe: false, trechoFoiPesquisado: false }));

        return {
          ...state,
          itinerarios,
          total: action.payload.itinerarios.total
        }
      }
      return state
    }

    case ALTERAR_DETALHAMENTO_ITINERARIO: {
      const itinerario = action.payload.itinerario;
      const trechos = action.payload.trechos || itinerario.trechos;

      if (!itinerario.trechoFoiPesquisado) {
        const arrayTrechos = [...trechos];
        trechos.forEach((trecho) => {
          const dataPartida = new Date(itinerario.dataPartida);
          const horaPartidaArray = itinerario.horaPartida.split(':');
          const dataHora = new Date(dataPartida.getFullYear(), dataPartida.getMonth(), dataPartida.getDate(), parseInt(horaPartidaArray[0]), parseInt(horaPartidaArray[1]))
          
          arrayTrechos.forEach(at => {
            if (trecho.ordem >= at.ordem) {              
              const duracaoArray = at.duracao.split(':')
              const valorHora = parseInt(duracaoArray[0]);              
              const valorMinuto = parseInt(duracaoArray[1]);
              dataHora.setHours(dataHora.getHours() + valorHora)
              dataHora.setMinutes(dataHora.getMinutes() + valorMinuto);              
            }
          })
          
          trecho.dataHoraPartida = dataHora
        })        
      }  

      const indice = state.itinerarios.findIndex((i) => i.id === itinerario.id)
      const novoItinerario = Object.assign({}, itinerario, { exibirDetalhe: !itinerario.exibirDetalhe, trechoFoiPesquisado: true, trechos: trechos });
      const itinerarios = Object.assign([], state.itinerarios);
      itinerarios[indice] = novoItinerario;

      return {
        ...state,
        itinerarios
      }
    }

    default: {
      return state
    }
  }
}
