import React from 'react'
import FiltrosAdicionadosPedidos from './FiltrosAdicionadosPedidos'
import Filtro from '../comum/Filtro'
import PainelFiltragem from '../comum/PainelFiltragem'
import FiltroPedidos from './FiltroPedidos';

export const PainelFiltroPedidos = () => (
  <Filtro PainelFiltro={FiltroPedidos}/>
);

const PainelFiltrosPedidos = () => (
  <PainelFiltragem    
    Filtro={PainelFiltroPedidos}
    FiltrosAdicionados={FiltrosAdicionadosPedidos}
    />
)

export default PainelFiltrosPedidos
