import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import buscarPastasAcervoDivulgacao from '../../actions/acervo/buscarPastasAcervoDivulgacao';

export class PainelAcervoDivulgacao extends Component {
    componentDidMount() {
        this.props.buscarPastasAcervoDivulgacao();
    }

    render() {
        return (
            <div className="galeria-container">
                <div className='cards'>
                    {this.props.acervos && this.props.acervos.length && this.props.acervos.map((item, indice) =>
                        <div className='folder' key={indice}>
                                <Link className='name' to={`/painel-acervo-divulgacao-pastas?pasta=${item.nome}`}>
                                    {item.nome}
                                </Link>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export const mapStateToProps = state => {
    return {
        acervos: state.acervoDivulgacao
    }
}

export default connect(mapStateToProps, {
    buscarPastasAcervoDivulgacao
})(PainelAcervoDivulgacao)
