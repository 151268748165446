import React from 'react'
import Paginacao from './Paginacao'

export const ParteCabecalhoRodape = props => (
  <React.Fragment>
      <div className="col-12 col-sm-12 col-md-8 col-lg-8">
          <div className="row">
              <Paginacao OpcoesPaginacao={props.OpcoesPaginacao} BotoesPaginacao={props.BotoesPaginacao}/>
          </div>
      </div>
  </React.Fragment>
);

const CabecalhoRodapeTabela = (props) => (
  <React.Fragment>
    <div className="row area-paginacao">
      <ParteCabecalhoRodape {...props} />
    </div>
    {props.children}
    <div className="row mt-3 mb-3">
      <ParteCabecalhoRodape {...props} />
    </div>
  </React.Fragment>
)

export default CabecalhoRodapeTabela
