import { DETERMINAR_PAGINA_ATUAL_ITINERARIO } from '../../actions/types'

const initialState = undefined

export default function (state = 1, action = initialState) {
  switch (action.type) {
    case DETERMINAR_PAGINA_ATUAL_ITINERARIO: {
      return action.payload
    }

    default:
      return state
  }
}
