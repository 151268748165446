import React, { Component } from 'react'
import { DetalhesPedido } from './DetalhesPedido'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../util/stringUtil'

export class Pedido extends Component {

    exibirIcone() {
        if (this.props.pedido.exibirDetalhe) {
            return <i className="material-icons" style={{ cursor: 'pointer' }}>
                &#xE5C7;</i>
        }
        return <i className="material-icons" style={{ cursor: 'pointer' }}>&#xE5C5;</i>
    }

    render() {
        return (
            <div>
                <div className="card">
                    <div className="card-body" style={{padding:'0.7rem'}}>
                        <div className='row'>
                            <div className="col-sm" onClick={() => this.props.alterarExibicao(this.props.pedido)} style={{ cursor: 'pointer' }}>
                                {this.exibirIcone()}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Pedido:&nbsp;</span>{this.props.pedido.id}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Empresa:&nbsp;</span>{this.props.pedido.nomeFantasia}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Cliente:&nbsp;</span>{this.props.pedido.nomeComprador}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Data da Venda:&nbsp;</span>{moment(this.props.pedido.dataCriacao).format('DD/MM/YYYY HH:mm')}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Situação:&nbsp;</span>{this.props.pedido.situacaoPedido && capitalizeFirstLetter(this.props.pedido.situacaoPedido.toLowerCase())}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Valor:&nbsp;</span> { this.props.pedido.valor && `${Number(this.props.pedido.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Forma de Pagamento:&nbsp;</span>{this.props.pedido.descricaoFormaPagamento}
                            </div>
                            <div className="col-sm">
                                <span className="titulo-mobile">Recebida:&nbsp;</span> -
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DetalhesPedido pedido={this.props.pedido} />
                </div>
            </div>
        )
    }
}