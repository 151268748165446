import React, { Fragment, useEffect, useState } from 'react';
import ImageGrid from './ImageGrid';
import Modal from './Modal';
import PaginacaoGaleria from './PaginacaoGaleria';

function retornarImageGrid(selectedImg, setSelectedImg, baixarArquivo, doc, indice) {
    return (
        <Fragment key={indice}>
            <ImageGrid setSelectedImg={setSelectedImg.bind(this)} doc={doc} indice={indice} baixarArquivo={baixarArquivo} />
            {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />}
        </Fragment>
    )
}

function Galeria({ acervos, naoEhObjetoVazio, selectedImg, setSelectedImg, baixarArquivo }) {
    const [itens, setItens] = useState([]);
    const [itensPorPagina, setItensPorPagina] = useState(10);
    const [paginaAtual, setPaginaAtual] = useState(0);

    const paginas = Math.ceil(itens.length / itensPorPagina);
    const inicioIndice = paginaAtual * itensPorPagina;
    const fimIndice = inicioIndice + itensPorPagina;

    const itensFiltrados = itens.slice(inicioIndice, fimIndice);

    useEffect(() => {
        setItens(retornarTodosItens());
    }, [acervos])

    useEffect(() => {
        setPaginaAtual(0);
    }, [itensPorPagina])

    const retornarTodosItens = () => naoEhObjetoVazio(acervos) ? acervos.filter(it => it.extensao != '') : [];

    const buscarImagens = (nome) => {        
        if (nome) {
            setItens(itens.filter(it => it.nome.toLowerCase().includes(nome.toLowerCase())));
            setPaginaAtual(0);
        } else {
            setItens(retornarTodosItens());            
        }        
    }

    return (
        <>
            <div className='mb-4 row col-12'>
                <div className="input-group rounded col-sm-7">
                    <input type="search" className="form-control rounded" placeholder="Pesquisa" aria-label="Search" aria-describedby="search-addon" onChange={(e) => buscarImagens(e.target.value)} />
                    <span className="input-group-text border-0" id="search-addon">
                        <i className="material-icons">search</i>
                    </span>
                </div>
            </div>
            {itens && itens.length > 1 ? <>
                <div style={{ marginBottom: '20px', marginLeft: '5px'}} className='form-group row'>
                    <label htmlFor='selectItensPorPagina' className='col-sm-3 col-form-label'>Total: {itens.length} - Itens Por Página: </label>
                    <select id='selectItensPorPagina' className='form-control col-sm-2' value={itensPorPagina} onChange={(e) => setItensPorPagina(Number(e.target.value))}>
                        <option value={5}>05</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                    </select>
                </div>
                <div className='row col-12 ml-2'>
                    <PaginacaoGaleria paginas={paginas} paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} />
                </div>
                <div className='row col-12'>
                    <div className="img-grid">
                        {itensFiltrados.map((doc, indice) => retornarImageGrid(selectedImg, setSelectedImg, baixarArquivo, doc, indice))}
                    </div>
                </div>
                <div className='row col-12 ml-2'>
                    <PaginacaoGaleria paginas={paginas} paginaAtual={paginaAtual} setPaginaAtual={setPaginaAtual} />
                </div>
            </> : 'Pasta vazia!'}
        </>
    )
}

export default Galeria;