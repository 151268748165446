import React from 'react'

export const incrementarPagina = (paginaAtual, maiorPagina, determinarPaginaAtual) => {
  if (paginaAtual < maiorPagina) {
    determinarPaginaAtual(paginaAtual + 1)
  }
}

export const decrementarPagina = (paginaAtual, determinarPaginaAtual) => {
  if (paginaAtual > 1) {
    determinarPaginaAtual(paginaAtual - 1)
  }
}

const calcularMaiorPagina = (numeroElementos, registrosPorPagina) => Math.ceil(numeroElementos / registrosPorPagina)

const BotoesPaginacao = ({
  numeroElementos,
  paginaAtual,
  registrosPorPagina,
  determinarPaginaAtual
}) => {
  const maiorPagina = calcularMaiorPagina(numeroElementos, registrosPorPagina)
  return (
    <div className='area-setas-paginacao col-12 col-sm-6 col-md-6 col-lg-6 text-center text-sm-right text-md-center text-lg-center'>
      <div className='paginacaoBotoes'>
        <button
          onClick={() => determinarPaginaAtual(1)}
          data-toggle='tooltip'
          data-placement='bottom'
          title='Primeiras'>
          <i className="material-icons">
            skip_previous
          </i>
        </button>

        <button
          onClick={() => decrementarPagina(paginaAtual, determinarPaginaAtual)}
          data-toggle='tooltip'
          data-placement='bottom'
          title='Anteriores'>
          <i className="material-icons">
            fast_rewind
          </i>
        </button>

        <button
          onClick={() => incrementarPagina(paginaAtual, maiorPagina, determinarPaginaAtual)}
          data-toggle='tooltip'
          data-placement='bottom'
          title='Próximas'>
          <i className="material-icons">
            fast_forward
          </i>
        </button>

        <button
          onClick={() => determinarPaginaAtual(maiorPagina)}
          data-toggle='tooltip'
          data-placement='bottom'
          title='Últimas'>
          <i className="material-icons">
            skip_next
          </i>
        </button>
      </div>
    </div>
  )
}

export default BotoesPaginacao
