export const MOSTRAR_LOADING = '@loadingReducer/MOSTRAR_LOADING';
export const FECHAR_LOADING = '@loadingReducer/FECHAR_LOADING';
const initialState = {loading: false, qtdOperacoesEmAndamento: 0};

export default function (state = initialState, action) {
    let qtd = state.qtdOperacoesEmAndamento;
    switch (action.type) {
        case MOSTRAR_LOADING: {
            qtd += 1;
            return {loading: true, qtdOperacoesEmAndamento: qtd}
        }

        case FECHAR_LOADING: {
            qtd -= 1;

            if (qtd > 0) {
                return {loading: true, qtdOperacoesEmAndamento: qtd}
            }

            return {loading: false, qtdOperacoesEmAndamento: qtd}
        }

        default:
            return state
    }
}
