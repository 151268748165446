import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import momentLocalizer from 'react-widgets-moment'
import adicionarFiltroItinerario from '../../actions/itinerario/adicionarFiltroItinerario'
import removerFiltroItinerario from '../../actions/itinerario/removerFiltroItinerario'
import FiltroBusca from '../comum/FiltroBusca'
import 'react-widgets/dist/css/react-widgets.css';

momentLocalizer();

export class FiltroBuscaItinerario extends Component {
  render () {
    if (!this.props.filtrosAdicionados) {
      return <div>Carregando...</div>
    }
    return (
      <FiltroBusca
        filtrosAdicionados={this.props.filtrosAdicionados}
        filtro={this.props.filtro}
        opcoes={this.props.opcoes}
        isCpf={this.props.isCpf}
        isNome={this.props.isNome}
        isRadio={this.props.isRadio}
        isFiltroData={this.props.isFiltroData}
        isNumero={this.props.isNumero}
        adicionarFiltro={this.props.adicionarFiltroItinerario}
        removerFiltro={this.props.removerFiltroItinerario}
      />
    )
  }
}

export const mapStateToProps = state => ({
  filtrosAdicionados: state.filtrosAdicionadosItinerario
})

export const mapDispatchToProps = dispatch => (
  bindActionCreators({
    adicionarFiltroItinerario,
    removerFiltroItinerario,
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(FiltroBuscaItinerario)

