import { BUSCAR_PEDIDOS } from '../types'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil'
import { URL } from '../../util/ambienteActions';
import { montarURLFiltro } from '../../util/filtros';

export default function buscarPedidos (filtrosPedido, pagina, registrosPorPagina, filtros) {
  const urlFiltros = montarURLFiltro(filtros, pagina, registrosPorPagina);

  const url = `${URL}/pedido${urlFiltros}`
  
  return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
    response => dispatch({
      type: BUSCAR_PEDIDOS,
      payload: {
        pedidos: response.data,
        existeFiltro: filtrosPedido && filtrosPedido.length > 0
      }
    })
  )
}
